import { MenuItem } from '../types'

export const invoicesPackagesMenu: MenuItem[] = [
  {
    label: 'แพ็กเกจที่ใช้งาน',
    href: '/packages',
    checkActive: ['packages'],
  },
  {
    label: 'ธุรกรรมของฉัน',
    href: '/invoices',
    checkActive: ['invoices'],
  },
]

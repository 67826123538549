import { Fragment } from 'react'
import { Link } from '@chakra-ui/react'
import { TermsAndPrivacyIndex } from '../types'

const FORM_PATH = 'https://ndatathoth.pdpaform.com'

export const termsAndPrivacyIndexes: Record<string, TermsAndPrivacyIndex> = {
  terms: {
    title: 'Terms and Conditions',
    checkActive: 'terms',
    linkTo: 'terms',
    subMenus: [
      {
        title: 'Accounts and membership',
        scrollTo: 'accounts-and-membership',
        content:
          'If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.',
      },
      {
        title: 'User content',
        scrollTo: 'user-content',
        content:
          'We do not own any data, information or material ("Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.',
      },
      {
        title: 'Billing and payments',
        scrollTo: 'billing-and-payments',
        content: (
          <Fragment>
            <p>
              You shall pay all fees or charges to your account in accordance
              with the fees, charges, and billing terms in effect at the time a
              fee or charge is due and payable. Where Services are offered on a
              free trial basis, payment may be required after the free trial
              period ends, and not when you enter your billing details (which
              may be required prior to the commencement of the free trial
              period). If auto-renewal is enabled for the Services you have
              subscribed for, you will be charged automatically in accordance
              with the term you selected. If, in our judgment, your purchase
              constitutes a high-risk transaction, we will require you to
              provide us with a copy of your valid government-issued photo
              identification, and possibly a copy of a recent bank statement for
              the credit or debit card used for the purchase.
            </p>
            <p>
              We reserve the right to change products and product pricing at any
              time. We also reserve the right to refuse any order you place with
              us. We may, in our sole discretion, limit or cancel quantities
              purchased per person, per household or per order.
            </p>
            <p>
              These restrictions may include orders placed by or under the same
              customer account, the same credit card, and/or orders that use the
              same billing and/or shipping address. In the event that we make a
              change to or cancel an order, we may attempt to notify you by
              contacting the e-mail and/or billing address/phone number provided
              at the time the order was made.
            </p>
            <p>
              PDPAForm.com is offering trial plan and paid services. Paid plans
              are subject to fees. Fees are prepaid on the subscribed date and
              next billing date is next 30 days (Example: start a plan on 16
              Apr, next billing date will be 15 May) or at the time of
              subscription as in pro-rata rate. All fees are non-refundable,
              i.e. there are no refunds or credits for periods where the Client
              did not use an activated Account, used it only partially, or
              deactivated the Account or terminated these Terms during an
              ongoing payment interval.
            </p>
            <p>
              All PDPAForm.com plans can be canceled on the dashboard.
              Otherwise, the subscription is considered as active and fees are
              applied. The remaining period (end date) is available from the
              status tab in the top menu.
            </p>
            <p>
              PDPAForm.com also offers yearly payments. Switching to yearly
              payments can be done from the plans tab in the dashboard. Yearly
              payments are non-refundable once paid.
            </p>
            <p>
              In the monthly or yearly plan there are limits for data points to
              be used and storage limitations, customers will be charged if they
              exceed the limitation as the price posted on the website onto the
              next billing period.
            </p>
            <p>
              If fees are not paid in the following 14 days, a supplier has the
              right to permanently delete the account, including all data
              therein.
            </p>
            <p>
              When subscribing to PDPAForm.com paid service (upgrading a plan),
              or adding a payment method, the user must ensure the email address
              provided for the PDPAForm.com account is valid, able to receive
              emails from us, and emails we will deliver to that email inbox are
              read by the user (eg. paid invoices or unpaid invoice
              notifications).
            </p>
            <p>
              By adding a payment method to their PDPAForm.com account, the user
              consents to be charged for any active paid subscription, as well
              as paid subscriptions coming out of the trial period. We will
              automatically attempt to pay any past and present unpaid invoices
              on the user account when a new payment method is added, using that
              new payment method. No fees will be incurred for free
              subscriptions even if a card is registered on the user account.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Refund policy',
        scrollTo: 'refund-policy',
        content: `Since our Website offers non-tangible, irrevocable goods we do not provide refunds after the product is purchased, which you acknowledge prior to purchasing any product on the Website. Please make sure that you've carefully read the service description before making a purchase.`,
      },
      {
        title: 'Accuracy of information',
        scrollTo: 'accuracy-of-information',
        content: `Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated.`,
      },
      {
        title: 'Third-party services',
        scrollTo: 'third-party-services',
        content: (
          <Fragment>
            <p>
              If you decide to enable, access or use third-party services, be
              advised that your access and use of such other services are
              governed solely by the terms and conditions of such other
              services, and we do not endorse, are not responsible or liable
              for, and make no representations as to any aspect of such other
              services, including, without limitation, their content or the
              manner in which they handle data (including your data) or any
              interaction between you and the provider of such other services.
              You irrevocably waive any claim against nDataThoth Limited with
              respect to such other services. nDataThoth Limited is not liable
              for any damage or loss caused or alleged to be caused by or in
              connection with your enablement, access or use of any such other
              services, or your reliance on the privacy practices, data security
              processes or other policies of such other services.
            </p>
            <p>
              You may be required to register for or log into such other
              services on their respective websites. By enabling any other
              services, you are expressly permitting nDataThoth Limited to
              disclose your data as necessary to facilitate the use or
              enablement of such other service.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Uptime guarantee',
        scrollTo: 'uptime-guarantee',
        content: `We offer a Service uptime guarantee of 99% of available time per month. If we fail to maintain this service uptime guarantee in a particular month (as solely determined by us), you may contact us and request a credit off your Service fee for that month. The credit may be used only for the purchase of further products and services from us, and is exclusive of any applicable taxes. The service uptime guarantee does not apply to service interruptions caused by: (1) periodic scheduled maintenance or repairs we may undertake from time to time; (2) interruptions caused by you or your activities; (3) outages that do not affect core Service functionality; (4) causes beyond our control or that are not reasonably foreseeable; and (5) outages related to the reliability of certain programming environments.`,
      },
      {
        title: 'Backups',
        scrollTo: 'backups',
        content: `We perform regular backups of the Website and Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.`,
      },
      {
        title: 'Links to other websites',
        scrollTo: 'links-to-other-websites',
        content: `Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.`,
      },
      {
        title: 'Prohibited uses',
        scrollTo: 'prohibited-uses',
        content: `In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.`,
      },
      {
        title: 'Intellectual property rights',
        scrollTo: 'intellectual-property-rights',
        content: `This Agreement does not transfer to you any intellectual property owned by nDataThoth Limited or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with nDataThoth Limited All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of PDPAForm.com/nDataThoth Limited or nDataThoth Limited licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third-parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any nDataThoth Limited or third-party trademarks.`,
      },
      {
        title: 'Disclaimer of warranty',
        scrollTo: 'disclaimer-of-warranty',
        content: `You agree that your use of our Website or Services is solely at your own risk. You agree that such Service is provided on an "as is" and "as available" basis. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.`,
      },
      {
        title: 'Limitation of liability',
        scrollTo: 'limitation-of-liability',
        content: `To the fullest extent permitted by applicable law, in no event will nDataThoth Limited, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if nDataThoth Limited has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of nDataThoth Limited and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to nDataThoth Limited for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.`,
      },
      {
        title: 'Indemnification',
        scrollTo: 'indemnification',
        content: `You agree to indemnify and hold nDataThoth Limited and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.`,
      },
      {
        title: 'Severability',
        scrollTo: 'severability',
        content: `All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.`,
      },
      {
        title: 'Content and postings',
        scrollTo: 'content-and-postings',
        content: (
          <Fragment>
            <p>
              You may not modify, print or copy any part of the Website.
              Inclusion of any part of this Website in another work, whether in
              printed or electronic or another form or inclusion of any part of
              the Website in another website by embedding, framing or otherwise
              without the express permission of nDataThoth Limited is
              prohibited.
            </p>
            <p>
              You may not upload content that is defamatory, fraudulent,
              obscene, threatening, invasive of another person&apos;s privacy
              rights or that is otherwise unlawful. You may not upload content
              that infringes on the intellectual property rights of any other
              person or entity. You may not upload any content that includes any
              computer virus or other code designed to disrupt, damage, or limit
              the functioning of any computer software or hardware. By
              submitting or uploading files on the website, you grant nDataThoth
              Limited remove any file at any time and for any reason.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Dispute resolution',
        scrollTo: 'dispute-resolution',
        content: `The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Thailand without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Thailand. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Thailand, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.`,
      },
      {
        title: 'Assignment',
        scrollTo: 'assignment',
        content: `You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third-party as part of the sale of all or substantially all of its assets or stock or as part of a merger.`,
      },
      {
        title: 'Changes and amendments',
        scrollTo: 'changes-amendments',
        content: `We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.`,
      },
      {
        title: 'Acceptance of these terms',
        scrollTo: 'acceptance-of-these-terms',
        content: `You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.`,
      },
      {
        title: 'Contacting us',
        scrollTo: 'contacting-us',
        content: `If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may do so via the contact form, send an email to support@ndatathoth.com or write a letter to nDataThoth Limited 1778 Summer Hub Offices, 6th Fl., Sukhumvit Rd., Phra Khanong, Klong Toey, Bangkok 10110 THAILAND`,
      },
    ],
  },
  policy: {
    title: 'Privacy Policy',
    checkActive: 'policies',
    linkTo: 'policies',
    subMenus: [
      {
        title: 'Automatic collection of information',
        scrollTo: 'automatic-collection-of-information',
        content: (
          <Fragment>
            <p>
              When you visit the Website our servers automatically record
              information that your browser sends. This data may include
              information such as your device&apos;s IP address, browser type
              and version, operating system type and version, language
              preferences or the webpage you were visiting before you came to
              our Website, pages of our Website that you visit, the time spent
              on those pages, information you search for on our Website, access
              times and dates, and other statistics.
            </p>
            <p>
              Information collected automatically is used only to identify
              potential cases of abuse and establish statistical information
              regarding Website usage. This statistical information is not
              otherwise aggregated in such a way that would identify any
              particular user of the system.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Collection of personal information',
        scrollTo: 'collection-of-personal-information',
        content: (
          <Fragment>
            <p>
              You can visit the Website without telling us who you are or
              revealing any information by which someone could identify you as a
              specific, identifiable individual. If, however, you wish to use
              some of the Website&apos;s features, you will be asked to provide
              certain Personal Information (for example, your name and e-mail
              address). We receive and store any information you knowingly
              provide to us when you create an account, publish content, make a
              purchase, or fill any online forms on the Website. When required,
              this information may include the following:
            </p>
            <ul className="Docs__List">
              <li className="Docs__List__Item">
                Identity details such as name-surname, age, data of birth,
                identification card/passport no.
              </li>
              <li className="Docs__List__Item">
                Contact detail such as email address, address, telephone no..
              </li>
              <li className="Docs__List__Item">
                Account details such as user name, unique user ID, password.
              </li>
              <li className="Docs__List__Item">
                Proof of identity such as photocopy of a identification card or
                passport.
              </li>
              <li className="Docs__List__Item">
                Payment information such as credit card details, bank details,
                etc.
              </li>
              <li className="Docs__List__Item">
                Any other materials you willingly submit to us such as articles,
                images, motion picture, feedback, or any information that
                considered as Personal Information under PDPA.
              </li>
            </ul>
            <p>
              You can choose not to provide us with your Personal Information,
              but then you may not be able to take advantage of some of the
              Website&apos;s features. Users who are uncertain about what
              information is mandatory are welcome to contact us.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Managing personal information',
        scrollTo: 'managing-personal-information',
        content: `You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Website or Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account on the Website or simply by contacting us.`,
      },
      {
        title: 'Storing personal information',
        scrollTo: 'storing-personal-information',
        content: (
          <Fragment>
            <p>
              We will retain and use your Personal Information for the period
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements unless a longer retention period is
              required or permitted by law. We may use any aggregated data
              derived from or incorporate your Personal Information after you
              update or delete it, but not in a manner that would identify you
              personally. Once the retention period expires, Personal
              Information shall be deleted. Therefore, the right to access, the
              right to erasure, the right to rectification and the right to data
              portability cannot be enforced after the expiration of the
              retention period.
            </p>
            <p>
              Unfortunately, at this point we do not provide the EU data center
              to host the data yet so we cannot provide a service on a shared
              server for you if you are EU citizens. We are able to provide you
              with an on-premise solution which allows you to host this at your
              private network or data center of your choice, please contact our
              sales for information.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Use and processing of collected information',
        scrollTo: 'use-and-processing-of-collected-information',
        content: (
          <Fragment>
            <p>
              In order to make our Website and Services available to you, or to
              meet a legal obligation, we need to collect and use certain
              Personal Information. If you do not provide the information that
              we request, we may not be able to provide you with the requested
              products or services. Some of the information we collect is
              directly from you via our Website. However, we may also collect
              Personal Information about you from other sources such as social
              media, emails, etc. Any of the information we collect from you may
              be used for the following purposes:
            </p>
            <ul className="Docs__List">
              <li className="Docs__List__Item">
                Create and manage user accounts
              </li>
              <li className="Docs__List__Item">Fulfill and manage orders</li>
              <li className="Docs__List__Item">Deliver products or services</li>
              <li className="Docs__List__Item">
                Improve products and services
              </li>
              <li className="Docs__List__Item">
                Send administrative information
              </li>
              <li className="Docs__List__Item">
                Send marketing and promotional communications
              </li>
              <li className="Docs__List__Item">
                Respond to inquiries and offer support
              </li>
              <li className="Docs__List__Item">Request user feedback</li>
              <li className="Docs__List__Item">Improve user experience</li>
              <li className="Docs__List__Item">Post customer testimonials</li>
              <li className="Docs__List__Item">
                Enforce terms and conditions and policies
              </li>
              <li className="Docs__List__Item">
                Protect from abuse and malicious users
              </li>
              <li className="Docs__List__Item">
                Respond to legal requests and prevent harm
              </li>
              <li className="Docs__List__Item">
                Run and operate our Website and Services
              </li>
            </ul>
            <p>
              Processing your Personal Information depends on how you interact
              with our Website, where you are located in the world and if one of
              the following applies: (i) You have given your consent for one or
              more specific purposes; (ii) Provision of information is necessary
              for the performance of an agreement with you and/or for any
              pre-contractual obligations thereof; (iii) Processing is necessary
              for compliance with a legal obligation to which you are subject;
              (iv) Processing is related to a task that is carried out in the
              public interest or in the exercise of official authority vested in
              us; (v) Processing is necessary for the purposes of the legitimate
              interests pursued by us or by a third party.
            </p>
            <p>
              Note that under some legislations we may be allowed to process
              information until you object to such processing (by opting out),
              without having to rely on consent or any other of the following
              legal bases below. In any case, we will be happy to clarify the
              specific legal basis that applies to the processing, and in
              particular whether the provision of Personal Information is a
              statutory or contractual requirement, or a requirement necessary
              to enter into a contract.
            </p>
            <p>
              We never sell your data to another party, we are using these
              Services of the following external data processors:
            </p>
            <ul className="Docs__List">
              {[
                'Amazon Web Services (Infrastructure and web hosting services/email) [Singapore/Japan]',
                'Slack [United States of America]',
                'Crisp [United States of America]',
                'Howuku [United States of America]',
                'Pipedrive [United States of America]',
                'Google Workspace [United States of America/Singapore/Worldwide]',
                'Google Analytics [United States of America/Singapore/Worldwide]',
                'Cookie Wow [Singapore/Thailand]',
                'GB Prime Pay [Thailand]',
              ].map((item, idx) => (
                <li className="Docs__List__Item" key={idx}>
                  {item}
                </li>
              ))}
            </ul>
          </Fragment>
        ),
      },
      {
        title: 'Information transfer and storage',
        scrollTo: 'information-transfer-and-storage',
        content: (
          <Fragment>
            <p>
              Depending on your location, data transfers may involve
              transferring and storing your information in a country other than
              your own. You are entitled to learn about the legal basis of
              information transfers to a country outside Thailand or to any
              international organization governed by public international law or
              set up by two or more countries, such as the UN, and about the
              security measures taken by us to safeguard your information. If
              any such transfer takes place, you can find out more by checking
              the relevant sections of this document or inquire with us using
              the information provided in the contact section.
            </p>
            <p>
              Your user data is safely stored in Singapore, and Thailand. The
              PDPA&nbsp;Form core infrastructure is hosted in Singapore. User
              data is stored in our core infrastructure. We are complying with
              PDPA and offer all the rights requested by the laws.
            </p>
            <p>
              Even though we are hosting some Personal Information outside
              Thailand, we are following proper data protection and selected a
              Data Center that has higher standards for both security and
              availability. As we cannot find any data center in Thailand that
              meets the standards so we are required to host our core
              infrastructure in Singapore and Japan as a disaster recovery site
              while some of the personal data used for billing is stored and
              processed in Thailand.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'The rights of users',
        scrollTo: 'the-rights-of-users',
        content: `You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) You have the right to object to collection, use or disclosure of your Personal Information at any time unless such doing is conducted for the purposes of our legitimate interests or other  corporation or individual or for carrying out public tasks and such processing is within your reasonable expectation; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof; and (viii) you have the right to complain to competent authorities pursuant to relevant laws if you believe that the collection, use or disclosure of your Personal Information is violating or not in compliance with relevant laws`,
      },
      {
        title: 'How to exercise these rights',
        scrollTo: 'how-to-exercise-these-rights',
        content: (
          <Fragment>
            <p>
              Any requests to exercise User rights can be directed to the owner
              through the contact details provided in this document. These
              requests can be exercised free of charge and will be addressed by
              the owner as early as possible.
            </p>
            <p>
              Online form is available at:{' '}
              <Link href={FORM_PATH} isExternal color={'#2879f9'}>
                {FORM_PATH}
              </Link>{' '}
              (Please mention in the form that the request is for PDPA Form
              website)
            </p>
          </Fragment>
        ),
        // content: `Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible.`,
      },
      {
        title: 'Billing and payments',
        scrollTo: 'billing-and-payments',
        content: `We use third-party payment processors to assist us in processing your payment information securely. Such third-party processors' use of your Personal Information is governed by their respective privacy policies which may or may not contain privacy protections as protective as this Privacy Policy. We suggest that you review their respective privacy policies.`,
      },
      {
        title: 'Product and service providers',
        scrollTo: 'project-and-service-providers',
        content: `We may contract with other companies to provide certain products and services. These service providers are not authorized to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements. We may share Personal Information for these purposes only with third-parties whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information Our service providers are given the information they need to perform their designated functions, and we do not authorize them to use or disclose Personal Information for their own marketing or other purposes.`,
      },
      {
        title: 'Privacy of children',
        scrollTo: 'privacy-of-children',
        content: (
          <Fragment>
            <p>
              We do not knowingly collect any Personal Information from children
              under the age of 20. If you are under the age of 20, please do not
              submit any Personal Information through our Website or Service. We
              encourage parents and legal guardians to monitor their
              children&apos;s Internet usage and to help enforce this Policy by
              instructing their children never to provide Personal Information
              through our Website or Service without their permission.
            </p>
            <p>
              If you have reason to believe that a child under the age of 20 has
              provided Personal Information to us through our Website or
              Service, please contact us.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Newsletters',
        scrollTo: 'newsletters',
        content: (
          <Fragment>
            <p>
              We offer electronic newsletters to which you may voluntarily
              subscribe at any time. We are committed to keeping your e-mail
              address confidential and will not disclose your email address to
              any third parties except as allowed in the information use and
              processing section or for the purposes of utilizing a third-party
              provider to send such emails. We will maintain the information
              sent via e-mail in accordance with applicable laws and
              regulations.
            </p>
            <p>
              You may choose to stop receiving our newsletter or marketing
              emails by following the unsubscribe instructions included in these
              emails or by contacting us. However, you will continue to receive
              essential transactional emails.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Cookies',
        scrollTo: 'cookies',
        content: (
          <Fragment>
            <p>
              The Website uses &quot;cookies&quot; to help personalize your
              online experience. A cookie is a text file that is placed on your
              hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you.
            </p>
            <p>
              We may use cookies to collect, store, and track information for
              statistical purposes to operate our Website and Services. You have
              the ability to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. If you choose to
              decline cookies, you will not be able to use and experience the
              features of the Website and Services.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'What are cookies?',
        scrollTo: 'what-are-cookies',
        content: (
          <Fragment>
            <p>
              Cookies are small pieces of data stored in text files that are
              saved on your computer or other devices when websites are loaded
              in a browser. They are widely used to remember you and your
              preferences, either for a single visit (through a &quot;session
              cookie&quot;) or for multiple repeat visits (using a
              &quot;persistent cookie&quot;).
            </p>
            <p>
              Session cookies are temporary cookies that are used during the
              course of your visit to the Website, and they expire when you
              close the web browser.
            </p>
            <p>
              Persistent cookies are used to remember your preferences within
              our Website and remain on your desktop or mobile device even after
              you close your browser or restart your computer. They ensure a
              consistent and efficient experience for you while visiting our
              Website or using our Services.
            </p>
            <p>
              Cookies may be set by the Website (&quot;first-party
              cookies&quot;), or by third parties, such as those who serve
              content or provide advertising or analytics services on the
              website (&quot;third party cookies&quot;). These third parties can
              recognize you when you visit our website and also when you visit
              certain other websites.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'What type of cookies do we use?',
        scrollTo: 'what-type-of-cookies-do-we-use',
        content: '',
      },
      {
        title: 'Necessary cookies',
        scrollTo: 'necessary-cookies',
        content:
          'Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our Website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account to access the content.',
        useH3: true,
        isSub: true,
      },
      {
        title: 'Functionality cookies',
        scrollTo: 'functionality-cookies',
        content:
          'Functionality cookies let us operate the Website and our Services in accordance with the choices you make. For example, we will recognize your username and remember how you customized the Website and Services during future visits.',
        useH3: true,
        isSub: true,
      },
      {
        title: 'Analytical cookies',
        scrollTo: 'analytic-cookies',
        content:
          'These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.',
        useH3: true,
        isSub: true,
      },
      {
        title: 'Social media cookies',
        scrollTo: 'social-media-cookies',
        content: (
          <Fragment>
            <p>
              Third-party cookies from social media sites (such as Facebook,
              Twitter, etc) let us track social network users when they visit
              our Website, use our Services or share content, by using a tagging
              mechanism provided by those social networks.
            </p>
            <p>
              These cookies are also used for event tracking and remarketing
              purposes. Any data collected with these tags will be used in
              accordance with our and social networks’ privacy policies. We will
              not collect or share any personally identifiable information from
              the user.
            </p>
          </Fragment>
        ),
        useH3: true,
        isSub: true,
      },
      {
        title: 'Do we use web beacons or tracking pixels?',
        scrollTo: 'do-we-use-web-beacons-or-tracking-pixels',
        content: (
          <Fragment>
            <p>
              Our emails may contain a &quot;web beacon&quot; (or &quot;tracking
              pixel&quot;) to tell us whether our emails are opened and verify
              any clicks through to links or advertisements within the email.
            </p>
            <p>
              We may use this information for purposes including determining
              which of our emails are more interesting to users and to query
              whether users who do not open our emails wish to continue
              receiving them.
            </p>
            <p>
              The pixel will be deleted when you delete the email. If you do not
              wish the pixel to be downloaded to your device, you should read
              the email in plain text view or with images disabled.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'What are your cookie options?',
        scrollTo: 'what-are-your-coolies-options',
        content: (
          <Fragment>
            <p>
              If you don&apos;t like the idea of cookies or certain types of
              cookies, you can change your browser&apos;s settings to delete
              cookies that have already been set and to not accept new cookies.
              To learn more about how to do this or to learn more about cookies,
              visit internetcookies.org
            </p>
            <p>
              Please note, however, that if you delete cookies or do not accept
              them, you might not be able to use all of the features our Website
              and Services offer.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Do Not Track signals',
        scrollTo: 'do-not-track-signals',
        content: `Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. Our Website does not track its visitors over time and across third party websites. However, some third party sites may keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you.`,
      },
      {
        title: 'Links to other websites',
        scrollTo: 'links-to-other-websites',
        content: `Our Website contains links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other websites or third-parties. We encourage you to be aware when you leave our Website and to read the privacy statements of each and every website that may collect Personal Information.`,
      },
      {
        title: 'Information security',
        scrollTo: 'information-security',
        content: (
          <Fragment>
            <p>
              We secure information you provide on computer servers in a
              controlled, secure environment, protected from unauthorized
              access, use, or disclosure. We maintain reasonable administrative,
              technical, and physical safeguards in an effort to protect against
              unauthorized access, use, modification, and disclosure of Personal
              Information in its control and custody. However, no data
              transmission over the Internet or wireless network can be
              guaranteed. Therefore, while we strive to protect your Personal
              Information, you acknowledge that (i) there are security and
              privacy limitations of the Internet which are beyond our control;
              (ii) the security, integrity, and privacy of any and all
              information and data exchanged between you and our Website cannot
              be guaranteed; and (iii) any such information and data may be
              viewed or tampered with in transit by a third-party, despite best
              efforts.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Data breach',
        scrollTo: 'data-breach',
        content: `In the event we become aware that the security of the Website has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the Website, send you an email.`,
      },
      {
        title: 'Legal disclosure',
        scrollTo: 'legal-disclosure',
        content: `We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. In the event we go through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account, and Personal Information will likely be among the assets transferred.`,
      },
      {
        title: 'Changes and amendments',
        scrollTo: 'changes-and-amendments',
        content: `We may update this Privacy Policy from time to time in our discretion and will notify you of any material changes to the way in which we treat Personal Information. When changes are made, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website or Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Data was collected.`,
      },
      {
        title: 'Acceptance of this policy',
        scrollTo: 'acceptance-of-this-policy',
        content: `You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.`,
      },
      {
        title: 'Contacting us',
        scrollTo: 'contacting-us2',
        content: `If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so via the contact form, send an email to dpo@ndatathoth.com or sending a letter to 1778 Summer Hub Office 6th Floor, Sukhumvit Road, Phra Khanong, Khlong Toei, Bangkok, Thailand 10110`,
      },
    ],
  },
  security: {
    title: 'Security',
    checkActive: 'securities',
    linkTo: 'securities',
    subMenus: [
      {
        title: 'Development and Operations',
        scrollTo: 'development-and-operations',
        content: (
          <Fragment>
            <p>
              All of our employees must be passed a background check and
              certified in information security awareness. Our technical staff
              and developers both web development and AI development are
              required to have extended knowledge of information security and
              secured system design. Our business processes are controlled with
              policy that takes security into consideration.
            </p>
            <p>
              Our information assets are logged and secured following company
              policy to ensure our customers that data is secured and accounted
              for. We also regularly audit our information assets.
            </p>
            <p>Standard Practice of System Development:</p>
            <ul className="Docs__List">
              <li className="Docs__List__Item">
                Follow OWASP framework (API Top 10, Web Top 10)
              </li>
              <li className="Docs__List__Item">
                Developers are regularly train and learn about common
                vulnerabilities and threats
              </li>
              <li className="Docs__List__Item">
                Code review for integrity and security
              </li>
              <li className="Docs__List__Item">
                Regularly update our dependencies and make sure none of them has
                known vulnerabilities
              </li>
              <li className="Docs__List__Item">
                Using Dynamic Application Security Test (DAST) and Static
                Application Security Test (SAST) to scan for vulnerabilities
              </li>
            </ul>
          </Fragment>
        ),
      },
      {
        title: 'Employee Access',
        scrollTo: 'employee-access',
        content: `Our policy prevents unauthorized employees or system administrators from gaining access to personal user data without a legitimate reason.  Limited exceptions may be made for customer support requests. Our company also implemented a need to know basis system where only relevant employees can access project information.  All of our employees are signed a Non-Disclosure Agreement when joining the company to protect our customers’ information.`,
      },
      {
        title: 'Business Continuity and Disaster Recovery',
        scrollTo: 'business-continuity-and-disaster-recovery',
        content: (
          <Fragment>
            <p>
              nDataThoth backs up all our critical information and assets and
              regularly performs a restore test on a backup to ensure swift
              recovery in case of disaster or failure. All of our backups are
              encrypted and saved to another location. Our RPO and RTO are 24
              hours for PDPAForm.com
            </p>
            <p>
              nDataThoth also maintains Business Continuity Plan (BCP) for
              common risks of our businesses.
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Infrastructure',
        scrollTo: 'infrastructure',
        content: (
          <Fragment>
            <p>
              All of our production facing customers are run in the cloud. We do
              not host or run our own infrastructure such as routers, physical
              servers, and load balancers. Our service is hosted on certified
              providers who have ISO27001, SOC1, SOC2, and other requirements or
              compliance requirements.
            </p>
            <p>
              Primary locations we hosted our services: Singapore, Japan (Tokyo)
            </p>
          </Fragment>
        ),
      },
      {
        title: 'Network Monitoring and Protection',
        scrollTo: 'networking-monitoring-and-protection',
        content: (
          <Fragment>
            <p>
              Our network are designed for fault tolerant, we also monitor
              regularly and protect our network from unauthorized access using
              best practices such as:
            </p>
            <ul className="Docs__List">
              <li className="Docs__List__Item">
                Virtual Private Cloud access only using VPN with Network Access
                Control List
              </li>
              <li className="Docs__List__Item">
                Firewall that can monitor incoming and outgoing network traffic
              </li>
              <li className="Docs__List__Item">IP Address filtering</li>
              <li className="Docs__List__Item">DDoS protection</li>
            </ul>
          </Fragment>
        ),
      },
      {
        title: 'Data Protection and Encryption',
        scrollTo: 'data-protection-and-encryption',
        content: `nDataThoth using TLS for data encryption in transit between our server and client.  Sensitive data also encrypted when at rest.  All sensitive data with a certain level of confidentiality will be required to encrypt on our employee computer.   `,
      },
      {
        title: 'Payment Processing Information',
        scrollTo: 'payment-processing-information',
        content: (
          <Fragment>
            <p>
              All online payment transactions are not processed at nDataThoth
              but instead outsourced to service providers with PCI DSS. No card
              information is stored at nDataThoth server. Our payment processing
              are:
            </p>
            <ul className="Docs__List">
              <li className="Docs__List__Item">Stripe.com</li>
            </ul>
          </Fragment>
        ),
      },
    ],
  },
}

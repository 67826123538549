import { useRef, Fragment, useMemo } from 'react'

import { withPage } from '@/lib/layout/Application/ApplicationLayout'

import Banner from '@/features/dashboard/packages/components/Banner'
import { PricingTableDesktop } from '@/features/dashboard/packages/components/PricingTable'
import SubscriptionModal, {
  RefProps,
} from '@/features/dashboard/packages/components/SubscriptionModal'

import {
  Plan,
  PackageDetail,
  PackageTokens,
  QuotaUsage,
  DowngradeInfo,
} from '@/features/dashboard/packages/types'
import { RecurringType } from '@/features/dashboard/payments/types'
import { PLANS } from '../../constants'

interface PackageDesktopPageProps {
  downgradeInfo: DowngradeInfo
  expiry: string
  packageDetail: PackageDetail
  packageTokens: PackageTokens
  quotaUsage: QuotaUsage
  recurring: RecurringType
  summariesUrl: string
}

const PackageDesktopPage = (props: PackageDesktopPageProps) => {
  const {
    downgradeInfo,
    expiry,
    packageDetail,
    packageTokens,
    quotaUsage,
    recurring,
    summariesUrl,
  } = props

  const planRef = useRef<RefProps>(null)

  const handleClickPlan = (plan: Plan) => {
    if (planRef.current) {
      planRef.current.setPlan(plan)
    }
  }

  const currentPackage = useMemo(() => {
    return PLANS.find!((pl) => pl.plan === packageDetail.name) as Plan
  }, [])

  return (
    <Fragment>
      <Banner
        packageDetail={packageDetail}
        quotaUsage={quotaUsage}
        recurring={recurring}
        expiry={expiry}
        onOpenUnsubModal={() => handleClickPlan(PLANS[0])}
        onOpenResubModal={() => handleClickPlan(currentPackage)}
        willBeDowngradedToPackage={downgradeInfo.willBeDowngradedToPackageName}
        cancelAtPeriodEnd={downgradeInfo.cancelAtPeriodEnd}
      />
      <PricingTableDesktop
        packageDetail={packageDetail}
        onClickPlan={handleClickPlan}
        cancelAtPeriodEnd={downgradeInfo.cancelAtPeriodEnd}
      />
      <SubscriptionModal
        ref={planRef}
        expiry={expiry}
        packageTokens={packageTokens}
        packageDetail={packageDetail}
        summariesUrl={summariesUrl}
      />
    </Fragment>
  )
}

export default withPage(PackageDesktopPage)

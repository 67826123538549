import { SUBSCRIPTION_LEVEL } from '@/features/dashboard/packages/constants'
import {
  Plan,
  PackageDetail,
  SubscriptionType,
} from '@/features/dashboard/packages/types'

export function getSubscriptionType(
  packageDetail: PackageDetail,
  plan: Plan,
): SubscriptionType {
  const fromSubscriptionLevel = SUBSCRIPTION_LEVEL[packageDetail.name]
  const toSubscriptionLevel = SUBSCRIPTION_LEVEL[plan.plan]

  if (fromSubscriptionLevel === 0) {
    return 'subscribe'
  }

  if (toSubscriptionLevel === 0) {
    return 'unsubscribe'
  }

  if (fromSubscriptionLevel < toSubscriptionLevel) {
    return 'upgrade'
  }

  if (fromSubscriptionLevel > toSubscriptionLevel) {
    return 'downgrade'
  }

  if (fromSubscriptionLevel === toSubscriptionLevel) {
    return 'resubscribe'
  }

  return 'unsubscribe'
}

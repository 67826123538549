import { Button, Input, Stack } from '@chakra-ui/react'

import { FormControl } from '@/components/Form'

import { getAuthenticityToken } from '../helpers'

type ForgotPasswordFormProps = {
  formActionPath: string
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { formActionPath } = props

  const authenticityToken = getAuthenticityToken()

  return (
    <form action={formActionPath} acceptCharset="UTF-8" method="post">
      <Stack spacing="6">
        <Input
          value={authenticityToken}
          type="hidden"
          name="authenticity_token"
        />

        <FormControl isRequired>
          <Input
            autoComplete="email"
            autoFocus
            id="admin_email"
            name="admin[email]"
            placeholder="อีเมล"
            size="lg"
            type="email"
          />
        </FormControl>

        <Button type="submit" size="xl">
          ส่งลิงก์ตั้งรหัสผ่านใหม่
        </Button>
      </Stack>
    </form>
  )
}

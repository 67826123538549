import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Input,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { FormControl } from '@/components/Form'
import { SigningSecret } from '../components'

import { Webhook, WebhookFormInput } from '../../types'

import { useWebhook } from '../queries'
import { useUpdateWebhook } from '../mutations'

import { webhookFormSchema } from '../schema'

type WebhookSettingSectionProps = {
  defaultWebhookValue: Webhook
}

export const WebhookSettingSection = (props: WebhookSettingSectionProps) => {
  const { defaultWebhookValue } = props

  const { data: webhook } = useWebhook({
    initialData: defaultWebhookValue,
  })
  const { isLoading, mutate: updateWebhook } = useUpdateWebhook()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<WebhookFormInput>({
    resolver: zodResolver(webhookFormSchema),
    defaultValues: {
      method: defaultWebhookValue.method,
      url: defaultWebhookValue.url,
    },
  })

  const isChecked = webhook?.status === 'enable'

  return (
    <Box sx={{ p: '24px 32px', borderBottom: '1px solid #e8e8e8' }}>
      <Stack spacing="6">
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Text as="h2" sx={{ fontSize: '20px', fontWeight: 'semibold' }}>
            Webhook Setting
          </Text>

          <HStack spacing={4}>
            <Text as="label" color="gray.400">
              Status: {isChecked ? 'Enabled' : 'Disabled'}
            </Text>
            <Switch
              isChecked={isChecked}
              isDisabled={isLoading}
              size="lg"
              colorScheme="switch"
              onChange={() => {
                updateWebhook({
                  data: {
                    status: isChecked ? 'disable' : 'enable',
                  },
                })
              }}
            />
          </HStack>
        </Flex>

        <Collapse in={isChecked}>
          <Stack spacing="6" sx={{ fontSize: 'sm' }}>
            <form
              onSubmit={handleSubmit((data) => {
                updateWebhook({ data })
              })}
            >
              <Stack spacing="4" direction="row">
                <FormControl
                  label="Method"
                  sx={{ w: '104px', flex: '1 0 104px' }}
                >
                  <Select
                    size="lg"
                    defaultValue={defaultWebhookValue.method}
                    {...register('method')}
                  >
                    <option value="post">POST</option>
                    <option value="get">GET</option>
                  </Select>
                </FormControl>
                <FormControl label="Webhook URL" errorMsg={errors.url?.message}>
                  <Input
                    size="lg"
                    placeholder="http://www.yourwebhook.com"
                    {...register('url')}
                  />
                </FormControl>
                <Button
                  size="lg"
                  type="submit"
                  sx={{
                    flex: '1 0 120px',
                    mt: '32px !important',
                  }}
                  isLoading={isLoading}
                  isDisabled={!isDirty}
                >
                  บันทึก
                </Button>
              </Stack>
            </form>

            {webhook?.signing_key && (
              <SigningSecret secretKey={webhook.signing_key} />
            )}
          </Stack>
        </Collapse>
      </Stack>
    </Box>
  )
}

import { Fragment } from 'react'
import { capitalize, get } from 'lodash'
import { Box, Grid, Text, Flex, Button } from '@chakra-ui/react'

import { withPage } from '@/lib/layout/Application/ApplicationLayout'
import IconCheckCircle from '@/components/atoms/Icons/IconCheckCircle'

import {
  PLANS,
  TABLE_COLUMN_HEADERS,
  MODAL_DATA,
} from '@/features/dashboard/packages/constants'
import { getSubscriptionType } from '@/features/dashboard/packages/helpers'
import { logEvent } from '@/lib/stats'

import { Plan, PackageDetail } from '@/features/dashboard/packages/types'

interface CheckCircleProps {
  isChecked: boolean
}

const CheckCircle = ({ isChecked }: CheckCircleProps) => {
  return isChecked ? <IconCheckCircle /> : <Text>-</Text>
}

interface PricingTableDesktopProps {
  onClickPlan: (plan: Plan) => void
  packageDetail: PackageDetail
  cancelAtPeriodEnd: boolean
}

const PricingTableDesktop = ({
  packageDetail,
  onClickPlan,
  cancelAtPeriodEnd,
}: PricingTableDesktopProps) => {
  const renderButton = (plan: Plan) => {
    const isUsedCurrentPackage = plan.plan === get(packageDetail, 'name', '')

    if (get(packageDetail, 'name') !== 'free' && plan.plan === 'free') {
      return (
        <Box
          sx={{
            color: `${plan.color}.400`,
            boxShadow: `0 -4px inset currentColor`,
          }}
        ></Box>
      )
    }

    return (
      <Button
        variant="ghost"
        layerStyle="pricePlaneTableItem"
        isDisabled={!cancelAtPeriodEnd && isUsedCurrentPackage}
        colorScheme={plan.color}
        sx={{
          color: `${plan.color}.400`,
          fontSize: 'md',
          fontWeight: 'medium',
          padding: 'calc(var(--global-space) * 0.8 - 4px) 0',
          borderRight: '1px solid',
          borderColor: 'gray.300',
          boxShadow: `0 -4px inset currentColor`,
          borderRadius: 'none',
          cursor: 'pointer',
          outline: 'none !important',
          '&:focus': {
            boxShadow: `0 -4px inset currentColor`,
          },
          '&:active': {
            boxShadow: `0 -4px inset currentColor`,
          },
          '&:disabled': {
            opacity: 1,
            borderBottom: '1px solid',
            borderColor: 'gray.300',
            cursor: 'not-allowed',
            backgroundColor: 'gray.200',
            color: 'gray.500',
          },
        }}
        onClick={() => {
          logEvent({
            ga: {
              category: 'Packages',
              action: 'Click choose package',
              label: capitalize(String(plan)),
            },
          })
          onClickPlan(plan)
        }}
      >
        {cancelAtPeriodEnd && isUsedCurrentPackage
          ? 'ใช้แพ็กเกจนี้ต่อ'
          : isUsedCurrentPackage
          ? 'คุณกำลังใช้แพ็กเกจนี้'
          : MODAL_DATA[getSubscriptionType(packageDetail, plan)](plan)
              .buttonLabel}
      </Button>
    )
  }

  return (
    <Box sx={{ pos: 'relative' }}>
      <Box
        className="PricingTableAnchor"
        sx={{ pos: 'absolute', top: -50 }}
      ></Box>

      <Grid
        sx={{
          backgroundColor: 'white',
          fontSize: 'md',
          fontFamily: 'body',
          gridTemplateColumns: '1fr repeat(4, 200px)',
          gridTemplateRows: '72px repeat(10, max-content)',
          gridAutoFlow: 'column',
          borderTop: '1px solid',
          borderRight: '1px solid,',
          borderColor: 'gray.300',
          gridGap: 0,
          '> *': {
            borderBottom: '1px solid',
            borderRight: '1px solid',
            borderColor: 'gray.300',
          },
          pos: 'relative',
        }}
      >
        {TABLE_COLUMN_HEADERS.map((head) => (
          <Text
            key={head.title}
            sx={{
              gridColumn: 1 / 2,
              padding:
                'calc(var(--global-space) * 0.5) calc(var(--global-space) * 1.2)',
              justifySelf: 'start',
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'center',
              alignItems: 'start',
              width: '100%',
              borderLeft: '1px solid',
              borderColor: 'gray.300',
              fontWeight: 'medium',
              '&:nth-of-type(11)': {
                backgroundColor: 'gray.50',
                border: 'none',
                borderRight: '1px solid',
                borderColor: 'gray.300',
              },
            }}
          >
            {head.title}
            {head.mini && (
              <Text
                as="span"
                sx={{
                  fontSize: 'xs',
                  fontWeight: 'light',
                  color: 'gray.600',
                }}
              >
                {head.mini}
              </Text>
            )}
          </Text>
        ))}

        {PLANS.map((plan, idx) => {
          return (
            <Fragment key={idx}>
              <Flex
                layerStyle="pricePlaneTableItem"
                sx={{
                  color: `${plan.color}.400`,
                  fontSize: 'xl',
                  fontWeight: 'medium',
                }}
              >
                {plan.prefix}
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <Text
                  sx={{
                    fontWeight: 'medium',
                    lineHeight: 5,
                  }}
                >
                  {plan.price.title}
                  {plan.price.unit && (
                    <Text
                      as="span"
                      sx={{
                        fontWeight: 'medium',
                        fontSize: 'md',
                      }}
                    >
                      {plan.price.unit}
                    </Text>
                  )}
                </Text>
                {plan.price.sub && (
                  <Text
                    sx={{
                      fontWeight: 'medium',
                      fontSize: 'md',
                    }}
                  >
                    {plan.price.sub}
                  </Text>
                )}
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">{plan.user.count}</Flex>
              <Flex layerStyle="pricePlaneTableItem">{plan.form.count}</Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <Text
                  sx={{
                    fontWeight: 'medium',
                    lineHeight: 5,
                  }}
                >
                  {plan.submission.count}
                </Text>
                {plan.submission.suffix && (
                  <Text
                    sx={{
                      fontSize: 'md',
                    }}
                  >
                    {plan.submission.suffix}
                  </Text>
                )}
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <CheckCircle isChecked={plan.custom_domain.checked} />
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <CheckCircle isChecked={plan.sub_domain.checked} />
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <CheckCircle isChecked={plan.custom_logo.checked} />
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                <CheckCircle isChecked={plan.api.checked} />
              </Flex>
              <Flex layerStyle="pricePlaneTableItem">
                {plan.data.desk_label}
              </Flex>

              {renderButton(plan)}
            </Fragment>
          )
        })}
      </Grid>
    </Box>
  )
}

export default withPage(PricingTableDesktop)

import { isEmpty } from 'lodash'

import {
  RawAddress,
  _RawAddress,
  Address,
  TaxpayerType,
} from '@/features/dashboard/payments/types'

export function convertRawAddress(rawAddress: _RawAddress): Address {
  if (rawAddress.attributes) {
    return {
      id: `${rawAddress.id}`,
      name: rawAddress.attributes.title,
      address: rawAddress.attributes.details,
      taxId: rawAddress.attributes.taxpayer_id,
      taxPayerType: rawAddress.attributes.taxpayer_type,
      primary: rawAddress.attributes.primary,
    }
  }

  return {
    id: `${rawAddress.id}`,
    name: rawAddress.title,
    address: rawAddress.details,
    taxId: rawAddress.taxpayer_id,
    taxPayerType: rawAddress.taxpayer_type,
    primary: rawAddress.primary,
  }
}

export function convertAddress(address: Address): RawAddress {
  return {
    id: address.id,
    title: address.name,
    details: address.address,
    taxpayer_id: address.taxId,
    taxpayer_type: address.taxPayerType,
    primary: address.primary,
  }
}

export function filterAddresses(
  addresses: Address[],
  taxPayerType: TaxpayerType,
): Address[] {
  return addresses.filter((addr) => addr.taxPayerType === taxPayerType)
}

export function transformAddresses(addresses: RawAddress[]): Address[] {
  const newAddresses = addresses.map(convertRawAddress)

  return newAddresses
}

export function getDefaultAddress(addresses: Address[]): string {
  if (isEmpty(addresses)) {
    return ''
  }

  const address = addresses.find((addr) => addr.primary)

  if (address) {
    return address.id
  }

  return addresses.length ? addresses[0].id : ''
}

export function getAddressType(addresses: Address[]): TaxpayerType {
  if (isEmpty(addresses)) {
    return TaxpayerType.COMPANY
  }

  const address = addresses.find((addr) => addr.primary)

  if (address) {
    return address.taxPayerType
  }

  return TaxpayerType.COMPANY
}

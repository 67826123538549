import { Fragment } from 'react'
import className from 'classnames'
import { Link as ScrollLink } from 'react-scroll'
import Sticky from 'react-stickynode'

import { termsAndPrivacyIndexes, scrollProps } from '../constants'

interface Props {
  lastSegment: string
}

const Sidebar = (props: Props) => {
  const { lastSegment } = props

  const checkActive = (title: string) => title === lastSegment

  const renderMenu = () => {
    return Object.values(termsAndPrivacyIndexes).map((item, idx) => {
      const isActive = checkActive(item.checkActive)

      return (
        <Fragment key={idx}>
          <div
            className={className('Sidebar__Title', {
              'Sidebar__Title--active': isActive,
            })}
          >
            <a href={`/${item.linkTo}`}>{item.title}</a>
          </div>
          {isActive && item.subMenus && (
            <ul className="Sidebar__List">
              {item.subMenus.map((menu, idx) => (
                <li
                  key={idx}
                  className={className('Sidebar__List__Item', {
                    'Sidebar__List__Item--issub': menu.isSub,
                  })}
                >
                  <ScrollLink
                    to={menu.scrollTo}
                    {...scrollProps}
                    className="Sidebar__Link"
                  >
                    {menu.title}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          )}
        </Fragment>
      )
    })
  }

  return (
    <aside className="Sidebar">
      <Sticky top={50} bottomBoundary="#EndContent">
        {renderMenu()}
      </Sticky>
    </aside>
  )
}

export default Sidebar

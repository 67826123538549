import { withPage } from '@/lib/layout/Application'
import { Box } from '@chakra-ui/react'
import { UpdatePasswordForm, UserSettingLayout } from '../components'

function UpdatePasswordPage() {
  return (
    <UserSettingLayout title="เปลี่ยนรหัสผ่านใหม่">
      <Box sx={{ px: 6, py: 8 }}>
        <UpdatePasswordForm />
      </Box>
    </UserSettingLayout>
  )
}

export default withPage(UpdatePasswordPage)

import { Fragment } from 'react'
import { TableOrder, Box, HStack, VStack, Summary } from '@datawowio/payment-ui'
import { useToast } from '@chakra-ui/react'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants/toast'

import BreadCrumb from '@/features/dashboard/payments/components/BreadCrumb'
import SelectAddress from '@/features/dashboard/payments/components/SelectAddress'

import {
  withPaymentSummaryContext,
  usePaymentSummaryContext,
} from '@/features/dashboard/payments/contexts/paymentSummaryContext'
import { withPaymentLayout } from '@/features/dashboard/payments/contexts/paymentProvider'
import { useCheckoutServices } from '@/features/dashboard/payments/services'
import { CONTACT } from '@/features/dashboard/payments/constants'

import { RecurringType } from '@/features/dashboard/payments/types'

interface Props {
  hostUrl: string
}

type Errors = Partial<{
  selectedAddress: string
}>

const PaymentSummaryPage = ({ hostUrl }: Props) => {
  const {
    addressType,
    addresses,
    packageData,
    recurringType,
    selectedAddress,
    setRecurringType,
  } = usePaymentSummaryContext()

  const CheckoutServices = useCheckoutServices(packageData)

  const toast = useToast()

  return (
    <Fragment>
      <Box backgroundColor="#fff" p="6" borderRadius="8px">
        <BreadCrumb labels={['สรุปรายการสั่งซื้อ']} />

        <HStack spacing="6" sx={{ alignItems: 'start' }}>
          <VStack spacing="6" sx={{ flex: '1 1 auto', alignItems: 'stretch' }}>
            <TableOrder
              {...packageData}
              recurring={recurringType}
              onChangePackage={(data) =>
                setRecurringType(data.recurring as RecurringType)
              }
            />

            <SelectAddress />
          </VStack>
          <Box
            sx={{
              maxW: '440px',
              minW: '440px',
            }}
          >
            <Summary
              {...packageData}
              contact={CONTACT}
              address={
                addresses.find((addr) => addr.id === selectedAddress)?.address
              }
              displayAcceptTerms={false}
              linkTermOfUse={`${hostUrl}/terms`}
              linkPrivacy={`${hostUrl}/policies`}
              onErrors={(errors) => {
                if (errors.selectedAddress) {
                  toast({
                    ...DEFAULT_TOAST_OPTIONS,
                    title: 'เกิดข้อผิดพลาด!',
                    description: errors.selectedAddress,
                    status: 'error',
                  })
                }
              }}
              validate={() => {
                const errors: Errors = {}

                if (!selectedAddress) {
                  errors.selectedAddress =
                    'จำเป็นต้องเลือกที่อยู่สำหรับออกใบกำกับภาษี'
                }

                return errors
              }}
              onSubmit={() =>
                CheckoutServices.setup({
                  data: {
                    address_id: selectedAddress,
                    package: packageData.id,
                    recurring: recurringType,
                  },
                })
              }
              showWHTException={addressType === 'company'}
            />
          </Box>
        </HStack>
      </Box>
    </Fragment>
  )
}

export default withPaymentLayout(withPaymentSummaryContext(PaymentSummaryPage))

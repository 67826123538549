import { t } from '@/lib/helpers'

import { Subject } from '../types'

export const getSubjects = (formName: string): Subject[] => [
  {
    key: 'forgotten',
    label: t('pages.forms.new.body.form.subject.forgotten.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.forgotten.optional1.label'),
      options: [
        {
          label: t(
            'pages.forms.new.body.form.subject.forgotten.optional1.one',
            { name: formName },
          ),
        },
        {
          label: t('pages.forms.new.body.form.subject.forgotten.optional1.two'),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.forgotten.optional1.three',
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.forgotten.optional1.four',
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.forgotten.optional1.five',
          ),
        },
        {
          label: t('pages.forms.new.body.form.subject.forgotten.optional1.six'),
        },
      ],
    },
    optional2: {
      label: t('pages.forms.new.body.form.subject.forgotten.optional2.label', {
        name: formName,
      }),
    },
  },
  {
    key: 'transfer',
    label: t('pages.forms.new.body.form.subject.transfer.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.transfer.optional1.label', {
        name: formName,
      }),
      options: [
        {
          label: t('pages.forms.new.body.form.subject.transfer.optional1.one'),
        },
        {
          label: t('pages.forms.new.body.form.subject.transfer.optional1.two'),
        },
      ],
    },
    optional2: {
      label: t('pages.forms.new.body.form.subject.transfer.optional2.label'),
    },
  },
  {
    key: 'know_how',
    label: t('pages.forms.new.body.form.subject.know_how.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.know_how.optional1.label'),
      options: [
        {
          label: t('pages.forms.new.body.form.subject.know_how.optional1.one', {
            name: formName,
          }),
        },
        {
          label: t('pages.forms.new.body.form.subject.know_how.optional1.two', {
            name: formName,
          }),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.know_how.optional1.three',
            { name: formName },
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.know_how.optional1.four',
            { name: formName },
          ),
        },
        {
          label: t('pages.forms.new.body.form.subject.know_how.optional1.five'),
        },
        {
          label: t('pages.forms.new.body.form.subject.know_how.optional1.six'),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.know_how.optional1.seven',
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.know_how.optional1.eight',
          ),
        },
      ],
    },
    optional2: {
      label: t('pages.forms.new.body.form.subject.know_how.optional2.label'),
    },
  },
  {
    key: 'correct',
    label: t('pages.forms.new.body.form.subject.correct.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.correct.optional1.label', {
        name: formName,
      }),
    },
    optional2: {
      label: t('pages.forms.new.body.form.subject.correct.optional2.label'),
    },
  },
  {
    key: 'stop_using',
    label: t('pages.forms.new.body.form.subject.stop_using.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.stop_using.optional1.label'),
    },
  },
  {
    key: 'stop_process',
    label: t('pages.forms.new.body.form.subject.stop_process.label', {
      name: formName,
    }),
    optional1: {
      label: t(
        'pages.forms.new.body.form.subject.stop_process.optional1.label',
        { name: formName },
      ),
      options: [
        {
          label: t(
            'pages.forms.new.body.form.subject.stop_process.optional1.one',
            { name: formName },
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.stop_process.optional1.two',
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.stop_process.optional1.three',
          ),
        },
        {
          label: t(
            'pages.forms.new.body.form.subject.stop_process.optional1.four',
            { name: formName },
          ),
        },
      ],
    },
    optional2: {
      label: t(
        'pages.forms.new.body.form.subject.stop_process.optional2.label',
      ),
    },
  },
  {
    key: 'object',
    label: t('pages.forms.new.body.form.subject.object.label', {
      name: formName,
    }),
    optional1: {
      label: t('pages.forms.new.body.form.subject.object.optional1.label', {
        name: formName,
      }),
    },
  },
]

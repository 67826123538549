export type Route = {
  name: string
  pattern: string
  regex: RegExp
}

export const routes: Route[] = [
  {
    name: 'invoices',
    pattern: '/invoices',
    regex: /\/invoices/,
  },
  {
    name: 'packages',
    pattern: '/packages',
    regex: /\/packages/,
  },
]

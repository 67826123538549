import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { fetchAPI } from '@/lib/helpers/api'
import { DEFAULT_TOAST_OPTIONS, ERROR_TOAST_OPTIONS } from '@/lib/constants'

type Payload = {
  params: {
    token: string
  }
}

export const useDeleteForm = () => {
  const toast = useToast()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    ({ params }) => {
      return fetchAPI({
        path: `/forms/${params.token}`,
        method: 'delete',
      })
    },
    {
      onSuccess() {
        toast({
          ...DEFAULT_TOAST_OPTIONS,
        })

        window.setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      onError(error) {
        toast({
          ...ERROR_TOAST_OPTIONS,
          description: error.response?.data.errors,
        })
      },
    },
  )
}

import { useState } from 'react'
import { Button, Stack, Icon, Flex, Text, useClipboard } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { FiEye, FiCopy, FiRefreshCw } from 'react-icons/fi'

import { MotionBox, MotionButton, MotionFlex } from '@/components/animations'

import { useRenewKey } from '../mutations'

type SigningSecretProps = {
  secretKey: string
}

export const SigningSecret = (props: SigningSecretProps) => {
  const { secretKey } = props

  const [open, setOpen] = useState(false)
  const { onCopy, hasCopied } = useClipboard(secretKey)

  const { mutate: renewKey, isLoading } = useRenewKey()

  return (
    <Stack>
      <Text fontWeight="500">Signing Secret</Text>

      <MotionFlex
        sx={{
          gap: '16px',
          pos: 'relative',
          button: {
            width: '120px',
          },
        }}
      >
        <AnimatePresence initial={false}>
          {open && (
            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key="3"
              sx={{
                pos: 'relative',
                flex: '1 0',
              }}
            >
              <Flex
                sx={{
                  alignItems: 'center',
                  bg: 'gray.100',
                  border: '1px solid #e8e8e8',
                  borderRadius: '4px',
                  color: 'gray.600',
                  fontSize: 'md',
                  h: '48px',
                  px: 4,
                }}
              >
                {secretKey}
              </Flex>

              <Button
                leftIcon={<Icon as={FiRefreshCw} />}
                variant="link"
                sx={{ pos: 'absolute', right: 0, top: '-30px' }}
                isLoading={isLoading}
                onClick={() => renewKey()}
              >
                Roll Key
              </Button>
            </MotionBox>
          )}
          {open ? (
            <MotionButton
              leftIcon={<Icon as={FiCopy} />}
              variant="ghost"
              size="lg"
              colorScheme="gray"
              onClick={onCopy}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key="2"
            >
              {hasCopied ? 'สำเร็จ!' : 'คัดลอก'}
            </MotionButton>
          ) : (
            <MotionButton
              leftIcon={<Icon as={FiEye} />}
              size="lg"
              sx={{ width: '120px' }}
              onClick={() => setOpen((s) => !s)}
              key="1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, x: 800, position: 'absolute' }}
              transition={{ type: 'tween', duration: 0.3, ease: 'linear' }}
            >
              Reveal
            </MotionButton>
          )}
        </AnimatePresence>
      </MotionFlex>
    </Stack>
  )
}

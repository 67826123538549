import { SuccessFeedback, Box } from '@datawowio/payment-ui'

import { withPaymentLayout } from '@/features/dashboard/payments/contexts/paymentProvider'

interface Props {
  email: string
  hostUrl: string
}

const PaymentSuccessPage = ({ hostUrl }: Props) => {
  return (
    <Box
      backgroundColor="#fff"
      borderRadius="8px"
      maxWidth="850px"
      m="0 auto"
      p="10"
    >
      <SuccessFeedback
        onClickBack={() => {
          window.location.href = `${hostUrl}`
        }}
      />
    </Box>
  )
}

export default withPaymentLayout(PaymentSuccessPage)

import { UseFormHandleSubmit, FieldValues } from 'react-hook-form'
import { Button, Text, Heading, Image, VStack } from '@chakra-ui/react'

import Sent from '@/images/Sent_Validation_Mail.svg'

interface Props {
  handleSubmitConfirm: UseFormHandleSubmit<FieldValues>
  getValuesEmail: (e: string) => string
  loading: boolean
  discard: () => void
}

export const SentMailConfirm = (props: Props) => {
  const { handleSubmitConfirm, getValuesEmail, loading, discard } = props
  return (
    <form onSubmit={handleSubmitConfirm(discard)}>
      <VStack spacing="24px">
        <Image src={Sent} alt="sent validation email" />
        <VStack spacing="10px">
          <Heading
            as="h3"
            sx={{ pb: 0, fontSize: '24px', textAlign: 'center' }}
          >
            กรุณายืนยันอีเมล
          </Heading>
          <Text
            sx={{
              fontSize: '16px',
              color: 'gray.600',
              textAlign: 'center',
            }}
          >
            คุณต้องทำการยืนยันการเปลี่ยนอีเมล กรุณาตรวจสอบกล่องขาเข้าของอีเมล{' '}
            <Text as="strong" color="primary.400" fontWeight="600">
              {getValuesEmail('new')}
            </Text>{' '}
            ของคุณ เพื่อทำการยืนยัน.
            <br />
            <Text as="strong" color="primary.400" fontWeight="600">
              ภายใน 3 วัน
            </Text>
          </Text>
        </VStack>
        <Button
          colorScheme="brand"
          type="submit"
          sx={{ width: '100%', height: '64px' }}
          isLoading={loading}
        >
          ยืนยัน
        </Button>
      </VStack>
    </form>
  )
}

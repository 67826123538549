import { useRef } from 'react'
import { Box, Text } from '@chakra-ui/react'

import { withPage } from '@/lib/layout/Application/ApplicationLayout'

import Banner from '@/features/dashboard/packages/components/Banner'
import PricingTableMobile from '@/features/dashboard/packages/components/PricingTable/Mobile'
import SubscriptionModal, {
  RefProps,
} from '@/features/dashboard/packages/components/SubscriptionModal'

import {
  PackageDetail,
  PackageTokens,
  QuotaUsage,
  Plan,
} from '@/features/dashboard/packages/types'
import { RecurringType } from '@/features/dashboard/payments/types'

interface PackagesMobilePageProps {
  summariesUrl: string
  packageDetail: PackageDetail
  packageTokens: PackageTokens
  quotaUsage: QuotaUsage
  recurring: RecurringType
  expiry: string
}

function PackageMobilePage({
  packageDetail,
  summariesUrl,
  packageTokens,
  quotaUsage,
  recurring,
  expiry,
}: PackagesMobilePageProps) {
  const planRef = useRef<RefProps>(null)

  const handleClickPlan = (plan: Plan) => {
    if (planRef.current) {
      planRef.current.setPlan(plan)
    }
  }

  return (
    <Box className="DashboardPackagesIndex">
      <Box
        sx={{
          fontWeight: 'medium',
        }}
      >
        <Box
          sx={{
            background: 'white',
            pt: 4,
            px: 6,
            pb: 8,
          }}
        >
          <Text
            sx={{
              fontSize: '2xl',
              py: 4,
            }}
          >
            Package
          </Text>
          <Box>
            <Banner
              packageDetail={packageDetail}
              quotaUsage={quotaUsage}
              recurring={recurring}
              expiry={expiry}
            />
          </Box>
        </Box>

        <PricingTableMobile
          packageDetail={packageDetail}
          onClickPlan={handleClickPlan}
        />
        <SubscriptionModal
          ref={planRef}
          expiry={expiry}
          packageTokens={packageTokens}
          packageDetail={packageDetail}
          summariesUrl={summariesUrl}
        />
      </Box>
    </Box>
  )
}

export default withPage(PackageMobilePage)

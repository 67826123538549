import { Fragment } from 'react'

import DocsLayout from '@/features/termAndPrivacy/components/DocsLayout'
import { renderTemplate } from '@/features/termAndPrivacy/helpers'

const Security = () => {
  return (
    <Fragment>
      <h1>Security</h1>
      <p>
        PDPAForm.com as in nDataThoth Limited takes data security very
        seriously, we are certified with ISO27001:2003 for our information
        security including but not limited to our physical security, development
        security, and infrastructure security.
      </p>
      {renderTemplate('security')}
      <div id="EndContent">
        This document was last updated on April 24, 2020
      </div>
    </Fragment>
  )
}

export default DocsLayout(Security)

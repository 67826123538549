import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'

import { getErrorToastObject } from '@/lib/helpers/utils'
import checkoutAPI from '@/features/dashboard/payments/api/checkout'
import { logEvent, logEcommerce } from '@/lib/stats'
import { useAppContext } from '@/lib/layout/Application'

import {
  CheckoutProps,
  CheckoutSetupProps,
  Package,
} from '@/features/dashboard/payments/types'
import { UnsubscriptionPayload } from '../../packages/types'

type CheckoutSetup = (args: {
  data: CheckoutSetupProps
}) => Promise<AxiosResponse>
type Subscribe = (args: { data: CheckoutProps }) => Promise<AxiosResponse>
type Unsubscribe = (data: UnsubscriptionPayload) => Promise<AxiosResponse>
type Resubscribe = () => Promise<AxiosResponse>

interface UseAddressServicesResult {
  isRequesting: boolean
  resubscribe: Resubscribe
  setup: CheckoutSetup
  subscribe: Subscribe
  unsubscribe: Unsubscribe
}

export function useCheckoutServices(
  packageData?: Package,
  onSubscribe?: (res: AxiosResponse) => Promise<boolean>,
): UseAddressServicesResult {
  const toast = useToast()
  const { currentPackage } = useAppContext()

  const item_id = `${packageData?.name}_${packageData?.recurringType}`

  const price = Number(packageData?.price)

  const value = packageData?.recurringType === 'year' ? price * 12 : price

  const currency = 'THB'

  const ecommerceItemLog = {
    value,
    items: [
      {
        item_id,
        item_name: item_id,
        currency,
        price: value,
        quantity: 1,
      },
    ],
  }

  const { mutateAsync: setup, isLoading: isLoadingSetup } = useMutation(
    checkoutAPI.setup,
    {
      onSuccess: (res) => {
        logEvent({
          ga: {
            category: 'Checkout',
            action: 'Click proceed payment',
            label: packageData?.name,
          },
          fb: {
            event: 'InitiateCheckout',
          },
        })

        logEcommerce('add_to_cart', ecommerceItemLog)

        window.location.href = `/payments/checkouts?token=${res.data.token}`
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: subscribe, isLoading: isLoadingSubscribe } = useMutation(
    checkoutAPI.subscribe,
    {
      onSuccess: async (res, data) => {
        if (onSubscribe) {
          const result = await onSubscribe(res)

          if (!result) return
        }

        logEvent({
          ga: {
            category: 'Checkout',
            action: 'Payment success',
          },
          fb: {
            event: 'Subscribe',
            value: price.toString(),
            currency,
            predicted_ltv: value.toString(),
          },
        })

        logEcommerce('purchase', ecommerceItemLog)

        window.location.href = `/payments/success?token=${data.data.token}`
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: unsubscribe, isLoading: isLoadingUnsubscribe } =
    useMutation<AxiosResponse, AxiosError, UnsubscriptionPayload>(
      checkoutAPI.unsubscribe,
      {
        onSuccess: () => {
          logEvent({
            ga: {
              category: 'Cancel success',
              action: 'Cancel success',
              label: currentPackage.plan,
            },
          })
          window.location.reload()
        },
        onError: (error: AxiosError) => {
          toast(getErrorToastObject(error))
        },
      },
    )

  const { mutateAsync: resubscribe, isLoading: isLoadingResubscribe } =
    useMutation<AxiosResponse, AxiosError, void>(checkoutAPI.resubscribe, {
      onSuccess: () => {
        window.location.reload()
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    })

  return {
    isRequesting:
      isLoadingSubscribe ||
      isLoadingUnsubscribe ||
      isLoadingResubscribe ||
      isLoadingSetup,
    resubscribe,
    setup,
    subscribe,
    unsubscribe,
  }
}

import { Link, Text, VStack } from '@chakra-ui/react'

import { AuthLayout, ResendConfirmationForm } from '../components'

import { LOGIN_PATH, POLICIES_PATH, TERMS_PATH } from '../constants'
import { AuthPageProps } from '../types'

function ResendConfirmationPage(props: AuthPageProps) {
  const { formActionPath, flash } = props

  return (
    <AuthLayout
      flash={flash}
      title="ส่งคำขอการยืนยันบัญชีอีกครั้ง"
      subtitle={
        <Text sx={{ fontWeight: 500 }}>
          กรอกอีเมลด้านล่างเพื่อส่งคำขอการยืนยันบัญชี
        </Text>
      }
      footer={
        <VStack spacing="4" sx={{ fontWeight: 500 }}>
          <Text>
            โดยการสร้างบัญชีคุณเห็นด้วยกับ&nbsp;
            <Link href={TERMS_PATH} isExternal>
              ข้อตกลงและเงื่อนไข
            </Link>
            &nbsp;และ&nbsp;
            <Link href={POLICIES_PATH} isExternal>
              นโยบายความเป็นส่วนตัว
            </Link>
          </Text>
          <Text>
            มีบัญชีอยู่แล้ว?&nbsp;
            <Link href={LOGIN_PATH}>เข้าสู่ระบบ</Link>
          </Text>
        </VStack>
      }
    >
      <ResendConfirmationForm formActionPath={formActionPath} />
    </AuthLayout>
  )
}

export default ResendConfirmationPage

import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { Box, Grid, Summary, useToast } from '@datawowio/payment-ui'
import { useStripe } from '@stripe/react-stripe-js'

import { SelectCreditCard } from '@/features/dashboard/payments/components/SelectCreditCard'
import BreadCrumb from '@/features/dashboard/payments/components/BreadCrumb'

import { ERROR_TOAST_OPTIONS } from '@/lib/constants/toast'

import {
  CONTACT,
  LINK_TERM_OF_USE,
  LINK_PRIVACY,
} from '@/features/dashboard/payments/constants'
import {
  withPaymentCheckoutContext,
  usePaymentCheckoutContext,
} from '@/features/dashboard/payments/contexts/paymentCheckoutContext'
import { withPaymentLayout } from '@/features/dashboard/payments/contexts/paymentProvider'
import { useCheckoutServices } from '@/features/dashboard/payments/services'

type Errors = Partial<{
  selectedPayment: string
}>

const PaymentCheckoutPage = () => {
  const [errors, setErrors] = useState<Errors>({})
  const toast = useToast()
  const stripe = useStripe()

  useEffect(() => {
    if (errors.selectedPayment) {
      toast({
        ...ERROR_TOAST_OPTIONS,
        description: errors.selectedPayment,
      })
    }
  }, [errors])

  const { paymentMethod, packageData, transaction } =
    usePaymentCheckoutContext()

  async function handleSubscribe(res: AxiosResponse): Promise<boolean> {
    if (!stripe) return false

    const subscription = res.data

    if (
      (subscription && subscription.status === 'active') ||
      subscription.status === 'not_started'
    ) {
      return true
    }

    const setupIntent = subscription.latest_invoice.payment_intent
    const paymentMethodId =
      subscription.latest_invoice.payment_intent.payment_method.id

    if (setupIntent.status === 'requires_action') {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        setupIntent.client_secret,
        {
          payment_method: paymentMethodId,
        },
      )

      if (error) {
        toast({
          ...ERROR_TOAST_OPTIONS,
          description: error.message,
        })

        return false
      }

      if (paymentIntent) {
        if (paymentIntent.status === 'succeeded') {
          return true
        }
      }
    }

    return false
  }

  const checkoutServices = useCheckoutServices(packageData, handleSubscribe)

  return (
    <Box backgroundColor="#fff" p="6" borderRadius="8px">
      <BreadCrumb labels={['สรุปรายการสั่งซื้อ', 'เลือกวิธีการชำระเงิน']} />
      <Grid gap="6" gridTemplateColumns="1fr 440px" alignItems="start">
        <SelectCreditCard />
        <Summary
          {...packageData}
          isLoading={checkoutServices.isRequesting}
          contact={CONTACT}
          address={transaction.address.details}
          onSubmit={() =>
            checkoutServices.subscribe({
              data: {
                credit_card_id: paymentMethod.payload,
                token: transaction.token,
              },
            })
          }
          linkTermOfUse={LINK_TERM_OF_USE}
          linkPrivacy={LINK_PRIVACY}
          onErrors={(err) => {
            setErrors(err)
          }}
          validate={() => {
            const errors: Errors = {}

            if (paymentMethod.type === 'credit' && !paymentMethod.payload) {
              errors.selectedPayment = 'จำเป็นต้องเลือกรูปแบบการจ่ายเงิน'
            }

            return errors
          }}
          showWHTException={transaction.address.taxpayer_type === 'company'}
        />
      </Grid>
    </Box>
  )
}

export default withPaymentLayout(
  withPaymentCheckoutContext(PaymentCheckoutPage),
)

import { Fragment } from 'react'
import { Text, Flex, Icon } from '@datawowio/payment-ui'
import { ChevronRightIcon } from '@chakra-ui/icons'

interface Props {
  labels: string[]
}

const BreadCrumb = ({ labels }: Props) => {
  return (
    <Flex mb="12" alignItems="center">
      {labels.map((text, index) => {
        return (
          <Fragment key={text}>
            <Text
              sx={{
                fontSize: '2xl',
                ...(index >= labels.length - 1
                  ? {
                      fontWeight: 'bold',
                    }
                  : {}),
              }}
            >
              {text}
            </Text>
            {index < labels.length - 1 && (
              <Icon
                as={ChevronRightIcon}
                name="chevron-right"
                mr="2"
                ml="2"
                boxSize="30px"
              />
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default BreadCrumb

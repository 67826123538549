import { get } from 'lodash'

function Nav(props) {
  const { links, signOutUrl } = props

  return (
    <div className="mb-Nav">
      {links.map((link) => {
        const subMenus = get(link, 'subMenus')

        return (
          <div key={link.title} className="mb-Nav__item">
            <a href={get(link, 'href', '')} className="mb-Nav__itemLink">
              <div>{link.title}</div>
            </a>
            {subMenus && (
              <div>
                {subMenus.map((subMenu) => (
                  <div
                    key={subMenu.title}
                    className="mb-Nav__item mb-Nav__item--sub"
                  >
                    <a
                      href={get(subMenu, 'href', '')}
                      className="mb-Nav__subItemLink"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#ccc"
                          fillRule="evenodd"
                          d="M3.529 4.195c.26-.26.682-.26.942 0L7.805 7.53c.26.26.26.682 0 .942L4.47 11.805c-.26.26-.682.26-.942 0-.26-.26-.26-.683 0-.943L6.39 8 3.529 5.138c-.26-.26-.26-.682 0-.943zm4.666 0c.26-.26.683-.26.943 0l3.333 3.334c.26.26.26.682 0 .942l-3.333 3.334c-.26.26-.682.26-.943 0-.26-.26-.26-.683 0-.943L11.057 8 8.195 5.138c-.26-.26-.26-.682 0-.943z"
                        />
                      </svg>
                      <span>{subMenu.title}</span>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      })}

      <div className="mb-Nav__item mb-Nav__item--signout">
        <a href={signOutUrl} className="mb-Nav__itemLink">
          <div>ออกจากระบบ</div>
        </a>
      </div>
    </div>
  )
}

export default Nav

import {
  Box,
  Button,
  Collapse,
  Flex,
  FormHelperText,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { FormControl } from '@/components/Form'
import { useUpdatePasswordExpiration } from '../mutations'

import { PasswordExpirationFormInput } from '../types'
import { Company } from '../../company/types'
import { useState } from 'react'

type PasswordExpirationSectionProps = {
  expirePasswordAfterDays: Company['expire_password_after_days']
}

export const PasswordExpirationSection = (
  props: PasswordExpirationSectionProps,
) => {
  const { expirePasswordAfterDays } = props

  const [checked, setChecked] = useState(Boolean(expirePasswordAfterDays))

  const { mutate: updatePasswordExpiration, isLoading } =
    useUpdatePasswordExpiration()

  return (
    <Box>
      <Text fontWeight="bold">นโยบายเปลี่ยนรหัส</Text>

      <Flex
        sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 4 }}
      >
        <Text sx={{ fontWeight: 'semibold', fontSize: 'md' }}>
          เปิดการใช้งานการตั้งอายุของรหัสผ่าน
        </Text>

        <Switch
          isChecked={checked}
          colorScheme="switch"
          size="lg"
          onChange={() => {
            if (checked) {
              updatePasswordExpiration({
                data: {
                  expire_password_after_days: null,
                },
              })
            } else {
              setChecked(true)
            }
          }}
        />
      </Flex>

      <Collapse in={checked}>
        <Box sx={{ mt: 4 }}>
          <PasswordExpirationForm
            onSubmitForm={(data) => updatePasswordExpiration({ data })}
            isLoading={isLoading}
            expirePasswordAfterDays={expirePasswordAfterDays}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

type PasswordExpirationFormProps = {
  isLoading: boolean
  onSubmitForm: (data: PasswordExpirationFormInput) => void
  expirePasswordAfterDays: Company['expire_password_after_days']
}

const PasswordExpirationForm = (props: PasswordExpirationFormProps) => {
  const { isLoading, onSubmitForm, expirePasswordAfterDays } = props

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<PasswordExpirationFormInput>({
    defaultValues: {
      expire_password_after_days: expirePasswordAfterDays,
    },
    resolver: zodResolver(
      z.object({
        expire_password_after_days: z
          .string()
          .refine(
            (val) => {
              const _val = Number(val)

              return _val > 0
            },
            {
              message: 'ขั้นต่ำ 1 วัน',
            },
          )
          .refine(
            (val) => {
              const _val = Number(val)

              return _val < 366
            },
            {
              message: 'สูงสุดไม่เกิน 365 วัน',
            },
          ),
      }),
    ),
  })

  return (
    <form onSubmit={handleSubmit((data) => onSubmitForm(data))}>
      <Stack spacing="6">
        <FormControl
          label="อายุการใช้งานของรหัสผ่าน"
          isRequired
          errorMsg={errors.expire_password_after_days?.message}
        >
          <InputGroup>
            <Input type="number" {...register('expire_password_after_days')} />
            <InputRightAddon>วัน</InputRightAddon>
          </InputGroup>
          <FormHelperText>
            กรุณาระบุขั้นต่ำ 1 วัน และสูงสุดไม่เกิน 365 วัน
          </FormHelperText>
        </FormControl>

        <Button
          size="lg"
          type="submit"
          isLoading={isLoading}
          isDisabled={!isDirty}
        >
          อัปเดต
        </Button>
      </Stack>
    </form>
  )
}

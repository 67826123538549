import { useMemo, useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import { IconType } from 'react-icons'
import { IoAlert } from 'react-icons/io5'
import { Column } from 'react-table'
import {
  Button,
  Circle,
  Collapse,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
  chakra,
} from '@chakra-ui/react'

import { DataTable } from '@/components/atoms/Table'

import { WebhookLog } from '../../types'
import { formatDataTime } from '@/lib/helpers'
import { eventTypeColor } from '../constants'

type WebhookLogTableProps = {
  data: WebhookLog[]
}

export const WebhookLogTable = (props: WebhookLogTableProps) => {
  const { data } = props

  const [currentId, setCurrentId] = useState('')
  const memoizedData = useMemo(() => data, [data])

  const columns = useMemo<Array<Column<WebhookLog>>>(() => {
    return [
      {
        Header: 'Created at',
        accessor: 'created_at',
        width: '12%',
        Cell({ value }) {
          return formatDataTime(value)
        },
      },
      {
        Header: 'Event Type',
        accessor: 'event_type',
        width: '63%',
        Cell({ value }) {
          const eventType = eventTypeColor[value]

          return (
            <Tag
              sx={{
                bg: eventType.color,
                borderRadius: 'full',
                color: 'white',
                fontWeight: 'medium',
                h: '28px',
                px: '10px',
              }}
            >
              {eventType.label}
            </Tag>
          )
        },
      },
      {
        Header: 'Status Code',
        accessor: 'status_code',
        width: '12.5%',
        Cell({ value }) {
          let icon: IconType
          let color: string

          const firstNumber = String(value)[0]

          switch (firstNumber) {
            case '2': {
              icon = FiCheck
              color = '#00DEA9'
              break
            }
            case '4':
            case '5': {
              icon = FiX
              color = 'red.500'
              break
            }
            default: {
              icon = IoAlert
              color = '#ffd60a'
            }
          }

          return (
            <HStack spacing="1.5">
              <Circle sx={{ boxSize: '14px', bg: color }}>
                <Icon as={icon} sx={{ fontSize: '12px', color: 'white' }} />
              </Circle>
              <Text sx={{ lineHeight: 1 }}>{value}</Text>
            </HStack>
          )
        },
      },
      {
        Header: 'Action',
        accessor: 'id',
        width: '12.5%',
        Cell({ value }) {
          const isOpen = currentId === value

          return (
            <Button
              variant="link"
              onClick={() => {
                if (isOpen) {
                  setCurrentId('')
                } else {
                  setCurrentId(value)
                }
              }}
            >
              {isOpen ? 'ซ่อนผลลัพท์' : 'ดูผลลัพท์'}
            </Button>
          )
        },
      },
    ]
  }, [currentId])

  return (
    <DataTable
      data={memoizedData}
      columns={columns}
      renderSubRow={(log) => {
        const isOpen = currentId === log.id

        return (
          <Collapse in={isOpen}>
            <Stack
              spacing="1"
              sx={{
                '> *': {
                  padding: '4px 12px',
                  bg: 'gray.100',
                  borderRadius: 'md',
                },
              }}
            >
              <Text>{log.url}</Text>

              <chakra.pre>{JSON.stringify(log.response, null, 2)}</chakra.pre>
            </Stack>
          </Collapse>
        )
      }}
    />
  )
}

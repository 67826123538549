import { UseToastOptions } from '@chakra-ui/react'

export const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  status: 'success',
  isClosable: true,
  position: 'top-right',
  title: 'สำเร็จ',
}

export const ERROR_TOAST_OPTIONS: UseToastOptions = {
  ...DEFAULT_TOAST_OPTIONS,
  status: 'error',
  title: 'เกิดข้อผิดพลาด',
}

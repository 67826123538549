import { Dispatch, SetStateAction, useMemo } from 'react'
import { Column } from 'react-table'
import { Link } from '@chakra-ui/react'

import { SelectableTable } from '@/components/atoms/Table'
import { MigrationForm } from '../../types'

type FormMigrationTableProps = {
  data: MigrationForm[]
  handleSelectRow: Dispatch<SetStateAction<any>>
  limit: number
}

export const FormMigrationTable = (props: FormMigrationTableProps) => {
  const { handleSelectRow, data, limit } = props

  const memoizedData = useMemo(() => data, [data])
  const columns = useMemo<Array<Column<MigrationForm>>>(
    () => [
      {
        Header: 'ชื่อฟอร์ม',
        accessor: 'name',
      },
      {
        Header: 'URL Form',
        accessor: 'form_url',
        Cell: (cellProps) => {
          const value = cellProps.value

          return (
            <Link isExternal href={value} color="brand.400">
              {value}
            </Link>
          )
        },
      },
    ],
    [],
  )

  return (
    <SelectableTable
      selectColumnWidth="10px"
      data={memoizedData}
      columns={columns}
      selectProps={{
        limit,
        handleSelectRow,
      }}
    />
  )
}

import {
  Button,
  Collapse,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { UnsubReasonFormInput } from '../../types'

type UnsubReasonFormProps = {
  isLoading: boolean
  onSubmit: (data: UnsubReasonFormInput) => void
}

export const UnsubReasonForm = (props: UnsubReasonFormProps) => {
  const { isLoading, onSubmit } = props

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<UnsubReasonFormInput>()

  const reason = watch('reason')
  const isShowInput = reason === OPTIONS[4]

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (isSubmitting) return

        onSubmit(data)
      })}
    >
      <Grid gap={6}>
        <RadioGroup
          colorScheme="brand"
          size="lg"
          onChange={(value) => {
            setValue('reason', value)
          }}
        >
          <Stack>
            {OPTIONS.map((opt) => (
              <Radio key={opt} value={opt}>
                <Text fontSize="16px">{opt}</Text>
              </Radio>
            ))}

            <Collapse in={isShowInput}>
              <Input
                {...register('reason_input')}
                isRequired={isShowInput}
                placeholder="โปรดระบุเหตุผลของคุณ"
              />
            </Collapse>
          </Stack>
        </RadioGroup>

        <Button size="lg" type="submit" isLoading={isLoading}>
          ยกเลิกการต่ออายุ
        </Button>
      </Grid>
    </form>
  )
}

const OPTIONS = [
  'ฉันพบผู้ให้บริการ/ผลิตภัณฑ์ที่ดีกว่า',
  'ฉันพบผู้ให้บริการ/ผลิตภัณฑ์ที่ราคาถูกกว่า',
  'ฉันพบประสบการณ์ที่ไม่ดีในการใช้บริการ',
  'ฉันต้องการลดค่าใช้จ่าย',
  'อื่นๆ (โปรดระบุ)',
]

import { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps as ChakraModalProps,
  StyleProps,
} from '@chakra-ui/react'

interface ModalProps extends ChakraModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  contentSX?: StyleProps
  withCloseButton?: boolean
}

function Modal(props: ModalProps) {
  const { children, withCloseButton, contentSX, ...rest } = props

  return (
    <ChakraModal closeOnOverlayClick motionPreset="slideInBottom" {...rest}>
      <ModalOverlay>
        <ModalContent pb="4" sx={contentSX}>
          {children}
          {withCloseButton && <ModalCloseButton />}
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  )
}

export default Modal

import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { axios } from '@/lib/helpers'
import { useErrorHandler } from '@/lib/hooks'

import { RequestFormInput } from '../../types'

type Payload = {
  data: RequestFormInput
  params: {
    token: string
  }
}

export const useUpdateRequestForm = () => {
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    async ({ data, params }: Payload) => {
      const { token } = params

      const formData = new FormData()

      Object.entries(data).forEach(([k, v]) => {
        if (v) {
          formData.append(k, v)
        }
      })

      return await axios.patch(`/forms/${token}`, formData)
    },
    {
      onSuccess() {
        window.location.href = '/forms'
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}

import {
  SelectAddress as _SelectAddress,
  SelectAddressItem,
  AddressForm,
} from '@datawowio/payment-ui'

import { convertAddress } from '@/features/dashboard/payments/helpers/utils'
import { usePaymentSummaryContext } from '@/features/dashboard/payments/contexts/paymentSummaryContext'
import { useAddressServices } from '@/features/dashboard/payments/services'
import { logEvent } from '@/lib/stats'

import { TaxpayerType, Address } from '@/features/dashboard/payments/types'

const SelectAddress = () => {
  const {
    addressType,
    setAddressType,
    selectedAddress,
    setSelectedAddress,
    addresses,
  } = usePaymentSummaryContext()
  const addressServices = useAddressServices()

  return (
    <_SelectAddress
      onChangeType={(type) => setAddressType(type as TaxpayerType)}
      addressType={addressType}
      renderAddressForm={({ onClose }) => {
        return (
          <AddressForm
            onSubmit={(values) =>
              addressServices
                .create({
                  data: convertAddress({
                    ...(values as Address),
                    taxPayerType: addressType,
                  }),
                })
                .then(() => {
                  logEvent({
                    ga: {
                      category: 'Summary Packages',
                      action: 'Add address success',
                    },
                  })
                  onClose()
                })
            }
            addressType={addressType}
          />
        )
      }}
    >
      {addresses.map((addressData) => {
        const { id, name, address, primary, taxId, taxPayerType } = addressData

        return (
          <SelectAddressItem
            key={id}
            id={parseInt(id)}
            name={name}
            address={address}
            taxId={taxId}
            addressType={taxPayerType}
            primary={Boolean(primary)}
            isSelected={selectedAddress === id}
            onDelete={() => {
              addressServices.remove({
                params: { id },
              })
            }}
            onEdited={(values) => {
              addressServices.update({
                data: convertAddress(values as Address),
                params: { id },
              })
            }}
            onClickSetPrimary={() => {
              addressServices.update({
                data: { primary: true },
                params: { id },
              })
            }}
            onClick={() => {
              setSelectedAddress(id)
            }}
          />
        )
      })}
    </_SelectAddress>
  )
}

export default SelectAddress

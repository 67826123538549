export const PAYMENT_PROJECT = {
  title: 'PDPA Form',
  productType: 'ฟอร์ม',
}

export const CONTACT = {
  email: 'support@ndatathoth.com',
  tel: '02-024-5560',
  operatingHour: 'จันทร์-ศุกร์ เวลา 08.00 - 17.00',
  facebook: 'https://www.facebook.com/pdpapro',
}

export const LINK_TERM_OF_USE = 'https://pdpaform.com/terms'
export const LINK_PRIVACY = 'https://pdpaform.com/policies'

import { Box } from '@chakra-ui/react'

function IconIncorrentCircle() {
  return (
    <Box
      boxSize="6"
      sx={{
        background:
          'no-repeat center/contain url("assets/incorrect-circle-icon.svg")',
      }}
    />
  )
}

export default IconIncorrentCircle

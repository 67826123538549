import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/helpers/api'
import { useErrorHandler } from '@/lib/hooks'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants'
import { WEBHOOK } from '../constants'

export const useRenewKey = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, void>(
    function () {
      return fetchAPI({
        path: '/webhooks/renew_key',
        method: 'post',
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([WEBHOOK])

        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'Renew Signing Secret สำเร็จ',
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}

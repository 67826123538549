import { useRef } from 'react'

function SearchBox(props) {
  const { requestPath, currentStatus } = props
  const $inputRef = useRef()

  const onSubmit = (e) => {
    e.preventDefault()

    const caseId = $inputRef.current.value
    window.location.href = `${requestPath}?status=${currentStatus}&ref_id=${caseId}`
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-filterDropdown__search">
        <input
          ref={$inputRef}
          type="text"
          className="Input Input--search"
          autoFocus
          placeholder="ค้นหาด้วยเลขเคส"
        />
        <button className="Button">ค้นหา</button>
      </div>
    </form>
  )
}

export default SearchBox

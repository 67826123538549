import { Box, CardItem } from '@datawowio/payment-ui'
import { isEmpty } from 'lodash'

import { Card } from '@/features/dashboard/payments/types'

import { usePaymentCheckoutContext } from '@/features/dashboard/payments/contexts/paymentCheckoutContext'
import { useCreditCardServices } from '@/features/dashboard/payments/services'

interface Props {
  cards: Card[]
}

const EmptyText = () => {
  return (
    <Box p="4" textAlign="center" color="gray.400">
      ไม่มีบัตรเครดิต
    </Box>
  )
}

const CreditCardList = ({ cards }: Props) => {
  const { paymentMethod, setPaymentMethod } = usePaymentCheckoutContext()
  const creditCardServices = useCreditCardServices()

  if (isEmpty(cards)) {
    return <EmptyText />
  }

  return (
    <Box>
      {cards.map((card) => {
        const { id, primary, number, brand } = card
        return (
          <CardItem
            id={id}
            key={id}
            isSelected={paymentMethod.payload === id}
            primary={Boolean(primary)}
            cardType={brand}
            shownNumber={number}
            onClick={() => {
              setPaymentMethod({ type: 'credit', payload: id })
            }}
            onDelete={() => creditCardServices.remove({ params: { id } })}
            onEdit={(value) => {
              if (value.isPrimary) {
                creditCardServices.update({
                  data: { default_card: value.isPrimary },
                  params: { id },
                })
              }
            }}
          />
        )
      })}
    </Box>
  )
}

export default CreditCardList

import { useState, useEffect, FC, ReactNode } from 'react'

import { createCtx } from '@/lib/helpers'

import { useTransformPackageData } from '@/features/dashboard/payments/helpers/hooks'
import {
  getAddressType,
  getDefaultAddress,
  transformAddresses,
  filterAddresses,
} from '@/features/dashboard/payments/helpers/utils'
import { useAddressServices } from '@/features/dashboard/payments/services'

import {
  RecurringType,
  Address,
  RawAddress,
  TaxpayerType,
  Package,
  RawPackage,
} from '@/features/dashboard/payments/types'

interface Context {
  hostUrl: string
  addressType: TaxpayerType
  setAddressType: (type: TaxpayerType) => void
  selectedAddress: string
  setSelectedAddress: (address: string) => void
  recurringType: RecurringType
  setRecurringType: (type: RecurringType) => void
  addresses: Address[]
  packageData: Package
}

interface Props {
  hostUrl: string
  currentPackage: RawPackage
  addresses: RawAddress[]
  children: ReactNode
  email: string
}

const [
  usePaymentSummaryContext,
  PaymentSummaryProvider,
  PaymentSummaryContext,
] = createCtx<Context>()

const withPaymentSummaryContext = (Component: FC<any>) => (props: Props) => {
  const { currentPackage, addresses, hostUrl, children } = props

  const { addresses: transformedAddresses } = useAddressServices(() =>
    transformAddresses(addresses),
  )

  const [selectedAddress, setSelectedAddress] = useState('')
  const [recurringType, setRecurringType] = useState<RecurringType>('month')

  const [addressType, setAddressType] = useState(() =>
    getAddressType(transformedAddresses),
  )

  const packageData = useTransformPackageData(
    currentPackage,
    recurringType,
    addressType,
  )

  const filteredAddresses = filterAddresses(transformedAddresses, addressType)

  useEffect(() => {
    setRecurringType('month')
  }, [])

  useEffect(() => {
    if (selectedAddress) {
      const selectedAddressObject = filteredAddresses.find(
        (address) => address.id === selectedAddress,
      )

      if (!selectedAddressObject) {
        setSelectedAddress(getDefaultAddress(filteredAddresses))
      }
    } else {
      setSelectedAddress(getDefaultAddress(filteredAddresses))
    }
  }, [transformedAddresses])

  useEffect(() => {
    setSelectedAddress(getDefaultAddress(filteredAddresses))
  }, [addressType])

  const contextValue = {
    hostUrl,
    addressType,
    setAddressType,
    selectedAddress,
    setSelectedAddress,
    recurringType,
    setRecurringType,
    addresses: filteredAddresses,
    packageData,
  }

  return (
    <PaymentSummaryProvider value={contextValue}>
      <Component {...props}>{children}</Component>
    </PaymentSummaryProvider>
  )
}

export {
  usePaymentSummaryContext,
  withPaymentSummaryContext,
  PaymentSummaryContext,
}

import { useState, Fragment } from 'react'
import { get, map, isEmpty, flatten, entries, isUndefined, each } from 'lodash'

import { axios } from '@/lib/helpers'

import Modal from '@/components/molecules/Modal'

const DashboardSettingsShow = ({ company }) => {
  const [errors, errorsSet] = useState()
  const [deleteErrors, deleteErrorsSet] = useState()
  const [uploadFile, uploadFileSet] = useState()
  const [modalData, modalDataSet] = useState({ isActive: false })
  const [isLoading, isLoadingSet] = useState(false)

  return (
    <Fragment>
      <div className="DashboardSettingsShow">
        <div className="container">
          <div className="Header">
            <h2 className="Header__h2">
              <span className="Header__breadcrumb__a">
                การตั้งค่าบัญชีบริษัท
              </span>
            </h2>
          </div>

          <div className="DashboardSettingsShow__body">
            <div className="DashboardSettingsShow__body__top">
              <form
                className="DashboardSettingsShow__body__top__form"
                onSubmit={(e) => {
                  e.preventDefault()

                  const $els = get(e, 'target.elements')
                  const formData = new FormData()

                  const inputs = map($els, (x) => {
                    return {
                      name: get(x, 'name'),
                      value:
                        get(x, 'name') == 'logo' ? uploadFile : get(x, 'value'),
                    }
                  }).filter(
                    (x) =>
                      !isEmpty(get(x, 'name')) && !isUndefined(get(x, 'value')),
                  )

                  inputs.forEach((x) => {
                    formData.append(get(x, 'name'), get(x, 'value'))
                  })

                  isLoadingSet(true)

                  axios
                    .patch('/settings', formData)
                    .then(() => {
                      window.location.reload()
                    })
                    .catch((e) => {
                      isLoadingSet(false)
                      errorsSet(get(e, 'response.data.errors'))
                    })
                }}
              >
                <label className="DashboardSettingsShow__body__top__form__image">
                  {uploadFile || get(company, 'logo.url') ? (
                    <img
                      className="DashboardSettingsShow__body__top__form__image__img"
                      src={
                        uploadFile
                          ? window.URL.createObjectURL(uploadFile)
                          : get(company, 'logo.url')
                      }
                    />
                  ) : (
                    <div className="DashboardSettingsShow__body__top__form__image__img"></div>
                  )}

                  <input
                    type="file"
                    name="logo"
                    onChange={(e) => {
                      uploadFileSet(get(e, 'target.files.0'))
                    }}
                  />

                  <a className="Button Button--trans-blue">อัปโหลดโลโก้</a>
                </label>

                <label className="FormsNew__body__form__label">
                  <span className="FormsNew__body__form__label__span">
                    ชื่อบริษัท
                  </span>

                  <input
                    type="text"
                    className="Input"
                    placeholder=""
                    name="name"
                    defaultValue={get(company, 'name')}
                  />
                </label>

                {!isEmpty(errors) && (
                  <Fragment>
                    <ul className="FormsNew__body__form__errors">
                      {flatten(entries(errors).map((x) => get(x, '1'))).map(
                        (x, i) => (
                          <li
                            key={i}
                            className="FormsNew__body__form__errors__li"
                          >
                            {x}
                          </li>
                        ),
                      )}
                    </ul>
                  </Fragment>
                )}

                <button
                  type="submit"
                  className="Button Button--large"
                  disabled={isLoading}
                >
                  บันทึก
                </button>
              </form>
            </div>

            <div className="DashboardSettingsShow__body__bottom">
              <div className="DashboardSettingsShow__body__bottom__left">
                <h3 className="DashboardSettingsShow__body__bottom__h3">
                  ลบบัญชีบริษัทนี้
                </h3>

                <p className="DashboardSettingsShow__body__bottom__p">
                  การลบข้อมูลบัญชีบริษัทของคุณ
                  รวมถึงการลบข้อมูลที่ถูกแนบมาทั้งหมดของผู้ใช้ ข้อมูลต่างๆ
                  ที่อยู่ในระบบนี้จะหายถาวรทั้งหมด
                </p>
              </div>

              <a
                className="Button Button--red"
                onClick={() => {
                  modalDataSet({
                    ...modalData,
                    isActive: true,
                  })
                }}
              >
                ลบบัญชี
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal modalData={modalData} modalDataSet={modalDataSet}>
        <form
          className="Modal__form"
          onSubmit={(e) => {
            e.preventDefault()

            const $els = get(e, 'target.elements')
            const formData = new FormData()

            each($els, ($el) => {
              const name = get($el, 'name')
              const value = get($el, 'value')

              if (!isEmpty(name)) {
                formData.append(name, value)
              }
            })

            axios
              .delete('/settings', formData)
              .then(() => {
                window.location.reload()
              })
              .catch((e) => {
                deleteErrorsSet(get(e, 'response.data.errors'))
              })
          }}
        >
          <h2 className="Modal__h2">ลบบัญชีบริษัท</h2>

          <p className="Modal__p">
            การลบข้อมูลบัญชีบริษัทของคุณ
            รวมถึงการลบข้อมูลที่ถูกแนบมาทั้งหมดของผู้ใช้ ข้อมูลต่างๆ
            ที่อยู่ในระบบนี้จะหายถาวรทั้งหมด
          </p>

          <input
            type="password"
            className="Input"
            name="password"
            placeholder="กรอกรหัสผ่าน เพื่อยืนยันการลบครั้งนี้"
          />

          {!isEmpty(deleteErrors) && (
            <Fragment>
              <ul className="FormsNew__body__form__errors">
                {flatten(entries(deleteErrors).map((x) => get(x, '1'))).map(
                  (x, i) => (
                    <li key={i} className="FormsNew__body__form__errors__li">
                      {x}
                    </li>
                  ),
                )}
              </ul>
            </Fragment>
          )}

          <button className="Button Button--red" type="submit">
            ยืนยันการลบ
          </button>
          <button
            className="Button Button--trans"
            onClick={() => {
              modalDataSet({
                ...modalData,
                isActive: false,
              })
            }}
          >
            ยกเลิก
          </button>
        </form>
      </Modal>
    </Fragment>
  )
}

DashboardSettingsShow.propTypes = {}

export default DashboardSettingsShow

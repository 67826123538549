type GAEventParams = {
  category: string
  action: string
  label?: string
  value?: string
}

type FBEventParams = {
  event: string
  value?: string
  currency?: string
  predicted_ltv?: string
}

type LogEventParams = {
  ga?: GAEventParams
  fb?: FBEventParams
}

type LogPageviewParams = {
  customPage: string
}

type UserDataParams = {
  user_id: string
}

export type GaEcommerce = null | {
  currency?: string
  value?: number
  coupon?: string
  payment_type?: string
  items?: {
    item_id?: string
    item_name?: string
    affiliation?: string
    coupon?: string
    currency?: string
    discount?: number
    index?: number
    item_brand?: string
    item_category?: string
    item_category2?: string
    item_category3?: string
    item_category4?: string
    item_category5?: string
    item_list_id?: string
    item_list_name?: string
    item_variant?: string
    location_id?: string
    price?: number
    quantity?: number
  }[]
}

type EcommerceParams = {
  ecommerce_type: string
  ecommerce: null | GaEcommerce
}

type GTMParams = {
  event: string
} & (
  | Partial<LogEventParams>
  | Partial<LogPageviewParams>
  | Partial<UserDataParams>
  | Partial<EcommerceParams>
)

function isDataInvalid(data: GTMParams) {
  return typeof data.event !== 'string'
}

function gtmPush(data: GTMParams) {
  const dataLayer = window.dataLayer

  if (typeof dataLayer !== 'undefined' && !isDataInvalid(data)) {
    dataLayer.push(data)
  }
}

export function logEvent({ ga, fb }: LogEventParams) {
  gtmPush({
    event: 'trackEvent',
    ga,
    fb,
  })
}

export function logUser(id?: string): void {
  gtmPush({
    event: 'userData',
    user_id: id ?? '',
  })
}

export function logEcommerce(
  name: string,
  ecommerce: EcommerceParams['ecommerce'],
): void {
  gtmPush({
    event: 'Ecommerce',
    ecommerce_type: name,
    ecommerce,
  })
}

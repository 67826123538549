type Props = {
  acceptedRoles?: Role[]
  extraConditions?: boolean
  children: any
}

export const MatchingRole = (props: Props) => {
  const { acceptedRoles, children, extraConditions = true } = props

  if (
    !acceptedRoles ||
    acceptedRoles.length === 0 ||
    (acceptedRoles.includes(gon.current_admin_role) && extraConditions)
  )
    return children()

  return null
}

import { z } from 'zod'

import { passwordValidator } from '@/features/auth/components/PasswordInput/schema'

const passwordAndConfirm = {
  password: z
    .string()
    .nonempty('กรุณากรอกรหัสผ่าน')
    .refine(passwordValidator, 'รหัสผ่านไม่ตรงตามเงื่อนไข'),
  password_confirmation: z.string().nonempty('กรุณากรอกยืนยันรหัสผ่าน'),
}

export const updatePasswordFormSchema = z
  .object({
    current_password: z.string().nonempty('กรุณากรอกรหัสผ่านปัจจุบัน'),
  })
  .extend(passwordAndConfirm)
  .refine((data) => data.password === data.password_confirmation, {
    message: 'รหัสผ่านไม่ตรงกัน',
    path: ['password_confirmation'],
  })

import { Button, Input, Stack } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { FormControl } from '@/components/Form'
import { PasswordInput } from '@/features/auth/components'

import { UpdatePasswordFormInput } from '../types'
import { updatePasswordFormSchema } from '../schema'
import { useUpdatePassword } from '../services'

export const UpdatePasswordForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<UpdatePasswordFormInput>({
    resolver: zodResolver(updatePasswordFormSchema),
  })

  const { mutate: updatePassword, isLoading } = useUpdatePassword()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        updatePassword(
          { data },
          {
            onSuccess: () => reset(),
          },
        )
      })}
    >
      <Stack spacing="6">
        <Stack spacing="4">
          <FormControl
            isRequired
            label="รหัสปัจจุบันที่ใช้งาน"
            errorMsg={errors.current_password?.message}
          >
            <Input
              {...register('current_password')}
              size="lg"
              placeholder="รหัสปัจจุบันที่ใช้งาน"
              type="password"
            />
          </FormControl>

          <Controller
            control={control}
            name="password"
            render={({ field: { name, onChange, value } }) => {
              return (
                <FormControl
                  errorMsg={errors.password?.message}
                  label="รหัสผ่านใหม่"
                  isRequired
                >
                  <PasswordInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    size="lg"
                    placeholder="รหัสผ่านใหม่"
                    autoComplete="new-password"
                  />
                </FormControl>
              )
            }}
          />

          <FormControl
            isRequired
            label="ยืนยันรหัสผ่านใหม่"
            errorMsg={errors.password_confirmation?.message}
          >
            <Input
              {...register('password_confirmation')}
              size="lg"
              placeholder="ยืนยันรหัสผ่านใหม่"
              type="password"
              autoComplete="new-password"
            />
          </FormControl>
        </Stack>

        <Button size="xl" type="submit" isLoading={isLoading}>
          บันทึก
        </Button>
      </Stack>
    </form>
  )
}

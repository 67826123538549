import { isEmpty } from 'lodash'
import { Fragment, useMemo } from 'react'
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs'
import {
  Box,
  Center,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
} from '@chakra-ui/react'

import { TableProps } from './types'

import { tableCellStyle, tableHeadStyle } from './Table.styles'

import { Pagination } from '../Pagination'

export function Table<T extends Record<string, unknown>>(props: TableProps<T>) {
  const {
    tableInstance,
    renderActionColumn,
    renderSubRow,
    renderNoData,
    showPagination,
    tableSx,
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // The rest of these things are super handy, too ;)
    pageCount: totalPage,
    gotoPage: onChangePage,
    state: { pageIndex: currentPage },
  } = tableInstance

  const rows = tableInstance.page ? tableInstance.page : tableInstance.rows

  const tableHeadersColumn = headerGroups[0].headers || []
  const colSpan = useMemo(() => {
    if (renderActionColumn) {
      return tableHeadersColumn.length + 1
    }

    return tableHeadersColumn.length
  }, [tableHeadersColumn, renderActionColumn])

  return (
    <Flex
      sx={{
        position: 'relative',
        minH: '700px',
        flexDir: 'column',
        ...(showPagination && { minH: '700px', flexDir: 'column' }),
        bg: 'white',
        borderRadius: '8px',
      }}
    >
      {/* {isLoading && <TableLoading />} */}

      <ChakraTable
        {...getTableProps()}
        sx={{
          tableLayout: 'fixed',
          mb: '30px',
          ...tableSx,
        }}
      >
        <Thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } =
              headerGroup.getHeaderGroupProps()

            return (
              <Tr key={key} {...headerGroupProps}>
                {headerGroup.headers.map((column, _idx, _headers) => {
                  const sortByToggleProps = column.getSortByToggleProps()
                  const { key, ...headerProps } =
                    column.getHeaderProps(sortByToggleProps)
                  const { isSorted, canSort, isSortedDesc, width, isNumeric } =
                    column

                  return (
                    <Th
                      key={key}
                      {...headerProps}
                      width={width}
                      isNumeric={isNumeric}
                      sx={tableHeadStyle}
                    >
                      <Box>
                        <Box
                          sx={{
                            ...(canSort && {
                              position: 'relative',
                              pr: '24px',
                              display: 'inline-flex',
                            }),
                          }}
                        >
                          {column.render('Header')}
                          {isSorted && (
                            <Center
                              ml="2"
                              sx={{
                                position: 'absolute',
                                right: '0',
                                top: '-3px',
                              }}
                            >
                              {isSortedDesc ? (
                                <BsArrowDownShort size="22px" />
                              ) : (
                                <BsArrowUpShort size="22px" />
                              )}
                            </Center>
                          )}
                        </Box>
                      </Box>
                    </Th>
                  )
                })}

                {renderActionColumn && renderActionColumn.Cell && (
                  <Th
                    width={renderActionColumn.width || '40'}
                    sx={{
                      bg: 'gray.100',
                    }}
                  >
                    {renderActionColumn.header || ''}
                  </Th>
                )}
              </Tr>
            )
          })}
        </Thead>

        {isEmpty(rows) ? (
          <Tbody {...getTableBodyProps()}>
            <Tr>
              <Td colSpan={colSpan}>
                {renderNoData ? (
                  renderNoData()
                ) : (
                  <Box sx={{ textAlign: 'center', p: '8', color: 'gray.900' }}>
                    ไม่มีข้อมูล
                  </Box>
                )}
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()} sx={{ verticalAlign: 'initial' }}>
            {rows.map((row) => {
              prepareRow(row)

              const { key, ...rowProps } = row.getRowProps()

              return (
                <Fragment key={key}>
                  <Tr {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      const column = cell.column

                      return (
                        <Td
                          key={key}
                          {...cellProps}
                          width={column.width}
                          sx={{
                            ...tableCellStyle,
                            ...(column.isSlim && {
                              pl: '12px',
                            }),
                          }}
                        >
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}

                    {renderActionColumn && renderActionColumn.Cell && (
                      <Td>
                        <Flex justify="flex-end" pr="8px">
                          {renderActionColumn.Cell(row?.original)}
                        </Flex>
                      </Td>
                    )}
                  </Tr>

                  {renderSubRow && (
                    <Tr>
                      <Td
                        colSpan={colSpan}
                        sx={{
                          ...(!!renderSubRow && {
                            borderBottom: '1px solid #e8e8e8',
                            p: 0,
                          }),
                        }}
                      >
                        {renderSubRow(row?.original)}
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })}
          </Tbody>
        )}
      </ChakraTable>

      {!isEmpty(rows) && totalPage > 1 && (
        <Center sx={{ mb: '30px', mt: 'auto' }}>
          <Pagination
            page={currentPage + 1}
            totalPage={totalPage}
            onChangePage={(pageNum) => onChangePage(pageNum - 1)}
          />
        </Center>
      )}
    </Flex>
  )
}

import { ComponentType, ReactNode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'

import theme from './theme'

export const ThemeProvider = (props: { children: ReactNode }) => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      {props.children}
    </ChakraProvider>
  )
}

export const withTheme =
  <T,>(Component: ComponentType<T>) =>
  (props: T) => {
    return (
      <ThemeProvider {...props}>
        <Component {...props} />
      </ThemeProvider>
    )
  }

import { AuthLayout, ResetPasswordForm } from '../components'
import { Flash, ResetPasswordFormInput } from '../types'

type ResetPasswordPageProps = {
  errors: Partial<Record<keyof ResetPasswordFormInput, string[]>>
  flash: Flash
  formActionPath: string
  resetPasswordToken: string
}

function ResetPasswordPage(props: ResetPasswordPageProps) {
  const { formActionPath, resetPasswordToken, flash, errors } = props

  return (
    <AuthLayout title="เปลี่ยนรหัสผ่าน" flash={flash}>
      <ResetPasswordForm
        formActionPath={formActionPath}
        resetPasswordToken={resetPasswordToken}
        errors={errors}
      />
    </AuthLayout>
  )
}

export default ResetPasswordPage

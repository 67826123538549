import { Fragment } from 'react'

import DocsLayout from '@/features/termAndPrivacy/components/DocsLayout'
import { renderTemplate } from '@/features/termAndPrivacy/helpers'

const Policy = () => {
  return (
    <Fragment>
      <h1>Privacy policy</h1>
      <p>
        This privacy policy (&quot;Policy&quot;) describes how PDPA&nbsp;Form
        (&quot;nDataThoth Limited&quot;, &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot;) collects, protects and uses the personally identifiable
        information (&quot;Personal Information&quot;) you (&quot;User&quot;,
        &quot;you&quot; or &quot;your&quot;) may provide on the pdpaform.com
        website and any of its products or services (collectively,
        &quot;Website&quot; or &quot;Services&quot;) to be in accordance with
        Personal Data Protection Act B.E. 2562 (2019) (&quot;PDPA&quot;).
      </p>
      <p>
        It also describes the choices available to you regarding our use of your
        Personal Information and how you can access and update this information.
        This Policy does not apply to the practices of companies that we do not
        own or control, or to individuals that we do not employ or manage.
      </p>
      {renderTemplate('policy')}
      <div id="EndContent">
        This document was last updated on September 19, 2022
      </div>
    </Fragment>
  )
}

export default DocsLayout(Policy)

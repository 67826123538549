import axios from 'axios'
import { ConsentRecord } from './types'

interface GetConsentProps {
  apiKey: string
  baseUrl: string
}

interface ResponseJSON {
  data: ConsentRecord[]
}

export async function getConsents({
  apiKey,
  baseUrl,
}: GetConsentProps): Promise<ResponseJSON> {
  const res = await axios.get(`${baseUrl}/api/v1/consent_records`, {
    headers: {
      Authorization: apiKey,
    },
  })
  return res.data
}

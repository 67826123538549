import { useEffect } from 'react'
import {
  Box,
  Button,
  HStack,
  ModalCloseButton,
  Stack,
  Text,
  UseDisclosureReturn,
  Icon,
  useToast,
  useClipboard,
  ModalBody,
} from '@chakra-ui/react'
import { IoCopyOutline, IoCodeSlash } from 'react-icons/io5'

import Modal from '@/components/atoms/Modal'
import { ModalHeader } from '@/components/atoms/shared'

type Props = {
  disclosure: UseDisclosureReturn
  iframe: string
  url: string
}

export const CopyUrlModal = (props: Props) => {
  const { disclosure, iframe, url } = props
  const { isOpen, onClose } = disclosure

  const toast = useToast()

  const { hasCopied: hasCopiedUrl, onCopy: onCopyUrl } = useClipboard(url)
  const { hasCopied: hasCopiedIframe, onCopy: onCopyIframe } =
    useClipboard(iframe)

  const showCopiedToast = () => {
    toast({
      description: 'คัดลอกสำเร็จ',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    })
  }

  useEffect(() => {
    if (hasCopiedUrl) {
      showCopiedToast()
    }
  }, [hasCopiedUrl])

  useEffect(() => {
    if (hasCopiedIframe) {
      showCopiedToast()
    }
  }, [hasCopiedIframe])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      contentSX={{ maxW: '600px' }}
    >
      <ModalHeader>เผยแพร่ฟอร์ม</ModalHeader>
      <ModalCloseButton id="close_scriptModal_button" />
      <ModalBody>
        <Stack sx={{ mb: 3, fontSize: 'md' }}>
          <HStack
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Text>ลิงก์สาธารณะ (Public URL)</Text>
            <Button
              onClick={onCopyUrl}
              size="sm"
              leftIcon={<Icon as={IoCopyOutline} />}
            >
              Copy URL
            </Button>
          </HStack>

          <Box
            as="code"
            sx={{
              border: '1px solid',
              borderColor: 'gray.300',
              borderRadius: '8px',
              bg: 'gray.100',
              py: 2,
              px: 4,
              mb: 4,
              fontSize: 'sm',
              whiteSpace: 'pre',
              color: 'gray.600',
            }}
          >
            {url}
          </Box>
        </Stack>
        <Stack sx={{ mt: 3 }}>
          <HStack
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Text>ติดตั้ง iFrame (Embed iFrame)</Text>

            <Button
              onClick={onCopyIframe}
              size="sm"
              leftIcon={<Icon as={IoCodeSlash} />}
            >
              Copy Code
            </Button>
          </HStack>

          <Box
            as="code"
            sx={{
              border: '1px solid',
              borderColor: 'gray.300',
              borderRadius: '8px',
              bg: 'gray.100',
              py: 2,
              px: 4,
              mb: 4,
              fontSize: 'sm',
              whiteSpace: 'pre',
              color: 'gray.600',
            }}
          >
            {iframe}
          </Box>
        </Stack>
      </ModalBody>
    </Modal>
  )
}

import { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Table,
  Thead,
  Tr,
  Th,
  chakra,
  Tbody,
  Td,
  Checkbox,
  Box,
} from '@chakra-ui/react'
import {
  useTable,
  useSortBy,
  useRowSelect,
  usePagination,
  CellProps,
  Hooks,
} from 'react-table'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'

import { tableCellStyle, tableHeadStyle } from './Table.styles'

type SelectableTableProps<T = any> = {
  selectColumnWidth: string
  columns: any[]
  data: T[]
  initialState?: any
  selectProps: {
    customRowDisable?: (data: T) => boolean
    handleSelectRow: Dispatch<SetStateAction<T[]>>
    limit: number
  }
}

export const SelectableTable = <T extends Record<string, unknown>>(
  props: SelectableTableProps<T>,
) => {
  const {
    columns,
    data,
    initialState,
    selectColumnWidth,
    selectProps: { customRowDisable, handleSelectRow, limit },
  } = props

  const tableInstance = useTable<T>(
    { columns, data, initialState },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => [
      hooks.allColumns.push((columns) => [
        {
          id: '_selector',
          width: selectColumnWidth,
          isSelectable: true,
          Cell: ({ row }: CellProps<any>) => {
            const { indeterminate: _, ...props } =
              row.getToggleRowSelectedProps()

            const isDisabled = customRowDisable
              ? customRowDisable(row.original)
              : false

            if (
              tableInstance.rows.filter((row) => row.isSelected).length <
                limit ||
              row.isSelected
            ) {
              return (
                <Checkbox
                  size="lg"
                  {...props}
                  isChecked={props.checked}
                  isDisabled={isDisabled}
                  colorScheme="brand"
                />
              )
            } else {
              return (
                <Checkbox
                  size="lg"
                  {...props}
                  isChecked={props.checked}
                  isDisabled={true}
                  style={{ cursor: 'not-allowed' }}
                />
              )
            }
          },
        },
        ...columns,
      ]),
    ],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  useEffect(() => {
    if (handleSelectRow) {
      const selectedRows = tableInstance.selectedFlatRows.map((d) => d.original)

      handleSelectRow(selectedRows)
    }
  }, [tableInstance.state.selectedRowIds])

  return (
    <Box
      sx={{ p: '4px 12px', minH: '500px', borderRadius: '8px', bg: 'white' }}
    >
      <Table
        {...getTableProps()}
        sx={{
          tableLayout: 'fixed',
        }}
        variant="unstyled"
      >
        <Thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps()

            return (
              <Tr key={key} {...rest}>
                {headerGroup.headers.map((column) => {
                  const { key, ...rest } = column.getHeaderProps(
                    column.getSortByToggleProps(),
                  )

                  return (
                    <Th
                      key={key}
                      {...rest}
                      isNumeric={column.isNumeric}
                      width={column.width}
                      sx={{
                        ...tableHeadStyle,
                        ...(column.isSelectable && { p: 0 }),
                      }}
                    >
                      {column.render('Header')}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  )
                })}
              </Tr>
            )
          })}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            const { key, ...rest } = row.getRowProps()

            return (
              <Tr key={key} {...rest}>
                {row.cells.map((cell) => {
                  const { key, ...rest } = cell.getCellProps()

                  return (
                    <Td
                      key={key}
                      {...rest}
                      isNumeric={cell.column.isNumeric}
                      width={cell.column.width}
                      sx={tableCellStyle}
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

import { Button, Checkbox, HStack, Input, Stack } from '@chakra-ui/react'
import { useState } from 'react'

import { getAuthenticityToken } from '../helpers'

import { FormControl } from '@/components/Form'

import { AcceptInvitationFormInput, AuthFormProps } from '../types'
import { PasswordInput } from './PasswordInput'

type AcceptInvitationFormProps = AuthFormProps & {
  invitationToken: string
  defaultValues: AcceptInvitationFormInput
  errors: Partial<Record<keyof AcceptInvitationFormInput, string[]>>
}

export const AcceptInvitationForm = (props: AcceptInvitationFormProps) => {
  const { formActionPath, invitationToken, defaultValues, errors } = props

  const authenticityToken = getAuthenticityToken()
  const [password, setPassword] = useState('')

  return (
    <form
      id="edit_admin"
      action={formActionPath}
      acceptCharset="UTF-8"
      method="post"
    >
      <Stack spacing="6">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
          autoComplete="off"
        />
        <input
          readOnly
          autoComplete="off"
          type="hidden"
          value={invitationToken}
          name="admin[invitation_token]"
          id="admin_invitation_token"
        />
        <input type="hidden" name="_method" value="put" autoComplete="off" />

        <HStack>
          <FormControl isRequired errorMsg={errors.first_name?.[0]}>
            <Input
              autoFocus={!Boolean(errors.password?.[0])}
              autoComplete="first-name"
              placeholder="ชื่อจริง"
              type="text"
              name="admin[first_name]"
              id="admin_first_name"
              size="lg"
              defaultValue={defaultValues.first_name}
            />
          </FormControl>
          <FormControl isRequired errorMsg={errors.last_name?.[0]}>
            <Input
              autoComplete="last-name"
              placeholder="นามสกุล"
              type="text"
              name="admin[last_name]"
              id="admin_last_name"
              size="lg"
              defaultValue={defaultValues.last_name}
            />
          </FormControl>
        </HStack>

        <FormControl isRequired errorMsg={errors.password?.[0]}>
          <PasswordInput
            autoFocus={Boolean(errors.password?.[0])}
            autoComplete="new-password"
            placeholder="รหัสผ่าน"
            name="admin[password]"
            id="admin_password"
            size="lg"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </FormControl>
        <FormControl isRequired errorMsg={errors.password_confirmation?.[0]}>
          <Input
            autoComplete="new-password"
            placeholder="ยืนยันรหัสผ่าน"
            type="password"
            name="admin[password_confirmation]"
            id="admin_password_confirmation"
            size="lg"
          />
        </FormControl>

        <Checkbox
          value="1"
          name="admin[marketing_consent]"
          id="admin_marketing_consent"
          defaultChecked={defaultValues.marketing_consent}
        >
          ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Form
          และบริการอื่น ๆ จากเรา บริษัทในเครือ บริษัทย่อยและพันธมิตรทางธุรกิจ
          &#8203;
          <span style={{ whiteSpace: 'nowrap' }}>
            (คุณสามารถยกเลิกได้ทุกเมื่อ)
          </span>
        </Checkbox>

        <Button type="submit" size="xl">
          ตั้งรหัสผ่านของคุณ
        </Button>
      </Stack>
    </form>
  )
}

import { useState } from 'react'
import { get, map, isEmpty } from 'lodash'

import { axios } from '@/lib/helpers'

interface Props {
  contact_path: string
}

const HomeIndexForm = ({ contact_path }: Props) => {
  const [isLoading, isLoadingSet] = useState(false)
  const [isSent, isSentSet] = useState(false)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()

        const $els = get(e, 'target.elements')
        const formData = new FormData()

        map($els, (x) => {
          return {
            name: get(x, 'name'),
            value: get(x, 'value'),
          }
        })
          .filter((x) => !isEmpty(get(x, 'name')))
          .forEach((x) => {
            formData.append(get(x, 'name'), get(x, 'value'))
          })

        isLoadingSet(true)

        axios.post(contact_path, formData).then(() => {
          isSentSet(true)
        })
      }}
    >
      <input
        type="email"
        name="email"
        placeholder="อีเมล"
        className="Contact__Container__right__input"
        required
      />

      <textarea
        name="message"
        placeholder="ข้อความ..."
        className="Contact__Container__right__textarea"
        rows={5}
        required
      />

      <button
        className="Contact__Container__right__button"
        disabled={isLoading}
      >
        {isSent ? 'ส่งข้อความเรียบร้อย' : 'ส่งข้อความ'}
      </button>
    </form>
  )
}

HomeIndexForm.propTypes = {}

export default HomeIndexForm

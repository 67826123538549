import dayjs, { Dayjs } from 'dayjs'
import * as d3 from 'd3-format'

export function getLocaleDateString(date: Date = new Date(), locale = 'th-TH') {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return date.toLocaleDateString(locale, options)
}

export function formatDataTime(
  date?: Dayjs | string,
  pattern = 'YYYY-MM-DD HH:mm',
) {
  if (!date) return null

  return dayjs(date).format(pattern)
}

export const formatThousand = d3.format(',d')

function IconChevronDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M8 9.057L4.471 5.53c-.26-.26-.682-.26-.942 0-.26.26-.26.682 0 .942l4 4c.26.26.682.26.942 0l4-4c.26-.26.26-.682 0-.942-.26-.26-.682-.26-.942 0L8 9.057z"
      />
    </svg>
  )
}

export default IconChevronDown

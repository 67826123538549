import { useState, Fragment } from 'react'
import { get, map, isEmpty, flatten, entries, isUndefined } from 'lodash'

import { axios } from '@/lib/helpers'

const DashboardRequestsShowForm = ({ path }) => {
  const [errors, errorsSet] = useState()

  return (
    <form
      className="RequestsShow__bottom__right__comment__form"
      onSubmit={(e) => {
        e.preventDefault()

        const $els = get(e, 'target.elements')
        const formData = new FormData()

        const inputs = map($els, (x) => {
          return {
            name: get(x, 'name'),
            value: get(x, 'value'),
          }
        }).filter(
          (x) => !isEmpty(get(x, 'name')) && !isUndefined(get(x, 'value')),
        )

        inputs.forEach((x) => {
          formData.append(get(x, 'name'), get(x, 'value'))
        })

        axios
          .post(path, formData)
          .then(() => {
            window.location.reload()
          })
          .catch((e) => {
            errorsSet(get(e, 'response.data.errors'))
          })
      }}
    >
      <textarea
        className="Input"
        rows="3"
        placeholder="พิมพ์ความคิดเห็น หรือ เรื่องที่ต้องการบันทึก ที่นี่ ..."
        name="comment"
      ></textarea>

      <div className="Buttons Buttons--jc-e">
        <button className="Button">แสดงความคิดเห็น</button>
      </div>

      {!isEmpty(errors) && (
        <Fragment>
          <ul className="FormsNew__body__form__errors">
            {flatten(entries(errors).map((x) => get(x, '1'))).map((x, i) => (
              <li key={i} className="FormsNew__body__form__errors__li">
                {x}
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </form>
  )
}

DashboardRequestsShowForm.propTypes = {}

export default DashboardRequestsShowForm

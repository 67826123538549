import { Box } from '@chakra-ui/react'

import CorrectCircleIcon from '@/images/correct-circle-icon.svg'
import IncorrectCircleIcon from '@/images/incorrect-circle-icon.svg'

import { FlashType } from '../types'

type FlashMessageProps = {
  type: FlashType
  message: string
}

export const FlashMessage = (props: FlashMessageProps) => {
  const { type, message } = props

  const isNoticeType = type === 'notice'

  return (
    <Box
      sx={{
        fontWeight: 500,
        borderRadius: '4px',
        mb: 4,
        p: '10px',
        d: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: isNoticeType ? '#9dd216' : '#f97272',
        backgroundColor: isNoticeType
          ? 'rgba(157, 210, 22, 0.16)'
          : 'rgba(249, 114, 114, 0.16)',
        _before: {
          content: '""',
          flex: '0 0 16px',
          boxSize: '16px',
          background: `no-repeat center/contain url("${
            type === 'notice' ? CorrectCircleIcon : IncorrectCircleIcon
          }")`,
          mr: '10px',
        },
      }}
    >
      {message}
    </Box>
  )
}

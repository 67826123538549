import { useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  Box,
  Text,
  Icon,
  CSSObject,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'

import type { FormLanguage } from '@/features/forms/types'

import { useNonInitialEffect } from '@/lib/hooks'
import { LANGUAGE_OPTIONS } from '@/constants/formLanguages'

type Props = {
  handleChange: (value: FormLanguage) => void
  id?: string
  isDisabled?: boolean
  isFullSize?: boolean
  isMini?: boolean
  menuShortLabel?: boolean
  sx?: CSSObject
  value: FormLanguage
}

export const LanguageSelect = (props: Props) => {
  const {
    handleChange,
    id = 'LanguageSelect',
    isDisabled = false,
    isFullSize = false,
    isMini = false,
    menuShortLabel = false,
    sx,
    value,
  } = props

  const [option, setOption] = useState(() => {
    return (
      LANGUAGE_OPTIONS.find((opt) => opt.value === value) || LANGUAGE_OPTIONS[0]
    )
  })

  useNonInitialEffect(() => {
    if (value === option.value) return

    handleChange(option.value)
  }, [option])

  return (
    <Menu id={id}>
      <MenuButton
        id="CustomizeLanguage-toggle"
        sx={{
          transition: 'all 0.2s',
          borderRadius: '4px',
          borderWidth: '1px',
          p: '8px',
          w: '100%',
          _disabled: {
            cursor: 'not-allowed',
            bg: 'gray.100',
          },
          ...sx,
        }}
        disabled={isDisabled}
        type="button"
      >
        <Grid
          sx={{
            gridTemplateColumns:
              isFullSize || isMini ? '24px 1fr 14px' : '24px 14px',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              w: '24px',
              h: '18px',
              background: `no-repeat center/contain url(${option.icon})`,
              color: 'inherit',
            }}
          />
          <Text sx={{ textAlign: 'start' }}>
            {isMini ? option.shortLabel : option.label}
          </Text>

          <Icon as={FiChevronDown} />
        </Grid>
      </MenuButton>

      <MenuList sx={{ minW: menuShortLabel ? '80px' : '144px' }}>
        {LANGUAGE_OPTIONS.map((opt) => (
          <MenuItem
            key={opt.value}
            onClick={() => setOption(opt)}
            type="button"
            data-testid={`${id}-${opt.value}`}
          >
            <Grid
              sx={{
                gridTemplateColumns: '20px 1fr',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  w: '20px',
                  h: '16px',
                  background: `no-repeat center/contain url(${opt.icon})`,
                }}
              />
              <Text>{menuShortLabel ? opt.shortLabel : opt.label}</Text>
            </Grid>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

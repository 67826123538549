import { Link, Text, VStack } from '@chakra-ui/react'

import { AuthLayout, LoginForm } from '../../components'
import {
  FORGOT_PASSWORD_PATH,
  POLICIES_PATH,
  REGISTER_PATH,
  TERMS_PATH,
} from '../../constants'
import { Flash } from '../../types'

type LoginPageProps = {
  formActionPath: string
  flash: Flash
}

function LoginPage(props: LoginPageProps) {
  const { formActionPath, flash } = props

  return (
    <AuthLayout
      title="ลงชื่อเข้าใช้บัญชีของคุณ"
      flash={flash}
      subtitle={
        <Text sx={{ fontWeight: 500 }}>
          ยังไม่มีบัญชีใช่ไหม? <Link href={REGISTER_PATH}>สมัครสมาชิก</Link>
        </Text>
      }
      footer={
        <VStack spacing="4" sx={{ fontWeight: 500 }}>
          <Text>
            จำรหัสผ่านไม่ได้?&nbsp;
            <Link href={FORGOT_PASSWORD_PATH}>ลืมรหัสผ่าน</Link>
          </Text>

          <Text>
            <Link isExternal href={TERMS_PATH}>
              ข้อตกลงและเงื่อนไข
            </Link>
            &nbsp;และ&nbsp;
            <Link isExternal href={POLICIES_PATH}>
              นโยบายความเป็นส่วนตัว
            </Link>
          </Text>
        </VStack>
      }
    >
      <LoginForm formActionPath={formActionPath} />
    </AuthLayout>
  )
}

export default LoginPage

import { Box, Text } from '@chakra-ui/react'

type UserSettingLayoutProps = {
  title: string
  children: React.ReactNode
}

export const UserSettingLayout = (props: UserSettingLayoutProps) => {
  const { title, children } = props

  return (
    <Box
      layerStyle="container"
      sx={{
        maxW: '620px',
      }}
    >
      <Box className="Header">
        <Text as="h2" textStyle="h2">
          {title}
        </Text>
      </Box>

      <Box
        sx={{
          mt: '20px',
          borderRadius: '8px',
          bg: 'white',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

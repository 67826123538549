import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useQuery, useMutation } from 'react-query'

import { getErrorToastObject } from '@/lib/helpers/utils'
import addressAPI from '@/features/dashboard/payments/api/addresses'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants/toast'
import { Address, RawAddress } from '@/features/dashboard/payments/types'

type CreateAddress = (args: {
  data: Omit<RawAddress, 'id'>
}) => Promise<AxiosResponse>

type UpdateAddress = (args: {
  data: Partial<Omit<RawAddress, 'id'>>
  params: { id: string }
}) => Promise<AxiosResponse>

type RemoveAddress = (args: {
  params: { id: string }
}) => Promise<AxiosResponse>

interface UseAddressServicesResult {
  addresses: Address[]
  isFetching: boolean
  isRequesting: boolean
  create: CreateAddress
  update: UpdateAddress
  remove: RemoveAddress
}

export function useAddressServices(
  initialData?: () => Address[] | Address[],
): UseAddressServicesResult {
  const toast = useToast()

  const { data: addresses, isFetching, isLoading, refetch } = useQuery(
    addressAPI.list.getQueryKey(),
    () => addressAPI.list.queryFn(),
    {
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
      refetchOnMount: true,
      initialData,
    },
  )

  const { mutateAsync: create, isLoading: isLoadingCreate } = useMutation(
    addressAPI.create,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'บันทึกสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: update, isLoading: isLoadingUpdate } = useMutation(
    addressAPI.update,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'บันทึกสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: remove, isLoading: isLoadingDelete } = useMutation(
    addressAPI.remove,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          status: 'warning',
          description: 'ลบสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  return {
    addresses: addresses || [],
    isFetching: isFetching || isLoading,
    isRequesting: isLoadingCreate || isLoadingUpdate || isLoadingDelete,
    create,
    update,
    remove,
  }
}

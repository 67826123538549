import { Meta, Story } from '@storybook/react'
import { action } from '@storybook/addon-actions'
import { Box } from '@chakra-ui/react'

import { RequestForm, RequestFormProps } from './RequestForm'

export default {
  title: 'Features/RequestForm',
  component: RequestForm,
  argTypes: {
    canCustomizeTheme: {
      name: 'Customize Theme',
      control: {
        type: 'boolean',
      },
    },
    canCustomizeSubDomain: {
      name: 'Customize Sub Domain',
      control: {
        type: 'boolean',
      },
    },
    onSubmitForm: action('onSubmit: '),
  },
} as Meta

const Template: Story<RequestFormProps> = (args) => {
  return (
    <Box w="500px">
      <RequestForm {...args} />
    </Box>
  )
}

export const Default = Template.bind({})
Default.args = {
  canCustomizeSubDomain: true,
  canCustomizeTheme: true,
}

import { Box, Center, Circle, Grid, HStack, Text } from '@chakra-ui/react'
import { ResponsivePie } from '@nivo/pie'
import { Fragment, useMemo } from 'react'

import { DashboardStats, RequestType } from '../types'

type RequestPieChartType = {
  data?: DashboardStats['request_type_count']
}

const NO_DATA_ID = 'no-data'

export const RequestPieChart = (props: RequestPieChartType) => {
  const { data } = props

  const { memoizedData, colors, isNoData } = useMemo(() => {
    if (!data || Object.values(data).reduce((sum, cur) => sum + cur, 0) === 0) {
      return {
        memoizedData: [{ id: NO_DATA_ID, value: 1, hideTooltip: true }],
        colors: ['#f6f6f6'],
        isNoData: true,
      }
    }

    const processedData = Object.keys(data)
      .filter((key) => Boolean(data[key]))
      .map((key) => ({
        id: key,
        value: data[key],
        hideTooltip: false,
      }))

    const colors = processedData.map((datum) => legendConstant[datum.id].color)

    return { memoizedData: processedData, colors, isNoData: false }
  }, [data])

  return (
    <Center
      sx={{
        boxSize: {
          base: '200px',
          md: '248px',
        },
      }}
    >
      <ResponsivePie
        data={memoizedData}
        activeOuterRadiusOffset={isNoData ? 0 : 4}
        colors={colors}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        endAngle={180}
        innerRadius={0.7}
        margin={{ top: 4, left: 4, right: 4, bottom: 4 }}
        padAngle={1}
        startAngle={-180}
        tooltip={(props) => {
          const { datum } = props

          if (datum.data.hideTooltip) return null

          return (
            <HStack
              sx={{
                bg: 'white',
                h: '36px',
                px: '8px',
                borderRadius: '4px',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
              }}
              spacing="6px"
            >
              <Circle
                sx={{
                  boxSize: '14px',
                  bg: datum.color,
                }}
              />
              <Text sx={{ transform: 'translateY(-1px)' }}>
                {legendConstant[datum.id as RequestType].label} :{' '}
                <strong>{datum.value}</strong>
              </Text>
            </HStack>
          )
        }}
        layers={[
          'arcs',
          ({ centerX, centerY }) => {
            if (!isNoData) return null

            return (
              <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  fill: '#b8b8b8',
                }}
              >
                ไม่มีข้อมูล
              </text>
            )
          },
        ]}
      />
    </Center>
  )
}

type RequestLegendProps = {
  data?: DashboardStats['request_type_count']
}

export const RequestLegend = (props: RequestLegendProps) => {
  const { data } = props

  const memoizedData = useMemo(() => {
    if (!data) return []

    return Object.keys(data)
      .map((key) => ({
        label: legendConstant[key].label,
        color: legendConstant[key].color,
        value: data?.[key] || 0,
      }))
      .sort((a, b) => b.value - a.value)
  }, [data])

  return (
    <Box
      sx={{
        mx: 'auto',
        width: '100%',
      }}
    >
      <Text sx={{ color: 'gray.600', fontWeight: 700 }}>ประเภทคำร้อง</Text>
      <Grid
        sx={{
          mt: 2,
          gridTemplateColumns: '14px 1fr max-content',
          gap: '8px',
          alignItems: 'center',
          p: {
            _even: {
              fontWeight: 700,
            },
          },
          div: {
            boxSize: '14px',
          },
        }}
      >
        {memoizedData.map((datum) => (
          <Fragment key={datum.label}>
            <Circle bg={datum.color} />
            <Text>{datum.label}</Text>
            <Text>{datum.value}</Text>
          </Fragment>
        ))}
      </Grid>
    </Box>
  )
}

const legendConstant: Record<RequestType, Record<string, string>> = {
  know_how: {
    label: 'ขอทราบการใช้งาน',
    color: '#0DD9C0',
  },
  correct: {
    label: 'แก้ไขข้อมูล',
    color: '#80B2FF',
  },
  transfer: {
    label: 'ย้ายข้อมูล',
    color: '#A180FF',
  },
  stop_using: {
    label: 'หยุดใช้เพื่อการโฆษณา',
    color: '#9D5806',
  },
  forgotten: {
    label: 'ลบข้อมูล',
    color: '#F65C5C',
  },
  stop_process: {
    label: 'หยุดดำเนินการ',
    color: '#F67B23',
  },
  object: {
    label: 'คัดค้านการใช้งาน',
    color: '#FFC120',
  },
}

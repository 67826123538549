import { Box, Text } from '@chakra-ui/react'
import { WebhookLog } from '../../types'

import { WebhookLogTable } from '../components'

type WebhookLogSectionProps = {
  data: WebhookLog[]
}

export const WebhookLogSection = (props: WebhookLogSectionProps) => {
  const { data } = props

  return (
    <Box sx={{ p: '24px 32px', borderBottom: '1px solid #e8e8e8' }}>
      <Text as="h2" sx={{ fontSize: '20px', fontWeight: 'semibold' }}>
        Log
      </Text>

      <Box mt="2">
        <WebhookLogTable data={data} />
      </Box>
    </Box>
  )
}

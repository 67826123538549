import { useEffect } from 'react'

import { logEvent } from '@/lib/stats'

const FailedSignUpEvent = () => {
  useEffect(() => {
    setTimeout(() => {
      logEvent({
        ga: {
          category: 'Sign up page',
          action: 'Failed Registration',
        },
      })
    }, 1000)
  }, [])

  return null
}

export default FailedSignUpEvent

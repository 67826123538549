import { AuthLayout, ResetExpiredPasswordForm } from '../components'
import { Flash, ResetPasswordFormInput } from '../types'

type ResetPasswordPageProps = {
  errors: Partial<Record<keyof ResetPasswordFormInput, string[]>>
  flash: Flash
  formActionPath: string
  resetPasswordToken: string
}

function PasswordExpiredPage(props: ResetPasswordPageProps) {
  const { formActionPath, resetPasswordToken, flash, errors } = props

  return (
    <AuthLayout
      title="เปลี่ยนรหัสผ่าน"
      subtitle="เนื่องจากนโยบายของคุณมีการกำหนดอายุรหัสผ่าน"
      flash={flash}
    >
      <ResetExpiredPasswordForm
        formActionPath={formActionPath}
        resetPasswordToken={resetPasswordToken}
        errors={errors}
      />
    </AuthLayout>
  )
}

export default PasswordExpiredPage

import { Dispatch, SetStateAction, useMemo } from 'react'
import { Column } from 'react-table'

import { SelectableTable } from '@/components/atoms/Table'
import { MigrationAdmin } from '../../types'
import { formatDataTime } from '@/lib/helpers'

type UserMigrationTableProps = {
  data: MigrationAdmin[]
  handleSelectRow: Dispatch<SetStateAction<any>>
  limit: number
}

export const UserMigrationTable = (props: UserMigrationTableProps) => {
  const { handleSelectRow, data, limit } = props

  const memoizedData = useMemo(() => data, [data])
  const columns = useMemo<Array<Column<MigrationAdmin>>>(
    () => [
      {
        Header: 'รายชื่อ',
        accessor: 'first_name',
        Cell: (cellProps) => {
          const row = cellProps.row.original

          return `${row.first_name} ${row.last_name}`
        },
      },
      {
        Header: 'อีเมล',
        accessor: 'email',
      },
      {
        Header: 'ตำแหน่ง',
        accessor: 'role',
        Cell: (cellProps) => {
          return cellProps.value.toTitleCase()
        },
      },
      {
        Header: 'วันที่สร้าง',
        accessor: 'created_at',
        Cell: (cellProps) => {
          return formatDataTime(cellProps.value)
        },
      },
    ],
    [],
  )

  const selectedRowIds = useMemo(() => {
    const result: Record<string, boolean> = {}

    const index = memoizedData.findIndex((data) => data.role === 'owner')

    result[index] = true

    return result
  }, [memoizedData])

  return (
    <SelectableTable
      selectColumnWidth="20px"
      data={memoizedData}
      columns={columns}
      selectProps={{
        limit,
        handleSelectRow,
        customRowDisable(user) {
          return user.role === 'owner'
        },
      }}
      initialState={{
        selectedRowIds,
      }}
    />
  )
}

import { useState, Fragment, useRef } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { withPage } from '@/lib/layout/Application'
import { CopyUrlModal } from '@/features/forms/components/CopyUrlModal'

import { getIframe } from '@/features/forms/helpers'
import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'
import { axios } from '@/lib/helpers'

interface Props {
  name: string
  path: string
  isFormActive: boolean
}

function ActionDropdown({ name, path, isFormActive }: Props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const copyUrlDisclosure = useDisclosure()

  useOnClickOutside(
    buttonRef,
    () => {
      setDropdownOpen(false)
    },
    [dropdownRef],
  )

  return (
    <Fragment>
      <div
        ref={buttonRef}
        className="mb-detailCard__actionButton"
        onClick={() => setDropdownOpen((o) => !o)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="#888"
            fillRule="evenodd"
            d="M12 7c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0 7c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0 7c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"
          />
        </svg>
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef} className="mb-detailCard__dropdown">
          <a
            className="mb-detailCard__dropdownItem"
            onClick={() => {
              copyUrlDisclosure.onOpen()
            }}
          >
            เผยแพร่
          </a>
          {isFormActive ? (
            <a
              className="mb-detailCard__dropdownItem"
              onClick={() => {
                axios.put(path, { status: 'inactive' }).then(() => {
                  window.location.reload()
                })
              }}
            >
              ซ่อนฟอร์ม
            </a>
          ) : (
            <a
              className="mb-detailCard__dropdownItem"
              onClick={() => {
                axios.put(path, { status: 'active' }).then(() => {
                  window.location.reload()
                })
              }}
            >
              เลิกซ่อน
            </a>
          )}
          <a className="mb-detailCard__dropdownItem" href={path}>
            แก้ไข
          </a>
          <a
            className="mb-detailCard__dropdownItem mb-detailCard__dropdownItem--delete"
            onClick={() => {
              axios.delete(path, { status: 'inactive' }).then(() => {
                window.location.reload()
              })
            }}
          >
            ลบฟอร์ม
          </a>
        </div>
      )}
      <CopyUrlModal
        disclosure={copyUrlDisclosure}
        url={path}
        iframe={getIframe(path, name)}
      />
    </Fragment>
  )
}

export default withPage(ActionDropdown)

import { get, isEmpty } from 'lodash'
import { prettyCardNumber } from '@datawowio/payment-ui'
import creditCardType from 'credit-card-type'

import {
  _RawCard,
  RawCard,
  Card,
  PaymentMethod,
} from '@/features/dashboard/payments/types'

function convertRawCreditCard(rawCard: _RawCard): Card {
  if (rawCard.attributes) {
    const brand = mapCreditCardBrand(rawCard.attributes.brand)
    const number = prependEmptySpace(
      get(rawCard.attributes, 'data.last4'),
      brand,
    )

    return {
      id: `${rawCard.id}`,
      primary: rawCard.attributes.default_card,
      brand: rawCard.attributes.brand,
      number: prettyCardNumber(number, brand),
    }
  }

  const brand = mapCreditCardBrand(rawCard.brand)
  const number = prependEmptySpace(get(rawCard, 'data.last4'), brand)

  return {
    id: `${rawCard.id}`,
    primary: rawCard.default_card,
    brand: rawCard.brand,
    number: prettyCardNumber(number, brand),
  }
}

export function convertCreditCard(card: Card): RawCard {
  return {
    id: card.id,
    default_card: card.primary,
    brand: card.brand,
    number: card.number,
  }
}

export function transformCreditCards(cards: RawCard[]): Card[] {
  return cards.map(convertRawCreditCard)
}

export function getDefaultCreditCard(cards: Card[]): PaymentMethod {
  const notFoundResult: PaymentMethod = {
    type: 'credit',
    payload: '',
  }
  if (isEmpty(cards)) {
    return notFoundResult
  }

  const card = cards.find((card) => card.primary)

  if (card) {
    return {
      type: 'credit',
      payload: card.id,
    }
  }

  return notFoundResult
}

const prependEmptySpace = (last4: string, cardType: string) => {
  const card = creditCardType.getTypeInfo(cardType)
  const [totalLength] = card.lengths

  return new Array(totalLength - 4).fill('X').join('') + last4
}

const mapCreditCardBrand = (brand: string): string => {
  switch (brand) {
    case 'amex':
      return 'american-express'
    default:
      return brand
  }
}

export const TABLE_COLUMN_HEADERS = [
  {
    title: 'แพ็คเกจ',
    mini: '(รวมภาษีมูลค่าเพิ่ม 7%)',
  },
  {
    title: 'ราคา',
  },
  {
    title: 'จำนวนบัญชีในระบบ',
  },
  {
    title: 'จำนวนแบบฟอร์มรับคำร้อง',
  },
  {
    title: 'จำนวนการส่งแบบฟอร์ม',
  },
  {
    title: 'กำหนดโดเมนเอง พร้อมกับฟรี SSL',
  },
  {
    title: 'กำหนด URL ซับโดเมน',
  },
  {
    title: 'ปรับแต่งโลโก้และธีมสีของฟอร์ม',
  },
  {
    title: 'สามารถเข้าถึงได้ด้วย API',
  },
  {
    title: 'ระยะเวลาการเก็บข้อมูล',
  },
  {
    title: '',
  },
]

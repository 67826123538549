import { Grid, Link } from '@chakra-ui/layout'

import { useActiveRoute } from '@/lib/routes'
import { MenuItem } from '../types'

type Props = {
  menus: MenuItem[]
}

export const DashboardNavigation = (props: Props) => {
  const { menus } = props
  const checkActiveRoute = useActiveRoute()

  return (
    <Grid
      sx={{
        bg: 'white',
        gridTemplateColumns: `repeat(${menus.length}, max-content)`,
        gap: '20px',
      }}
    >
      {menus.map((menu) => {
        const { label, href, checkActive } = menu
        const isActive = checkActiveRoute(checkActive)

        return (
          <Link
            key={label}
            href={href}
            sx={{
              fontSize: '14px',
              color: 'gray.900',
              d: 'flex',
              alignItems: 'center',
              p: '20px 30px',
              textDecor: 'none !important',
              boxShadow: 'none',
              ...(isActive && {
                boxShadow: 'inset 0 -4px 0 0 #2879f9',
                color: 'primary.400',
              }),
            }}
          >
            {label}
          </Link>
        )
      })}
    </Grid>
  )
}

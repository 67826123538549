import { get, entries, nth } from 'lodash'

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key: string, args = {}) => {
  try {
    let text = get(gon.locales, `${gon.currentLocale}.${key}`, key)
    const argsEntries: [string, string | undefined][] = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {}
}

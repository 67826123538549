import { useState, Fragment } from 'react'

import Modal from '@/components/molecules/Modal'

const DashboardRequestsShowViewImage = ({ img }) => {
  const [modalData, modalDataSet] = useState({ isActive: false })

  return (
    <Fragment>
      <a
        className="RequestsShow__bottom__table__col__image"
        onClick={() => {
          if (!img) {
            modalDataSet({
              ...modalData,
              isActive: true,
            })
          }
        }}
        href={img}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        ดูรูปประกอบ
      </a>

      <Modal modalData={modalData} modalDataSet={modalDataSet}>
        <h2 className="Modal__h2">
          การใช้สิทธิเข้าถึงข้อมูลเพื่อตรวจสอบของผู้ยื่นคำร้อง
        </h2>

        <div className="DashboardRequestsShowViewImage">
          <p className="DashboardRequestsShowViewImage__p">
            ทางเราได้คำนึงถึงความปลอดภัยในการตรวจสอบหลักฐานการยืนยันตัวบุคคลที่ได้ยื่นคำร้องเข้ามา
            ดังนั้นบริษัทจะสามารถดูข้อมูลในส่วนดังกล่าวได้
            จะต้องแนบเอกสารส่งมาดังนี้
          </p>

          <p className="DashboardRequestsShowViewImage__p">
            <strong>สิ่งที่ต้องแนบมา:</strong>
          </p>

          <ul className="DashboardRequestsShowViewImage__ul">
            {[
              'สำเนาหนังสือรับรองบริษัท (ไม่เกิน 6 เดือน) พร้อมเซ็นต์สำเนาถูกต้อง',
              'สำเนาบัตรประชาชนของกรรมการผู้มีอำนาจลงนาม (คนใดคนนึง) พร้อมเซ็นต์สำเนาถูกต้อง',
            ].map((x, i) => (
              <li className="DashboardRequestsShowViewImage__ul__li" key={i}>
                <strong>{x}</strong>
              </li>
            ))}
          </ul>

          <p className="DashboardRequestsShowViewImage__p">
            <strong>ส่งมาที่ support@ndatathoth.com</strong> <br />
            <strong>
              ในช่วงเวลา วันจันทร์ - ศุกร์ เวลา 8.00 - 17.00 น. (เวลาทำการ)
            </strong>
          </p>

          <p className="DashboardRequestsShowViewImage__p">
            ขออภัยในความไม่สะดวก
            <br />
            ทีมงาน <strong>PDPA FORM</strong>
          </p>

          <a
            className="Button Button--large"
            onClick={() => {
              modalDataSet({
                ...modalData,
                isActive: false,
              })
            }}
          >
            ปิด
          </a>
        </div>
      </Modal>
    </Fragment>
  )
}

DashboardRequestsShowViewImage.propTypes = {}

export default DashboardRequestsShowViewImage

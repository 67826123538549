import { useState, Fragment } from 'react'
import { get, map, isEmpty, flatten, entries, cloneDeep } from 'lodash'
import className from 'classnames'

import { axios } from '@/lib/helpers'
import { useAppContext, withPage } from '@/lib/layout/Application'

import Modal from '@/components/molecules/Modal'

const DashboardAccountsIndex = ({ admins }) => {
  const { currentPackage } = useAppContext()

  const [errors, errorsSet] = useState()
  const [modalData, modalDataSet] = useState({ isActive: false })
  const [modalDataEdit, modalDataEditSet] = useState({ isActive: false })
  const [isLoading, isLoadingSet] = useState(false)
  const gon = window.gon

  return (
    <Fragment>
      <div className="DashboardAccountsIndex">
        <div className="container">
          <div className="Header">
            <h2 className="Header__h2">
              <span className="Header__breadcrumb__a">
                รายชื่อผู้ใช้ทั้งหมด
              </span>
            </h2>
            {admins.length < currentPackage.user.count && (
              <a
                className="Button"
                onClick={() => {
                  modalDataSet({
                    ...modalData,
                    isActive: true,
                  })
                }}
              >
                ส่งคำเชิญ
              </a>
            )}
          </div>

          <div
            className="Table"
            style={{
              gridTemplateColumns: 'max-content max-content 1fr',
            }}
          >
            <div className="Table__row Table__row--header">
              {[
                {
                  label: 'รายชื่อ',
                },
                {
                  label: 'อีเมล',
                },
                {
                  label: 'การกระทำ',
                },
              ].map((x, i) => (
                <div className="Table__col Table__col--header" key={i}>
                  {get(x, 'label')}
                </div>
              ))}
            </div>

            {admins.map((x, i) => (
              <div className="Table__row" key={i}>
                {['first_name', 'email'].map((key, j) => (
                  <div className="Table__col" key={j}>
                    {['role'].find((y) => key === y) ? (
                      <span
                        className={className('Table__col__plate', {
                          'Table__col__plate--lightgray': true,
                        })}
                      >
                        {get(x, key)}
                      </span>
                    ) : ['first_name'].find((y) => key === y) ? (
                      `${get(x, key)} ${get(x, 'last_name')}`
                    ) : (
                      get(x, key)
                    )}
                  </div>
                ))}

                <div className="Table__col">
                  <div className="Table__col__controls">
                    {(get(gon, 'current_admin_id') === get(x, 'id') ||
                      ['admin', 'owner'].includes(
                        get(gon, 'current_admin_role'),
                      )) && (
                      <a
                        className="Table__col__controls__a"
                        onClick={() => {
                          modalDataEditSet({
                            isActive: true,
                            data: cloneDeep(x),
                          })
                          errorsSet(null)
                        }}
                      >
                        <strong>จัดการ</strong>
                      </a>
                    )}

                    {get(gon, 'current_admin_id') !== get(x, 'id') && (
                      <a
                        className="Table__col__controls__a Table__col__controls__a--red"
                        onClick={() => {
                          if (confirm('Are you sure?')) {
                            axios
                              .delete(`/accounts/${get(x, 'id')}`)
                              .then(() => {
                                window.location.reload()
                              })
                          }
                        }}
                      >
                        ลบ
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal modalData={modalData} modalDataSet={modalDataSet}>
        <form
          className="Modal__form"
          onSubmit={(e) => {
            e.preventDefault()

            const $els = get(e, 'target.elements')
            const formData = new FormData()

            const inputs = map($els, (x) => {
              return {
                name: get(x, 'name'),
                value: get(x, 'value'),
              }
            }).filter((x) => !isEmpty(get(x, 'name')))

            inputs.forEach((x) => {
              formData.append(get(x, 'name'), get(x, 'value'))
            })

            isLoadingSet(true)

            axios
              .post('/accounts', formData)
              .then(() => {
                window.location.reload()
              })
              .catch((e) => {
                isLoadingSet(false)
                errorsSet(get(e, 'response.data.errors'))
              })
          }}
        >
          <h2 className="Modal__h2">ส่งคำเชิญผู้ใช้ใหม่</h2>

          <label className="FormsNew__body__form__label">
            <span className="FormsNew__body__form__label__span">
              อีเมลที่ต้องการเชิญ
            </span>

            <input type="text" className="Input" placeholder="" name="email" />
          </label>

          <label className="FormsNew__body__form__label">
            <span className="FormsNew__body__form__label__span">ตำแหน่ง</span>

            <select className="Select" name="role">
              <option value="user">พนักงาน</option>
              <option value="admin">ผู้ดูแล</option>
            </select>
          </label>

          {!isEmpty(errors) && (
            <Fragment>
              <ul className="FormsNew__body__form__errors">
                {flatten(entries(errors).map((x) => get(x, '1'))).map(
                  (x, i) => (
                    <li key={i} className="FormsNew__body__form__errors__li">
                      {x}
                    </li>
                  ),
                )}
              </ul>
            </Fragment>
          )}

          <button className="Button Button--large" disabled={isLoading}>
            เชิญผู้ใช้
          </button>

          <a
            className="Button Button--large Button--trans"
            onClick={() => {
              modalDataSet({
                ...modalData,
                isActive: false,
              })
            }}
          >
            ยกเลิก
          </a>
        </form>
      </Modal>

      <Modal modalData={modalDataEdit} modalDataSet={modalDataEditSet}>
        <form
          className="Modal__form"
          onSubmit={(e) => {
            e.preventDefault()

            const $els = get(e, 'target.elements')
            const formData = new FormData()

            const inputs = map($els, (x) => {
              return {
                name: get(x, 'name'),
                value: get(x, 'value'),
              }
            }).filter((x) => !isEmpty(get(x, 'name')))

            inputs.forEach((x) => {
              formData.append(get(x, 'name'), get(x, 'value'))
            })

            isLoadingSet(true)

            axios
              .patch(`/accounts/${get(modalDataEdit, 'data.id')}`, formData)
              .then(() => {
                window.location.reload()
              })
              .catch((e) => {
                isLoadingSet(false)
                errorsSet(get(e, 'response.data.errors'))
              })
          }}
        >
          <h2 className="Modal__h2">Edit user</h2>

          <label className="FormsNew__body__form__label">
            <span className="FormsNew__body__form__label__span">Email</span>

            <input
              type="text"
              className="Input"
              disabled
              defaultValue={get(modalDataEdit, 'data.email')}
            />
          </label>

          {['admin', 'owner'].includes(get(gon, 'current_admin_role')) ? (
            <Fragment>
              <label className="FormsNew__body__form__label">
                <span className="FormsNew__body__form__label__span">
                  First name
                </span>

                <input
                  type="text"
                  className="Input"
                  defaultValue={get(modalDataEdit, 'data.first_name')}
                  name="first_name"
                />
              </label>

              <label className="FormsNew__body__form__label">
                <span className="FormsNew__body__form__label__span">
                  Last name
                </span>

                <input
                  type="text"
                  className="Input"
                  defaultValue={get(modalDataEdit, 'data.last_name')}
                  name="last_name"
                />
              </label>

              <label className="FormsNew__body__form__label">
                <span className="FormsNew__body__form__label__span">
                  ตำแหน่ง
                </span>

                <select
                  className="Select"
                  name="role"
                  value={get(modalDataEdit, 'data.role')}
                  onChange={(e) => {
                    modalDataEditSet({
                      ...modalDataEdit,
                      data: {
                        ...get(modalDataEdit, 'data'),
                        role: get(e, 'target.value'),
                      },
                    })
                  }}
                  disabled={
                    get(modalDataEdit, 'data.id') ===
                      get(gon, 'current_admin_id') ||
                    get(modalDataEdit, 'data.role') === 'owner'
                  }
                >
                  <option value="user">พนักงาน</option>
                  <option value="admin">ผู้ดูแล</option>
                  {(get(gon, 'current_admin_role') === 'owner' ||
                    get(modalDataEdit, 'data.role') === 'owner') && (
                    <option value="owner">เจ้าของ</option>
                  )}
                </select>
              </label>
            </Fragment>
          ) : (
            ''
          )}

          {!isEmpty(errors) && (
            <Fragment>
              <ul className="FormsNew__body__form__errors">
                {flatten(entries(errors).map((x) => get(x, '1'))).map(
                  (x, i) => (
                    <li key={i} className="FormsNew__body__form__errors__li">
                      {x}
                    </li>
                  ),
                )}
              </ul>
            </Fragment>
          )}

          <button className="Button Button--large" disabled={isLoading}>
            ยืนยัน
          </button>

          <a
            className="Button Button--large Button--trans"
            onClick={() => {
              modalDataEditSet({
                ...modalDataEdit,
                isActive: false,
              })
            }}
          >
            ยกเลิก
          </a>
        </form>
      </Modal>
    </Fragment>
  )
}

DashboardAccountsIndex.propTypes = {}

export default withPage(DashboardAccountsIndex)

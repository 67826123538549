import { useQuery, UseQueryOptions } from 'react-query'

import { Webhook } from '../../types'

import { WEBHOOK } from '../constants'

import { fetchAPI } from '@/lib/helpers/api'

export const useWebhook = (options?: UseQueryOptions<Webhook>) => {
  return useQuery<Webhook>({
    queryKey: [WEBHOOK],
    async queryFn() {
      const response = await fetchAPI<{ data: { attributes: Webhook } }>({
        path: '/webhooks',
      })

      return response.data.data.attributes
    },
    ...options,
  })
}

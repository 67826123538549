import { Button, Input, Stack } from '@chakra-ui/react'

import { FormControl } from '@/components/Form'

import { getAuthenticityToken } from '../helpers'

import { AuthFormProps } from '../types'

export const ResendConfirmationForm = (props: AuthFormProps) => {
  const { formActionPath } = props

  const authenticityToken = getAuthenticityToken()

  return (
    <form action={formActionPath} acceptCharset="UTF-8" method="post">
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
        autoComplete="off"
      />

      <Stack spacing="6">
        <FormControl isRequired>
          <Input
            autoFocus
            autoComplete="email"
            placeholder="อีเมล"
            type="email"
            name="admin[email]"
            id="admin_email"
            size="lg"
          />
        </FormControl>
        <Button type="submit" size="xl">
          ส่งคำขอการยืนยันบัญชีอีกครั้ง
        </Button>
      </Stack>
    </form>
  )
}

import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '@/lib/helpers'
import { transformCreditCards } from '@/features/dashboard/payments/helpers/utils'

import { Card } from '@/features/dashboard/payments/types'

const baseUrl = '/payments/credit_cards'

const list = {
  getQueryKey: (): unknown[] => [baseUrl, 'list'],
  queryFn: async ({
    config = {},
  }: {
    config?: AxiosRequestConfig
  }): Promise<Card[]> => {
    const res = await axios.get(`${baseUrl}`, config)

    if (res?.data?.data) {
      return transformCreditCards(res.data.data)
    }

    return []
  },
}

async function setup(): Promise<{ client_secret: 'string' }> {
  const res = await axios.post(`${baseUrl}/setup`, undefined)

  return res.data || {}
}

async function create({
  data,
}: {
  data: {
    payment_method_id: string
    default_card: boolean
  }
}): Promise<AxiosResponse> {
  return axios.post(`${baseUrl}`, data)
}

async function update({
  data,
  params,
}: {
  data: {
    default_card: boolean
  }
  params: {
    id: string
  }
}): Promise<AxiosResponse> {
  return axios.put(`${baseUrl}/${params.id}`, data)
}

async function remove({
  params,
}: {
  params: { id: string }
}): Promise<AxiosResponse> {
  return axios.delete(`${baseUrl}/${params.id}`, undefined)
}
export default {
  baseUrl,
  list,
  setup,
  create,
  update,
  remove,
}

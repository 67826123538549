import { useState } from 'react'
import { get } from 'lodash'
import * as Cookies from 'js-cookie'

import { axios } from '@/lib/helpers'

interface Props {
  confirmations_path: string
}

const AlertAdminConfirm = ({ confirmations_path }: Props) => {
  const [isSuccess, isSuccessSet] = useState(false)
  const [isLoading, isLoadingSet] = useState(false)

  if (Cookies.get('_pdpa_admin_confirmation_snooze')) {
    return null
  }

  return (
    <div className="Alert__wrapper">
      <p className="Alert__p">
        {isSuccess && 'ส่งอีเมลยืนยันเรียบร้อยแล้ว '}
        กรุณาตรวจสอบ ‘กล่องจดหมายเข้า’ ในอีเมลของคุณ เพื่อเสร็จสิ้นการสร้างบัญชี
      </p>

      <a
        className="Alert__a"
        disabled={isSuccess || isLoading}
        onClick={() => {
          isLoadingSet(true)

          axios.post(confirmations_path, undefined).then((res) => {
            if (get(res, 'status') == 200) {
              isSuccessSet(true)
            }
          })
        }}
      >
        ส่งเมลยืนยันอีกครั้ง
      </a>

      <a
        className="Alert__a Alert__a--alt"
        disabled={isLoading}
        onClick={() => {
          Cookies.set('_pdpa_admin_confirmation_snooze', 1, {
            expires: 1, // 1 day.
          })

          window.location.reload()
        }}
      >
        ไม่ใช่ตอนนี้
      </a>
    </div>
  )
}

AlertAdminConfirm.propTypes = {}

export default AlertAdminConfirm

import { Fragment, useState, ReactNode } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import className from 'classnames'

import img1 from '@/images/app/Landing/WhyUs__carousel__1.jpg'
import img2 from '@/images/app/Landing/WhyUs__carousel__2.jpg'
import img3 from '@/images/app/Landing/WhyUs__carousel__3.jpg'
import img4 from '@/images/app/Landing/WhyUs__carousel__4.jpg'

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const contentVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: '-50%',
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const WhyUsCarousel = () => {
  const [[page, direction], setPage] = useState([0, 0])

  const imageIndex = wrap(0, images.length, page)

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <Fragment>
      <div className="WhyUs__carousel">
        <button
          className="WhyUs__carousel__arrow WhyUs__carousel__arrow--left"
          onClick={() => paginate(-1)}
        ></button>
        <button
          className="WhyUs__carousel__arrow WhyUs__carousel__arrow--right"
          onClick={() => paginate(1)}
        ></button>

        <div className="WhyUs__carousel__container">
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              className="WhyUs__carousel__img"
              key={page}
              src={images[imageIndex]}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
            />
          </AnimatePresence>

          <div className="WhyUs__carousel__controller">
            {Array.from(Array(images.length)).map((_, idx) => {
              return (
                <button
                  className={className('WhyUs__carousel__controller__item', {
                    'WhyUs__carousel__controller__item--active':
                      idx === page % 4,
                  })}
                  key={idx}
                  onClick={() => {
                    const dir = idx > page ? 1 : -1
                    setPage([idx, dir])
                  }}
                ></button>
              )
            })}
          </div>

          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className="WhyUs__carousel__content"
              key={page}
              custom={direction}
              variants={contentVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
            >
              {contents[imageIndex]}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </Fragment>
  )
}

export default WhyUsCarousel

interface ContentProps {
  title: string
  content: ReactNode
  color: string
}

const Content = (props: ContentProps) => {
  const { title, content, color } = props

  return (
    <Fragment>
      <h4 className="WhyUs__carousel__content__h4" style={{ color }}>
        {title}
      </h4>
      <p className="WhyUs__carousel__content__p">{content}</p>
    </Fragment>
  )
}

const images = [img1, img2, img3, img4]
const contents = [
  {
    title: 'Friendly Form',
    content:
      'สร้างแบบคำร้องขอตรวจสอบข้อมูลที่สอดคล้องกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล 2562อย่างรวดเร็วและง่ายดาย พร้อมทั้งได้ URL ไปติดตั้งบนเว็บไซต์บริษัทของคุณ',
    color: '#9dd216',
  },
  {
    title: 'Tracking Platform',
    content:
      'คุณสามารถรับและติดตามคำร้องผ่านทางแพลตฟอร์ม อีกทั้งตรวจสอบข้อมูล สถานะคำร้องต่างๆ ด้านในได้อีกด้วย',
    color: '#2879f9',
  },
  {
    title: 'Deadline Alert',
    content: (
      <Fragment>
        ไม่พลาดทุกคำร้องขอที่ส่งเข้าระบบ กับการแจ้งเตือนก่อนครบกำหนดจาก
        <strong>PDPA</strong> แพลตฟอร์ม ดังนั้นคุณจะมั่นใจได้ว่า
        ทุกคำร้องถูกจัดการ <strong>ภายใน 30 วัน</strong> ตามข้อบังคับ
      </Fragment>
    ),
    color: '#f97272',
  },
  {
    title: 'Work Together (Multi Users)',
    content: (
      <Fragment>
        สำหรับบริษัทที่มีคำร้องขอจำนวนมาก
        อาจจะต้องมีผู้ช่วยจัดการคำร้องหลายคนบนระบบ
        <strong>PDPA</strong> แพลตฟอร์มนี้
        สามารถให้คุณเชิญผู้มีสิทธิจัดการคำร้องมาช่วยจัดการงานได้ไม่จำกัดจำนวนคน
      </Fragment>
    ),
    color: '#cea473',
  },
].map((item) => <Content key={item.title} {...item} />)

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

import { Button, FormControl, Input, VStack, FormLabel } from '@chakra-ui/react'
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'

interface Props {
  handleSubmitEmail: UseFormHandleSubmit<{ old: any; new: string }>
  registerEmail: UseFormRegister<{ old: any; new: string }>
  loading: boolean
  changeEmail: () => void
}

export const ChangeMailForm = (props: Props) => {
  const { handleSubmitEmail, registerEmail, loading, changeEmail } = props
  return (
    <form className="Modal__form" onSubmit={handleSubmitEmail(changeEmail)}>
      <VStack spacing="24px" align="left">
        <FormControl label="อีเมลปัจจุบัน">
          <FormLabel>อีเมลปัจจุบัน</FormLabel>
          <Input
            {...registerEmail('old', { disabled: true })}
            sx={{
              fontSize: '16px',
              backgroundColor: 'gray.300',
              color: 'gray.600',
            }}
            placeholder="อีเมลปัจจุบัน"
            type="email"
            size="lg"
          />
        </FormControl>
        <FormControl label="อีเมลใหม่" isRequired>
          <FormLabel
            sx={{
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            อีเมลใหม่
          </FormLabel>
          <Input
            type="email"
            {...registerEmail('new', { required: true })}
            sx={{
              fontSize: '16px',
            }}
            placeholder="ต้องการเปลี่ยนเป็น..."
            size="lg"
          />
        </FormControl>
      </VStack>
      <Button
        colorScheme="brand"
        type="submit"
        sx={{ width: '100%', height: '64px', mt: '4px' }}
        isLoading={loading}
      >
        ยืนยัน
      </Button>
    </form>
  )
}

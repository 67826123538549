import { useState, Fragment } from 'react'
import className from 'classnames'
import { get } from 'lodash'
import { Text } from '@chakra-ui/layout'

import Nav from './Nav'
import { withTheme } from '@/lib/styles'

function OffCanvas(props) {
  const { links, signOutUrl, admin, expiry } = props

  const [isShow, setShow] = useState(false)
  const userData = window.gon

  return (
    <Fragment>
      <div
        className={className('mb-offcanvas', { 'mb-offcanvas--show': isShow })}
      >
        <div className="mb-offcanvas__container">
          <div
            className="mb-offcanvas__menuIcon"
            onClick={() => setShow((isShow) => !isShow)}
          >
            <svg width="24px" height="18px" viewBox="0 0 24 18">
              <path d="M1,16 C0.44771525,16 0,16.4477153 0,17 C0,17.5522847 0.44771525,18 1,18 L23,18 C23.5522847,18 24,17.5522847 24,17 C24,16.4477153 23.5522847,16 23,16 L1,16 Z M1,8 C0.44771525,8 0,8.44771525 0,9 C0,9.55228475 0.44771525,10 1,10 L15,10 C15.5522847,10 16,9.55228475 16,9 C16,8.44771525 15.5522847,8 15,8 L1,8 Z M1,0 C0.44771525,-1.01453063e-16 0,0.44771525 0,1 C0,1.55228475 0.44771525,2 1,2 L23,2 C23.5522847,2 24,1.55228475 24,1 C24,0.44771525 23.5522847,1.01453063e-16 23,0 L1,0 Z"></path>
            </svg>
          </div>
          <div className="mb-offcanvas__panel">
            <div className="mb-offcanvas__profile">
              <div className="mb-userProfile">
                <div className="mb-userProfile__avatar">
                  <img src={get(userData, 'company_logo')} />
                </div>
                <div>
                  <Text sx={{ fontWeight: 700 }}>{admin.email}</Text>

                  <Text
                    sx={{ fontSize: '12px', color: 'gray.600' }}
                  >{`ใช้ได้ถึง ${expiry}`}</Text>
                </div>
              </div>
            </div>

            <div className="mb-offcanvas__navigationTitle">Navigation</div>
            <div className="mb-offcanvas__menu">
              <Nav links={links} signOutUrl={signOutUrl} />
            </div>
          </div>
        </div>
      </div>
      {isShow && (
        <div
          className="mb-navigationBackdrop"
          onClick={() => setShow(false)}
        ></div>
      )}
    </Fragment>
  )
}

export default withTheme(OffCanvas)

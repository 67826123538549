import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './resources/en'
import th from './resources/th'

i18n.use(initReactI18next).init({
  lng: gon.currentLocale,
  fallbackLng: 'th',
  resources: {
    en,
    th,
  },
  defaultNS: 'common',
})

export default i18n

import { Fragment } from 'react'
import { get, startsWith, isEmpty } from 'lodash'
import className from 'classnames'
import { Stack, Text } from '@chakra-ui/layout'

import { withTheme } from '@/lib/styles'
import { MatchingRole } from '@/features/permissions/components'
import { logEvent } from '@/lib/stats'

type Props = {
  admin: Admin
  links: { label: string; href: string; roles?: Role[] }[]
  links_account: {
    setting: string
    user_settings: string
    api_keys: string
  }
  links_billing: {
    package: string
    invoice: string
  }
  links_user: {
    password: string
    user_settings: string
    sign_out: string
  }
  links_dev: {
    webhooks?: string
    api_keys?: string
  }
}

const Nav = (props: Props) => {
  const { links, links_account, links_billing, links_user, links_dev, admin } =
    props

  return (
    <div className="Nav">
      <div className="container">
        <h1 className="Nav__h1">
          <a className="Nav__h1__a" href={get(links, '0.href')}>
            PDPA Form
          </a>
        </h1>

        <div className="Nav__links">
          {links.map((link) => (
            <MatchingRole key={link.href} acceptedRoles={link.roles}>
              {() => {
                return (
                  <a
                    className={className('Nav__links__a', {
                      'Nav__links__a--active': startsWith(
                        get(window, 'location.pathname'),
                        get(link, 'href'),
                      ),
                    })}
                    href={get(link, 'href')}
                    onClick={() => {
                      logEvent({
                        ga: {
                          category: 'Platform',
                          action: 'Click ' + link.href.replace('/', ''),
                        },
                      })
                    }}
                  >
                    {get(link, 'label')}
                  </a>
                )
              }}
            </MatchingRole>
          ))}

          <MatchingRole acceptedRoles={['admin', 'owner']}>
            {() => (
              <Fragment>
                <div
                  className={className('Nav__links__a', 'Nav__links__acc', {
                    'Nav__links__a--active':
                      startsWith(
                        get(window, 'location.pathname'),
                        '/settings',
                      ) ||
                      startsWith(
                        get(window, 'location.pathname'),
                        '/packages',
                      ) ||
                      startsWith(
                        get(window, 'location.pathname'),
                        '/invoices',
                      ) ||
                      startsWith(
                        get(window, 'location.pathname'),
                        '/users/settings',
                      ),
                  })}
                >
                  บัญชี
                  <div className="Nav__links__acc__panel">
                    <div className="Nav__links__acc__panel__wrapper">
                      <p className="Nav__links__acc__p">ตั้งค่า</p>
                      <div className="Nav__links__acc__panel__links">
                        <a
                          href={get(links_account, 'setting')}
                          className="Nav__links__acc__panel__links__a"
                        >
                          ตั้งค่าบัญชี
                        </a>
                        <a
                          href={get(links_account, 'user_settings')}
                          className="Nav__links__acc__panel__links__a"
                        >
                          ตั้งค่าผู้ใช้งาน
                        </a>
                      </div>

                      <div className="Nav__links__acc__panel__line"></div>

                      <p className="Nav__links__acc__p">
                        วิธีการชำระเงินและเรียกเก็บเงิน
                      </p>
                      <div className="Nav__links__acc__panel__links">
                        <a
                          href={get(links_billing, 'package')}
                          className="Nav__links__acc__panel__links__a"
                        >
                          แพ็กเกจที่ใช้งาน
                        </a>
                        <a
                          href={get(links_billing, 'invoice')}
                          className="Nav__links__acc__panel__links__a"
                        >
                          ธุรกรรมของฉัน
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {!isEmpty(links_dev) && (
                  <div
                    className={className('Nav__links__a', 'Nav__links__acc', {
                      'Nav__links__a--active':
                        startsWith(
                          get(window, 'location.pathname'),
                          '/webhooks',
                        ) ||
                        startsWith(
                          get(window, 'location.pathname'),
                          '/api_keys',
                        ),
                    })}
                  >
                    ผู้พัฒนา
                    <div className="Nav__links__acc__panel">
                      <div className="Nav__links__acc__panel__wrapper">
                        <div className="Nav__links__acc__panel__links">
                          <a
                            href={get(links_dev, 'webhooks')}
                            className="Nav__links__acc__panel__links__a"
                          >
                            Webhooks
                          </a>
                          <a
                            href={get(links_dev, 'api_keys')}
                            className="Nav__links__acc__panel__links__a"
                          >
                            API Keys
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </MatchingRole>
        </div>

        <div className="Nav__profile">
          <div className="Nav__profile__left">
            <span className="Nav__profile__span">
              {get(gon, 'company_name')}
            </span>
            <Text
              sx={{ fontSize: '10px', color: 'gray.600' }}
            >{`ใช้ได้ถึง ${gon.expiry}`}</Text>
          </div>

          {get(gon, 'company_logo') ? (
            <img className="Nav__profile__img" src={get(gon, 'company_logo')} />
          ) : (
            <div className="Nav__profile__img"></div>
          )}

          <div className="Nav__profile__panel">
            <div className="Nav__profile__panel__wrapper">
              <Stack
                spacing="4px"
                sx={{
                  pb: '15px',
                  borderBottom: '1px solid',
                  borderColor: 'gray.300',
                  mb: '16px',
                }}
              >
                <Text
                  sx={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: 'gray.600',
                  }}
                >
                  {`${admin.first_name} ${admin.last_name}`}
                </Text>
                <Text>{admin.email}</Text>
              </Stack>

              <div className="Nav__profile__panel__links">
                <a
                  className="Nav__profile__panel__links__a"
                  href={get(links_user, 'user_settings')}
                >
                  ข้อมูลส่วนตัว
                </a>

                <a
                  className="Nav__profile__panel__links__a"
                  href={get(links_user, 'security')}
                >
                  ตั้งค่าความปลอดภัย
                </a>

                <a
                  className="Nav__profile__panel__links__a"
                  href={get(links_user, 'password')}
                >
                  เปลี่ยนรหัสผ่าน
                </a>

                <a
                  className="Nav__profile__panel__links__a Nav__profile__panel__links__a--red"
                  href={get(links_user, 'sign_out')}
                >
                  ออกจากระบบ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(Nav)

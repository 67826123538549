import React, { PropsWithChildren } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

type DashboardLayoutProps = PropsWithChildren<{
  title: string
  leftElement?: React.ReactElement
}>

export const DashboardLayout = (props: DashboardLayoutProps) => {
  const { title, children, leftElement } = props

  return (
    <Box sx={{ px: 5, m: '0 auto', maxW: '1540px' }}>
      <Flex justify="space-between" align="center">
        <Text as="h1" sx={{ fontSize: '2xl', fontWeight: 'bold' }}>
          {title}
        </Text>

        {leftElement}
      </Flex>

      <Box sx={{ bg: 'white', borderRadius: '8px', mt: '20px' }}>
        {children}
      </Box>
    </Box>
  )
}

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'
import { toLower, startCase, get } from 'lodash'

import { PLANS } from '@/features/dashboard/packages/constants'

import {
  PackageDetail,
  QuotaUsage,
  DowngradeInfo,
} from '@/features/dashboard/packages/types'
import { RecurringType } from '@/features/dashboard/payments/types'
import { formatThousand } from '@/lib/helpers'

interface Props {
  packageDetail: PackageDetail
  quotaUsage: QuotaUsage
  recurring: RecurringType
  expiry: string
  willBeDowngradedToPackage: DowngradeInfo['willBeDowngradedToPackageName']
  cancelAtPeriodEnd: boolean
  onOpenUnsubModal: () => void
  onOpenResubModal: () => void
}

function Banner(props: Props) {
  const {
    cancelAtPeriodEnd,
    expiry,
    onOpenResubModal,
    onOpenUnsubModal,
    packageDetail,
    quotaUsage,
    recurring,
    willBeDowngradedToPackage,
  } = props

  const quotaFormSubmissions = get(quotaUsage, 'form_submissions', 0)
  const quotaUser = get(quotaUsage, 'users', 0)

  const packageFormSubmissions = get(packageDetail, 'form_submissions', 0)

  const currentPlan = PLANS.find(
    (plan) => plan.plan === toLower(get(packageDetail, 'name', 'free')),
  )

  const scrollToTable = () => {
    const table = document.querySelector('.PricingTableAnchor')

    if (table) {
      table.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const currentPrice = get(currentPlan, ['price', recurring])

  const isFree = packageDetail.name === 'free'

  const renderText = () => {
    if (willBeDowngradedToPackage) {
      return (
        <Text sx={{ fontSize: 'xs' }}>
          แพ็กเกจปัจจุบันจะใช้ได้ถึงวันที่ {expiry}
          <br />
          หลังจากนั้นจะแพ็กเกจจะถูกเปลี่ยนเป็นแพ็กเกจ&nbsp;
          {willBeDowngradedToPackage.toTitleCase()}
        </Text>
      )
    }

    if (cancelAtPeriodEnd) {
      return (
        <Text sx={{ fontSize: 'xs' }}>
          คุณได้ยกเลิกแพ็กเกจนี้แล้ว ใช้ได้ถึงวันที่ {expiry}
        </Text>
      )
    }

    if (isFree) {
      return null
    }

    return <Text sx={{ fontSize: 'xs' }}>ชำระอีกครั้งวันที่ {expiry}</Text>
  }

  const renderButton = () => {
    if (cancelAtPeriodEnd) {
      return (
        <Button
          variant="outline"
          colorScheme="whiteAlpha"
          onClick={onOpenResubModal}
          size="sm"
          sx={{
            w: 'max-content',
            mt: 'auto',
            fontSize: 'xs',
            borderRadius: 'full',
            borderColor: 'white',
            color: 'white',
            mixBlendMode: 'lighten',
            _hover: {
              bg: '#ffffff',
              color: 'black',
            },
            _active: {
              bg: '#ffffffcc',
              color: 'black',
            },
          }}
        >
          ใช้แพ็กเกจนี้ต่อ
        </Button>
      )
    }

    if (isFree) {
      return null
    }

    return (
      <Button
        variant="link"
        colorScheme="white"
        onClick={onOpenUnsubModal}
        size="sm"
        sx={{ w: 'max-content', mt: 'auto', fontSize: 'xs' }}
      >
        ยกเลิกแพ็กเกจ
      </Button>
    )
  }

  return (
    <Box className="Banner" sx={{ p: 8, bg: 'white' }}>
      <Grid
        sx={{
          borderRadius: '8px',
          gridTemplateColumns: '464px 1fr',
          h: '200px',
        }}
      >
        {packageFormSubmissions >= 0 &&
          quotaFormSubmissions >= packageFormSubmissions && (
            <Box className="Banner__Wrapper__Upgrade">อัปเกรดแพลน</Box>
          )}
        <Flex
          className={`Banner__Left Banner__Left--${get(
            packageDetail,
            'color',
          )}`}
          sx={{
            padding: 6,
            color: 'white',
            borderRadius: '8px 0 0 8px',
            flexDir: 'column',
          }}
        >
          <Text>ขณะนี้คุณกำลังใช้</Text>
          <Text sx={{ fontSize: '32px' }}>
            {startCase(toLower(get(packageDetail, 'name', '')))}
          </Text>
          {currentPrice && <Text sx={{ fontSize: 'md' }}>{currentPrice}</Text>}

          {renderText()}
          <Box mt={1}>{renderButton()}</Box>
        </Flex>

        <Grid
          sx={{
            p: 6,
            gridTemplateColumns: 'repeat(3, 1fr)',
            border: '1px solid',
            borderColor: 'gray.300',
            borderLeft: 'none',
          }}
          className="Banner__Right"
        >
          <Box className="Banner__Right__Item">
            <p className="Banner__Right__Item__main">{quotaUser}</p>
            <p className="Banner__Right__Item__p">จำนวนผู้ใช้</p>
          </Box>

          <Box className="Banner__Right__Item" sx={{ pos: 'relative' }}>
            <Box
              sx={{
                pos: 'absolute',
                top: '-24px',
                color: 'white',
                bg: 'brand.400',
                py: 1.5,
                px: 6,
                borderRadius: '0 0 8px 8px',
                cursor: 'pointer',
              }}
              onClick={scrollToTable}
            >
              อัปเกรดแพลน
            </Box>

            <p className="Banner__Right__Item__main">{quotaFormSubmissions}</p>
            <Box className="Banner__Right__Item__p">
              คำร้องที่ถูกส่ง
              {packageFormSubmissions >= 0 &&
                quotaFormSubmissions >= packageFormSubmissions && (
                  <span className="Banner__Warning">
                    <Box className="Banner__Warning__Popup">
                      เนื่องจากมีผู้ใช้ส่งคำร้องเข้ามามาก ขอแนะนำให้อัปเกรดแพลน
                    </Box>
                  </span>
                )}
            </Box>
            <p className="Banner__Right__Item__p--mini">
              จาก&nbsp;
              {packageFormSubmissions < 0
                ? 'ไม่จำกัด'
                : formatThousand(packageFormSubmissions)}
              &nbsp;ฟอร์มต่อเดือน
            </p>
          </Box>

          <Box className="Banner__Right__Item">
            <p className="Banner__Right__Item__main">-</p>
            <p className="Banner__Right__Item__p">API Access</p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Banner

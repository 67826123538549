import { useEffect } from 'react'
import { Button, FormControl, Input, VStack, FormLabel } from '@chakra-ui/react'
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'

interface Props {
  handleSubmitPWD: UseFormHandleSubmit<{ password: string }>
  registerPWD: UseFormRegister<{
    password: string
  }>
  loading: boolean
  checkPwd: () => void
  isReauthentication: boolean
  setStep: (step: number) => void
}

export const CheckPasswordForm = (props: Props) => {
  const {
    handleSubmitPWD,
    registerPWD,
    loading,
    checkPwd,
    isReauthentication,
    setStep,
  } = props

  useEffect(() => {
    if (!isReauthentication) {
      setStep(1)
    }
  }, [isReauthentication])

  return (
    <form className="Modal__form" onSubmit={handleSubmitPWD(checkPwd)}>
      <VStack spacing="24px">
        <FormControl label="รหัสผ่าน" isRequired>
          <FormLabel>รหัสผ่าน</FormLabel>
          <Input
            type="password"
            {...registerPWD('password')}
            sx={{
              fontSize: '16px',
            }}
            placeholder="รหัสผ่าน"
            size="lg"
          />
        </FormControl>
        <Button
          colorScheme="brand"
          type="submit"
          sx={{ width: '100%', height: '64px' }}
          isLoading={loading}
        >
          ยืนยัน
        </Button>
      </VStack>
    </form>
  )
}

import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { fetchAPI } from '@/lib/helpers/api'

import { UpdatePasswordFormInput } from '../types'
import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants'

import { useErrorHandler } from '@/lib/hooks'

type Payload = {
  data: UpdatePasswordFormInput
}

export const useUpdatePassword = () => {
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse<any>, AxiosError, Payload>(
    async function ({ data }) {
      return await fetchAPI({
        path: '/passwords',
        method: 'put',
        data: {
          admin: data,
        },
      })
    },
    {
      onSuccess() {
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'อัปเดตรหัสผ่านสำเร็จ',
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}

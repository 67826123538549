import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { RequestFormInput } from '@/features/forms/types'
import { ERROR_TOAST_OPTIONS } from '@/lib/constants'

import { fetchAPI } from '@/lib/helpers/api'
import { logEvent } from '@/lib/stats'

type Payload = {
  data: RequestFormInput
}

export const useCreateForm = () => {
  const toast = useToast()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    async ({ data }) => {
      const formData = new FormData()

      Object.entries(data).forEach(([k, v]) => {
        if (v) {
          formData.append(k, v)
        }
      })

      return fetchAPI({
        path: '/forms',
        method: 'post',
        data: formData,
      })
    },
    {
      onSuccess() {
        logEvent({
          ga: {
            category: 'Forms',
            action: 'Click create a form',
          },
        })
        window.location.href = '/forms'
      },
      onError(error) {
        toast({
          ...ERROR_TOAST_OPTIONS,
          description: error.response?.data.errors,
        })
      },
    },
  )
}

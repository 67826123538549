import { Button, Checkbox, HStack, Input, Link, Stack } from '@chakra-ui/react'
import { useState } from 'react'

import { FormControl } from '@/components/Form'
import { logEvent } from '@/lib/stats'

import { RegisterFormInput } from '../types'
import { PasswordInput } from './PasswordInput'

import { getAuthenticityToken } from '../helpers'
import { TERMS_PATH, POLICIES_PATH } from '../constants'

type RegisterFormProps = {
  formActionPath: string
  defaultValues: RegisterFormInput
  errors: Partial<Record<keyof RegisterFormInput, string[]>>
}

export const RegisterForm = (props: RegisterFormProps) => {
  const { formActionPath, defaultValues, errors } = props

  const authenticityToken = getAuthenticityToken()

  const [password, setPassword] = useState('')

  return (
    <form
      action={formActionPath}
      acceptCharset="UTF-8"
      method="post"
      onSubmit={() => {
        logEvent({
          ga: {
            category: 'Sign up page',
            action: 'Click submit registration',
          },
          fb: {
            event: 'CompleteRegistration',
          },
        })
      }}
    >
      <Stack spacing="6">
        <HStack>
          <FormControl isRequired errorMsg={errors.first_name?.[0]}>
            <Input
              name="admin[first_name]"
              autoComplete="first-name"
              autoFocus
              placeholder="ชื่อจริง"
              id="admin_first_name"
              size="lg"
              defaultValue={defaultValues.first_name}
            />
          </FormControl>
          <FormControl isRequired errorMsg={errors.last_name?.[0]}>
            <Input
              name="admin[last_name]"
              autoComplete="last-name"
              placeholder="นามสกุล"
              id="admin_last_name"
              size="lg"
              defaultValue={defaultValues.last_name}
            />
          </FormControl>
        </HStack>

        <FormControl isRequired errorMsg={errors.company_name?.[0]}>
          <Input
            name="admin[company_attributes][name]"
            autoComplete="company-name"
            placeholder="ชื่อบริษัท"
            id="admin_company_name"
            size="lg"
            defaultValue={defaultValues.company_name}
          />
        </FormControl>
        <FormControl isRequired errorMsg={errors.telephone?.[0]}>
          <Input
            name="admin[company_attributes][telephone]"
            autoComplete="company-name"
            placeholder="เบอร์โทรติดต่อ"
            id="admin_company_attributes_telephone"
            size="lg"
            defaultValue={defaultValues.telephone}
          />
        </FormControl>

        <FormControl isRequired errorMsg={errors.email?.[0]}>
          <Input
            autoComplete="email"
            id="admin_email"
            name="admin[email]"
            placeholder="อีเมล"
            size="lg"
            type="email"
            defaultValue={defaultValues.email}
          />
        </FormControl>
        <FormControl isRequired errorMsg={errors.password?.[0]}>
          <PasswordInput
            autoComplete="new-password"
            id="admin_password"
            name="admin[password]"
            placeholder="รหัสผ่าน"
            size="lg"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </FormControl>
        <FormControl isRequired errorMsg={errors.password_confirmation?.[0]}>
          <Input
            autoComplete="new-password"
            id="admin_password_confirmation"
            name="admin[password_confirmation]"
            placeholder="ยืนยันรหัสผ่าน"
            size="lg"
            type="password"
          />
        </FormControl>
        <Input
          value={authenticityToken}
          type="hidden"
          name="authenticity_token"
        />

        <Stack>
          <Checkbox
            id="admin_term_privacy"
            isRequired
          >
            ฉันยอมรับ <Link isExternal href={TERMS_PATH}>เงื่อนไขในการใช้เว็บไซต์</Link> และ <Link isExternal href={POLICIES_PATH}>นโยบายความเป็นส่วนตัว </Link>
          </Checkbox>

          <Checkbox
            id="admin_marketing_consent"
            value="1"
            name="admin[marketing_consent]"
            alignItems="flex-start"
            defaultChecked={defaultValues.marketing_consent}
          >
            ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Form
            และบริการอื่น ๆ จากเรา บริษัทในเครือ บริษัทย่อยและพันธมิตรทางธุรกิจ
            &#8203;
            <span style={{ whiteSpace: 'nowrap' }}>
              (คุณสามารถยกเลิกได้ทุกเมื่อ)
            </span>
          </Checkbox>
        </Stack>

        <Button type="submit" size="xl">
          สมัครสมาชิก
        </Button>
      </Stack>
    </form>
  )
}

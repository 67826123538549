import { ReactNode, Fragment } from 'react'
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { getLocaleDateString } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'

import { SubscriptionType, Plan } from '@/features/dashboard/packages/types'
import { Span, Strong } from '@/components/atoms/shared'

export type ModalDatum = {
  title: ReactNode
  description: ReactNode
  buttonLabel: string
  onTrackEvent?: () => void
}

const titleTextStyle = {
  fontSize: '3xl',
  textAlign: 'center',
}

const descriptionTextStyle = {
  fontSize: 'md',
  lineHeight: 1.6,
  textAlign: 'left',
}

export const MODAL_DATA: Record<
  SubscriptionType,
  (plan: Plan, expiry?: string) => ModalDatum
> = {
  downgrade: (plan, expiry) => ({
    buttonLabel: 'ดาวน์เกรด',
    title: (
      <Text as="h1" sx={titleTextStyle}>
        ต้องการดาวน์เกรด ?
      </Text>
    ),
    description: (
      <Box sx={descriptionTextStyle}>
        <Text>
          แพ็กเกจปัจจุบันของคุณจะ
          <Strong color="red.400">หมดอายุในวันที่ {expiry}</Strong>&nbsp;
          คุณจะสามารถใช้ฟีเจอร์ที่มีเฉพาะในแพ็กเกจปัจจุบันของคุณได้ถึงวันที่ดังกล่าว
        </Text>

        <Text mt="6">คุณสามารถเปลี่ยนไปใช้แพ็กเกจอื่นได้ทุกเวลา</Text>

        <Box mt="4">
          <Text sx={{ fontWeight: 300 }}>
            หลังจากนั้น แพ็กเกจของคุณจะถูกดาวน์เกรดเป็น&nbsp;
            <Strong>แพ็กเกจ {plan.plan.toTitleCase()}</Strong>
            <br />
            ด้วยข้อจำกัดดังต่อไปนี้:
          </Text>
          <UnorderedList sx={{ pl: 4, mt: 1 }}>
            <ListItem>จำนวนในบัญชีระบบ {plan.user.count} บัญชี</ListItem>
            <ListItem>จำนวนแบบฟอร์มรับคำร้อง {plan.form.count} ฟอร์ม</ListItem>
            <ListItem>
              จำนวนการส่งฟอร์ม {plan.submission.count} ฟอร์ม ต่อเดือน
            </ListItem>
            <ListItem>ระยะเวลาการเก็บข้อมูล {plan.data.count} ปี</ListItem>
          </UnorderedList>
        </Box>

        <Text sx={{ color: 'red.400', mt: 6 }}>
          <Span sx={{ textDecor: 'underline', fontWeight: 700 }}>
            ข้อควรระวัง
          </Span>
          <br />
          เมื่อคุณดาวน์เกรด หรือ ยกเลิกแพ็กเกจ คำร้องที่เกินจำนวนที่กำหนดตาม
          แพ็กเกจจะถูกลบดังนั้นควร&nbsp;
          <Strong>
            ดาวน์โหลดข้อมูล “คำร้อง” ก่อนวันที่แพ็กเกจของคุณจะถูกเปลี่ยน
          </Strong>
        </Text>
      </Box>
    ),
    onTrackEvent() {
      logEvent({
        ga: {
          category: 'Packages',
          action: 'Click confirm',
          label: plan.plan,
        },
      })
    },
  }),
  upgrade: (plan) => ({
    buttonLabel: 'อัปเกรด',
    title: (
      <Text as="h1" sx={titleTextStyle}>
        ต้องการอัปเกรด ?
      </Text>
    ),
    description: (
      <Fragment>
        <Text sx={descriptionTextStyle}>
          รอบบิลถัดไปของคุณคือวันที่{' '}
          {getLocaleDateString(dayjs().add(1, 'month').toDate())}
        </Text>
        <Text sx={descriptionTextStyle}>
          คุณจะถูกเรียกชำระเงินเป็นจำนวนที่หักส่วนลดที่คิดตามการใช้งานของรอบบิลปัจจุบันของคุณแล้ว
        </Text>
      </Fragment>
    ),
    onTrackEvent() {
      logEvent({
        ga: {
          category: 'Packages',
          action: 'Click confirm',
          label: plan.plan,
        },
      })
    },
  }),
  subscribe: (plan) => ({
    buttonLabel: 'เลือกแพ็กเกจ',
    title: (
      <Text as="h1" sx={titleTextStyle}>
        เลือกแพ็กเกจ {plan.plan.toTitleCase()}
        <br />
        ราคา {plan.price.title}
        {plan.price.unit}?
      </Text>
    ),
    description: (
      <Text sx={descriptionTextStyle}>
        รอบบิลถัดไปของคุณคือวันที่{' '}
        {getLocaleDateString(dayjs().add(1, 'month').toDate())}
      </Text>
    ),
    onTrackEvent() {
      logEvent({
        ga: {
          category: 'Packages',
          action: 'Click confirm',
          label: plan.plan,
        },
      })
    },
  }),
  unsubscribe: (plan, expiry) => ({
    buttonLabel: 'ยกเลิกแพ็กเกจ',
    title: (
      <Text as="h1" sx={titleTextStyle}>
        ต้องการยกเลิกแพ็กเกจ ?
      </Text>
    ),
    description: (
      <Box sx={{ fontSize: 'md' }}>
        <Text sx={descriptionTextStyle}>
          แพ็กเกจปัจจุบันของคุณจะ
          <Strong color="red.400">หมดอายุในวันที่ {expiry}</Strong>&nbsp;
          คุณจะสามารถใช้ฟีเจอร์ที่มีเฉพาะในแพ็กเกจปัจจุบันของคุณได้ถึงวันที่ดังกล่าว
        </Text>

        <Text mt="6">คุณสามารถเปลี่ยนไปใช้แพ็กเกจอื่นได้ทุกเวลา</Text>

        <Box mt="4">
          <Text sx={{ fontWeight: 300 }}>
            หลังจากนั้น แพ็กเกจของคุณจะถูกดาวน์เกรดเป็น&nbsp;
            <Strong>แพ็กเกจ Free</Strong>
            <br />
            ด้วยข้อจำกัดดังต่อไปนี้:
          </Text>
          <UnorderedList sx={{ pl: 4, mt: 1 }}>
            <ListItem>จำนวนในบัญชีระบบ {plan.user.count} บัญชี</ListItem>
            <ListItem>จำนวนแบบฟอร์มรับคำร้อง {plan.form.count} ฟอร์ม</ListItem>
            <ListItem>
              จำนวนการส่งฟอร์ม {plan.submission.count} ฟอร์ม ต่อเดือน
            </ListItem>
            <ListItem>ไม่สามารถกำหนด URL ซับโดเมน</ListItem>
            <ListItem>ไม่สามารถปรับแต่งโลโก้และธีมสีของฟอร์ม</ListItem>
            <ListItem>ระยะเวลาการเก็บข้อมูล {plan.data.count} ปี</ListItem>
          </UnorderedList>
        </Box>

        <Text sx={{ color: 'red.400', mt: 6 }}>
          <Span sx={{ textDecor: 'underline', fontWeight: 700 }}>
            ข้อควรระวัง
          </Span>
          <br />
          เมื่อคุณดาวน์เกรด หรือ ยกเลิกแพ็กเกจ คำร้องที่เกินจำนวนที่กำหนดตาม
          แพ็กเกจจะถูกลบดังนั้นควร&nbsp;
          <Strong>
            ดาวน์โหลดข้อมูล “คำร้อง” ก่อนวันที่แพ็กเกจของคุณจะถูกเปลี่ยน
          </Strong>
        </Text>
      </Box>
    ),
  }),
  resubscribe: (plan) => ({
    buttonLabel: 'ตกลง',
    title: (
      <Text as="h1" sx={titleTextStyle}>
        ใช้แพ็กเกจ {plan.plan.toTitleCase()} ต่อไป
      </Text>
    ),
    description: (
      <Box sx={{ fontSize: 'sm' }}>
        <Text sx={descriptionTextStyle}>
          แพ็กเกจปัจจุบันของคุณ: แพ็กเกจ {plan.plan.toTitleCase()}&nbsp;
          จะถูกต่ออายุโดยอัตโนมัติ
        </Text>
      </Box>
    ),
  }),
}

import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/helpers/api'
import { useErrorHandler } from '@/lib/hooks'

import { WebhookFormInput } from '../../types'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants'
import { WEBHOOK } from '../constants'

type Payload = {
  data: WebhookFormInput
}

export const useUpdateWebhook = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    function ({ data }) {
      return fetchAPI({
        path: '/webhooks',
        method: 'put',
        data,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([WEBHOOK])

        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'อัพเดท Webhook สำเร็จ',
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}

import { Fragment } from 'react'
import {
  Button,
  Flex,
  FormHelperText,
  FormLabel,
  Grid,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Link,
  Square,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { get } from 'lodash'
import { zodResolver } from '@hookform/resolvers/zod'

import { FormControl } from '@/components/Form'

import { ERROR_TOAST_OPTIONS } from '@/lib/constants'
import { CREATE_POLICY_URL } from '@/features/dashboard/constants'

import { RequestFormInput } from '@/features/forms/types'
import {
  baseRequestFormSchema,
  premiumRequestFormSchema,
} from '@/features/forms/constants/schema'

const maxAllowedSize = 100 * 1024

export type RequestFormProps = {
  canCustomizeSubDomain: boolean
  canCustomizeTheme: boolean
  defaultValues?: RequestFormInput
  formUrl?: string
  isFreePlan: boolean
  isLoading: boolean
  logoUrl?: string | null
  onSubmitForm: (data: RequestFormInput) => void
}

export const RequestForm = (props: RequestFormProps): React.ReactElement => {
  const {
    canCustomizeSubDomain,
    canCustomizeTheme,
    defaultValues,
    formUrl,
    isFreePlan,
    isLoading,
    logoUrl,
    onSubmitForm,
  } = props

  const isEditing = Boolean(defaultValues)
  const toast = useToast()

  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RequestFormInput>({
    defaultValues: defaultValues ?? { status: 'active' },
    resolver: zodResolver(
      isFreePlan ? baseRequestFormSchema : premiumRequestFormSchema,
    ),
  })

  const color = watch('color')
  const logo = watch('logo')
  const status = watch('status')

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmitForm(data)
      })}
    >
      <Stack spacing={5}>
        <FormControl
          label="ชื่อฟอร์ม"
          isRequired
          errorMsg={errors.name?.message}
        >
          <Input size="lg" {...register('name')} />
        </FormControl>

        {canCustomizeTheme && (
          <Fragment>
            <FormControl label="โลโก้">
              <FormLabel sx={{ cursor: 'pointer' }} id="logo-label">
                <Flex
                  sx={{
                    p: 2,
                    border: '1px dotted',
                    borderColor: 'gray.300',
                    h: '172px',
                    flexDir: 'column',
                  }}
                >
                  <Input
                    sx={{ d: 'none' }}
                    type="file"
                    name="logo"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      const file = get(e, 'target.files.0')
                      if (file === undefined) return

                      if (file.size <= maxAllowedSize) {
                        setValue('logo', file)
                      } else {
                        toast({
                          ...ERROR_TOAST_OPTIONS,
                          description: 'ขนาดไฟล์ใหญ่เกินไป',
                        })
                      }
                    }}
                  />

                  {(logo || logoUrl) && (
                    <Image
                      src={
                        logo ? window.URL.createObjectURL(logo) : logoUrl ?? ''
                      }
                      objectFit="contain"
                      sx={{ maxW: '100%', maxH: '100px' }}
                    />
                  )}
                  <Button
                    colorScheme={logo ? 'red' : 'brand'}
                    sx={{ mt: 'auto' }}
                    onClick={() => {
                      ;(
                        document.querySelector(
                          '#logo-label',
                        ) as HTMLLabelElement
                      )?.click()
                    }}
                  >
                    {logo ? 'อัปโหลดรูปภาพใหม่' : 'อัปโหลดโลโก้'}
                  </Button>
                </Flex>
                <FormHelperText>
                  ขนาดที่แนะนำ: ~ 172 x 40 px และไม่ควรเกิน 100 KB รองรับ .png
                  และ .jpg
                </FormHelperText>
              </FormLabel>
            </FormControl>

            <Grid
              sx={{
                gridTemplateColumns: '1fr 48px',
                gap: '10px',
              }}
            >
              <FormControl label="ธีมสี" errorMsg={errors.color?.message}>
                <InputGroup size="lg">
                  <InputLeftElement>#</InputLeftElement>
                  <Input
                    name="color"
                    value={color?.replace('#', '') ?? ''}
                    onChange={(e) => setValue('color', e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              <FormLabel
                sx={{
                  m: 0,
                  border: '1px solid',
                  borderColor: 'gray.300',
                  alignSelf: 'start',
                  pos: 'relative',
                  mt: 8,
                }}
              >
                <Square size="47px" style={{ backgroundColor: color }} />
                <input
                  type="color"
                  value={color?.replace('#', '') ?? ''}
                  onChange={(e) => setValue('color', e.currentTarget.value)}
                  style={{
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    visibility: 'hidden',
                  }}
                />
              </FormLabel>
            </Grid>
          </Fragment>
        )}

        {canCustomizeSubDomain && (
          <FormControl label="กำหนด URL ที่ต้องต้องการแสดง">
            <InputGroup size="lg">
              <Input {...register('slug')} />
              <InputRightAddon>{'.pdpaform.com'}</InputRightAddon>
            </InputGroup>
          </FormControl>
        )}

        <FormControl
          label="Terms & Conditions URL"
          isRequired
          errorMsg={errors.terms_url?.message}
        >
          <Input size="lg" {...register('terms_url')} />
        </FormControl>

        <FormControl
          label="Privacy Policy URL"
          isRequired
          errorMsg={errors.privacy_url?.message}
        >
          <Input size="lg" {...register('privacy_url')} />
          <FormHelperText>
            ยังไม่มี Policy ?&nbsp;
            <Link
              isExternal
              href={CREATE_POLICY_URL}
              sx={{ color: 'brand.400' }}
            >
              สร้างเลย
            </Link>
          </FormHelperText>
        </FormControl>

        {isEditing && (
          <FormControl
            sx={{
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormLabel mb="0">สถานะของฟอร์ม</FormLabel>

            <HStack spacing="1">
              <Text
                sx={{
                  color: 'gray.400',
                  fontSize: 'xs',
                  fontWeight: 'medium',
                }}
              >
                Active
              </Text>
              <Switch
                colorScheme="green"
                size="lg"
                isChecked={status === 'active'}
                onChange={(e) =>
                  setValue('status', e.target.checked ? 'active' : 'inactive')
                }
              />
            </HStack>
          </FormControl>
        )}

        <HStack spacing="5">
          <Button type="submit" size="lg" isLoading={isLoading} width="full">
            บันทึก
          </Button>

          {isEditing && (
            <Link isExternal href={formUrl}>
              <Button size="lg" variant="outline">
                ดูฟอร์ม
              </Button>
            </Link>
          )}
        </HStack>
      </Stack>
    </form>
  )
}

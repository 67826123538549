import { extendTheme } from '@chakra-ui/react'

import layerStyles from './theme/layerStyles'
import textStyles from './theme/textStyles'
import sizes from './theme/sizes'
import colors from './theme/colors'
import components from './components'

const theme = extendTheme({
  colors,
  layerStyles,
  textStyles,
  components,
  sizes,
  fonts: {
    heading:
      '"Noto Sans Thai", "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    body: '"Noto Sans Thai", "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
  },
})

export default theme

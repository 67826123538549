const Switch = {
  parts: ['thumb', 'track'],
  baseStyle: {
    thumb: {},
    track: {
      bg: '#ccc',
      boxShadow: 'none !important',
    },
  },
  sizes: {
    lg: {
      track: {
        padding: '0px',
        px: '4px',
        width: '2.25rem',
        height: '1.5rem',
        alignItems: 'center',
        _checked: {
          justifyContent: 'flex-end',
        },
      },
      thumb: {
        boxSize: '1rem',
        _checked: {
          transform: 'unset',
        },
      },
    },
    xl: {
      track: {
        padding: '8px',
        width: '64px',
        height: '32px',
        alignItems: 'center',
      },
      thumb: {
        boxSize: '32px',
        _checked: {
          transform: 'translateX(32px)',
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'green',
  },
}

export default Switch

import { useMemo } from 'react'

import {
  TaxpayerType,
  Package,
  RecurringType,
  RawPackage,
} from '@/features/dashboard/payments/types'

export const useTransformPackageData = (
  currentPackage: RawPackage,
  recurringType: RecurringType,
  addressType: TaxpayerType,
): Package => {
  return useMemo(() => {
    const recurringData = currentPackage.recurring[recurringType]
    const {
      vat,
      vat_percent,
      wht_percent,
      recurring_amount,
      discount = 0,
    } = recurringData

    const total = Math.max(recurring_amount - discount, 0)

    const transformedData: Package = {
      id: currentPackage.token,
      name: currentPackage.name,
      price: recurring_amount,
      net: recurring_amount,
      vat: vat,
      vatPercent: vat_percent,
      preVatTotal: recurring_amount - vat,
      withHoldingTaxPercent: wht_percent,
      total,
      recurringType,
      discount: discount
        ? [
            {
              label: 'ส่วนลดมูลค่าคงเหลือแพ็กเกจ',
              value: discount,
            },
          ]
        : undefined,
      ...(total === 0 && {
        notice:
          'ไม่มีค่าใช้จ่ายใดๆ เพิ่มเติม เนื่องจากการปรับลดแพ็กเกจของคุณครอบคลุมค่าใช้จ่ายทั้งหทด',
      }),
    }

    return transformedData
  }, [currentPackage, recurringType, addressType])
}

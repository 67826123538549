import { HStack, Icon, Text } from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi'

import { withPage } from '@/lib/layout/Application'

const QuotaExceededBanner = () => {
  return (
    <HStack
      sx={{
        py: 2,
        px: 3,
        bg: 'red.50',
        borderRadius: '8px',
        color: 'red.400',
        border: '1px solid',
        borderColor: 'red.400',
      }}
    >
      <Icon as={FiInfo} boxSize="20px" color="red.400" />
      <Text sx={{ transform: 'translateY(1px)' }}>
        เนื่องจากข้อจำกัดของแพ็กเกจ ทำให้คุณไม่สามารถใช้งานได้ขณะนี้ กรุณาติดต่อ
        Owner ของคุณเพื่อรับสิทธิเข้าถึงการใช้งาน
      </Text>
    </HStack>
  )
}

export default withPage(QuotaExceededBanner)

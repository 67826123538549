import { CSSObject } from '@chakra-ui/react'

export const tableHeadStyle: CSSObject = {
  p: '8px 12px',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'gray.300',
  color: 'gray.600',
  letterSpacing: 'normal',
}

export const tableCellStyle: CSSObject = {
  p: 3,
}

export {}

declare global {
  interface String {
    toTitleCase(): string
    format(...args: any[]): string
  }

  interface Number {
    clamp(min: number, max: number): number
  }

  interface Window {
    dataLayer?: object[]
  }
}

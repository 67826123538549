import { Link, Text, VStack } from '@chakra-ui/react'

import { withAuthPage } from '@/lib/layout/Application'
import { logEvent } from '@/lib/stats'

import { AuthLayout, RegisterForm } from '../../components'

import { LOGIN_PATH, POLICIES_PATH, TERMS_PATH } from '../../constants'
import { Flash, RegisterFormInput } from '../../types'

type RegisterPageProps = {
  formActionPath: string
  flash: Flash
  resource: RegisterFormInput
  errors: Partial<Record<keyof RegisterFormInput, string[]>>
}

function RegisterPage(props: RegisterPageProps) {
  const { formActionPath, flash, resource, errors } = props

  return (
    <AuthLayout
      title="เริ่มแบบฟอร์มของคุณภายในหนึ่งนาที"
      flash={flash}
      subtitle={
        <Text sx={{ fontWeight: 500, color: 'gray.600' }}>
          ทดลองใช้ฟรี - ติดตั้งง่าย - ยกเลิกได้ตลอดเวลา
        </Text>
      }
      footer={
        <VStack spacing="4" sx={{ fontWeight: 500 }}>
          <Text>
            มีบัญชีอยู่แล้ว?&nbsp;
            <Link
              href={LOGIN_PATH}
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Sign up page',
                    action: 'Click sign in',
                  },
                })
              }}
            >
              เข้าสู่ระบบ
            </Link>
          </Text>
        </VStack>
      }
    >
      <RegisterForm
        formActionPath={formActionPath}
        defaultValues={resource}
        errors={errors}
      />
    </AuthLayout>
  )
}

export default withAuthPage(RegisterPage)

import {
  Box,
  Circle,
  Grid,
  Icon,
  Input,
  InputProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import { Fragment, useRef } from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'

import { hints } from './constants'
import { passwordSchema } from './schema'

type PasswordInputProps = {} & InputProps

export const PasswordInput = (
  props: PasswordInputProps,
): React.ReactElement => {
  const { value = '', onChange, ...rest } = props

  const [show, setShow] = useBoolean()
  const initialFocusRef = useRef<HTMLInputElement>(null)
  const validateList = passwordSchema.validate(value, { list: true })

  return (
    <Box>
      <Popover
        isOpen={show}
        initialFocusRef={initialFocusRef}
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <Input
            type="password"
            onBlur={setShow.off}
            onFocus={setShow.on}
            ref={initialFocusRef}
            value={value}
            onChange={onChange}
            {...rest}
          />
        </PopoverTrigger>
        <PopoverContent
          sx={{
            border: 'none',
            boxShadow: '0px 0px 10px rgba(151, 151, 151, 0.25)',
          }}
        >
          <PopoverArrow
            sx={{
              boxShadow: '0px 0px 10px rgba(151, 151, 151, 0.25) !important',
            }}
          />
          <PopoverBody
            sx={{
              textAlign: 'start',
              fontSize: 'sm',
              p: '12px 16px',
              bg: 'white',
              borderRadius: '12px',
            }}
          >
            <Stack>
              <Text>รหัสผ่านต้องประกอบด้วย:</Text>
              {Object.keys(hints).map((hintKey) => {
                const isChecked = !validateList.includes(hintKey)

                return (
                  <HintItem
                    key={hints[hintKey]}
                    isChecked={isChecked}
                    text={
                      <Text
                        sx={{
                          lineHeight: '20px',
                          transition: 'color 0.2s',
                          color: isChecked ? '#00c4ab' : undefined,
                        }}
                      >
                        {hintKey === 'symbols' ? (
                          <Fragment>
                            {hints[hintKey]}
                            <br />
                            <Text as="span" sx={{ ml: '2px' }}>
                              (-!$%@^&amp;*#()_+|~=`{}
                              []:\&quot;;&#39;&lt;&gt;?,./)
                            </Text>
                          </Fragment>
                        ) : (
                          hints[hintKey]
                        )}
                      </Text>
                    }
                  />
                )
              })}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

type HintItemProps = {
  isChecked: boolean
  text: React.ReactElement
}

const HintItem = (props: HintItemProps): React.ReactElement => {
  const { isChecked, text } = props

  return (
    <Grid
      sx={{
        gridTemplateColumns: '16px 1fr',
        gap: '4px',
      }}
    >
      <Box sx={{ height: '16px' }}>
        {isChecked ? (
          <Icon as={BsCheckCircleFill} fill="#00C4A8" fontSize="16px" />
        ) : (
          <Circle
            sx={{ boxSize: '16px', border: '1px solid #c4c4c4', mt: '2px' }}
          />
        )}
      </Box>
      {text}
    </Grid>
  )
}

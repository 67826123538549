import { Link, Text, VStack } from '@chakra-ui/react'

import { AuthLayout, ForgotPasswordForm } from '../../components'
import { LOGIN_PATH, POLICIES_PATH, TERMS_PATH } from '../../constants'

type ForgotPasswordPageProps = {
  formActionPath: string
}

function ForgotPasswordPage(props: ForgotPasswordPageProps) {
  const { formActionPath } = props

  return (
    <AuthLayout
      title="ลืมรหัสผ่าน?"
      subtitle={
        <Text sx={{ fontWeight: 500 }}>
          กรอกอีเมลด้านล่างเพื่อรีเซ็ตรหัสผ่านของคุณ
        </Text>
      }
      footer={
        <VStack spacing="4" sx={{ fontWeight: 500 }}>
          <Text>
            มีบัญชีอยู่แล้ว?&nbsp;
            <Link href={LOGIN_PATH}>เข้าสู่ระบบ</Link>
          </Text>

          <Text>
            <Link isExternal href={TERMS_PATH}>
              ข้อตกลงและเงื่อนไข
            </Link>
            &nbsp;และ&nbsp;
            <Link isExternal href={POLICIES_PATH}>
              นโยบายความเป็นส่วนตัว
            </Link>
          </Text>
        </VStack>
      }
    >
      <ForgotPasswordForm formActionPath={formActionPath} />
    </AuthLayout>
  )
}

export default ForgotPasswordPage

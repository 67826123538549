import { useToast } from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { useErrorHandler } from '@/lib/hooks'
import { fetchAPI } from '@/lib/helpers/api'

import { PasswordExpirationFormInput } from '../types'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants'

type Payload = {
  data: PasswordExpirationFormInput
}

export const useUpdatePasswordExpiration = () => {
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    ({ data }) => {
      return fetchAPI({
        path: `/users/password_expiration`,
        method: 'put',
        data,
      })
    },
    {
      onSuccess() {
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'เปลี่ยนแปลงนโยบายเปลี่ยนรหัสผ่านสำเร็จ',
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}

import { useState } from 'react'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'

import { Button, Heading, useToast } from '@chakra-ui/react'

import { axios, getErrorMsg } from '@/lib/helpers'
import Modal from '@/components/molecules/Modal'

import { CheckPasswordForm } from './CheckPasswordForm'
import { ChangeMailForm } from './ChangeMailForm'
import { SentMailConfirm } from './SentMailConfirm'
import {
  DEFAULT_TOAST_OPTIONS,
  ERROR_TOAST_OPTIONS,
} from '@/lib/constants/toast'

interface Props {
  modalData: { isActive: boolean; newEmail: string }
  modalDataSet: (data: { isActive: boolean; newEmail: string }) => void
  user: {
    marketing_consent: boolean
    email: string
    first_name: string
    last_name: string
    telephone: string
  }
  isReauthentication: boolean
}

export const ChangeEmailModal = (props: Props) => {
  const { modalData, modalDataSet, user, isReauthentication } = props
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const maxStep = 2

  const {
    register: registerPWD,
    getValues: getValuesPWD,
    reset: resetPWD,
    handleSubmit: handleSubmitPWD,
  } = useForm({
    defaultValues: {
      password: '',
    },
  })

  const {
    register: registerEmail,
    getValues: getValuesEmail,
    reset: resetEmail,
    handleSubmit: handleSubmitEmail,
  } = useForm({
    defaultValues: {
      old: get(user, 'email'),
      new: '',
    },
  })

  const { handleSubmit: handleSubmitConfirm } = useForm()

  const toast = useToast()

  const checkError = async (func: () => void, successMsg: string) => {
    setLoading(true)
    try {
      await func()
      toast({
        description: successMsg,
        ...DEFAULT_TOAST_OPTIONS,
      })
      if (step !== maxStep) {
        setStep(step + 1)
      }
      const newEmail = getValuesEmail('new')
      modalDataSet({ ...modalData, newEmail })
    } catch (err: any) {
      toast({
        title: getErrorMsg(err),
        ...ERROR_TOAST_OPTIONS,
      })
    } finally {
      setLoading(false)
    }
  }

  function postSecurity() {
    const password = getValuesPWD('password')
    return axios.post('/users/securities', { password })
  }

  function checkPwd() {
    checkError(postSecurity, 'validated password')
  }

  function putEmail() {
    const email = getValuesEmail('new')
    return axios.put('/users/email', { admin: { email } })
  }
  function changeEmail() {
    checkError(putEmail, 'เปลี่ยนแปลงอีเมลสำเร็จ!')
  }

  function discard() {
    modalDataSet({
      ...modalData,
      isActive: false,
    })
    setStep(0)
    resetEmail()
    resetPWD()
  }

  const formState = [
    {
      component: (
        <CheckPasswordForm
          isReauthentication={isReauthentication}
          handleSubmitPWD={handleSubmitPWD}
          registerPWD={registerPWD}
          checkPwd={checkPwd}
          loading={loading}
          setStep={setStep}
        />
      ),
    },
    {
      component: (
        <ChangeMailForm
          handleSubmitEmail={handleSubmitEmail}
          changeEmail={changeEmail}
          registerEmail={registerEmail}
          loading={loading}
        />
      ),
    },
    {
      component: (
        <SentMailConfirm
          handleSubmitConfirm={handleSubmitConfirm}
          getValuesEmail={getValuesEmail}
          loading={loading}
          discard={discard}
        />
      ),
    },
  ]

  return (
    <Modal modalData={modalData} modalDataSet={modalDataSet}>
      {step < maxStep && (
        <Heading as="h3" sx={{ pb: 0, fontSize: '24px', mb: '24px' }}>
          เปลี่ยนอีเมล
        </Heading>
      )}
      {formState[step].component}
      {step < maxStep && (
        <Button
          color="gray.600"
          colorScheme="white"
          type="submit"
          sx={{ width: '100%', height: '64px' }}
          onClick={() => discard()}
        >
          ยกเลิก
        </Button>
      )}
    </Modal>
  )
}

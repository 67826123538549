import { Plan } from '@/features/dashboard/packages/types'

import { createCtx } from '@/lib/helpers'

type AppContextValue = {
  currentPackage: Plan
  isFreePlan: boolean
}

export const [useAppContext, AppContextProvider] = createCtx<AppContextValue>()

import { Text } from '@chakra-ui/react'
import { useSpring } from 'react-spring'

import { SpringHStack } from '@/components/atoms/shared'

type SelectCounterProps = {
  current: number
  quota: number
}

export const SelectCounter = (props: SelectCounterProps) => {
  const { current, quota } = props

  const styles = useSpring({
    opacity: current ? 1 : 0,
    top: current ? '-24px' : '-48px',
  })

  return (
    <SpringHStack
      style={styles}
      sx={{
        bg: 'brand.400',
        borderRadius: 'full',
        color: 'white',
        justifyContent: 'flex-end',
        pos: 'absolute',
        pl: current >= 10 ? 3 : 4,
        pr: '14px',
        py: 1,
        pt: '5px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      spacing="1"
    >
      <Text>
        {current}/{quota}
      </Text>
      <Text>Selected</Text>
    </SpringHStack>
  )
}

export enum TaxpayerType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export type RawAddress = {
  id: string
  primary?: boolean
  title: string
  details: string
  taxpayer_id: string
  taxpayer_type: TaxpayerType
}

export type _RawAddress = {
  attributes?: Omit<RawAddress, 'id'>
} & RawAddress

export type Address = {
  id: string
  primary?: boolean
  name: string
  address: string
  taxId: string
  taxPayerType: TaxpayerType
}

import { HStack, Icon, Text } from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi'

import { PackageSize } from '@/features/dashboard/packages/types'
import { MigrationMode } from '../types'

type MigrationNoticeBannerProps = {
  mode: MigrationMode
  packageName: PackageSize
  quota: number
}

export const MigrationNoticeBanner = (props: MigrationNoticeBannerProps) => {
  const { mode, packageName, quota } = props

  const renderText = () => {
    if (mode === 'admin') {
      return `ปัจจุบันคุณใช้ ${packageName.toTitleCase()} สามารถมีผู้ใช้งานได้สูงสุด ${quota} คน กรุณาเลือกผู้ใช้งานที่จะสามารถใช้แพลตฟอร์มนี้ได้`
    }

    return `ปัจจุบันคุณใช้ ${packageName.toTitleCase()} สามารถมีแบบฟอร์มรับคำร้องได้สูงสุด ${quota} ฟอร์ม กรุณาเลือกแบบฟอร์มรับคำร้องที่จะสามารถใช้งานต่อได้`
  }

  return (
    <HStack
      sx={{
        py: 2,
        px: 3,
        bg: 'brand.50',
        borderRadius: '8px',
        color: 'brand.400',
      }}
      spacing="2"
    >
      <Icon as={FiInfo} boxSize="20px" />
      <Text sx={{ transform: 'translateY(1px)' }}>{renderText()}</Text>
    </HStack>
  )
}

import { createContext, useContext } from 'react'
import { get } from 'lodash'
import { AxiosError } from 'axios'
import { UseToastOptions } from '@chakra-ui/react'
import { getContrast } from 'polished'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants/toast'

export const noop = () => {}

export const getErrorMsg = (error: AxiosError) => {
  let errorText = 'Something Wrong'

  if (error.response) {
    if (
      typeof error.response.data === 'string' &&
      !error.response.data.startsWith('<!DOCTYPE')
    ) {
      errorText = error.response.data
    }

    const errors = get(error, 'response.data.errors', [])
    if (errors.length > 0) {
      errorText = errors[0]
    }

    const errorMsg = get(error, 'response.data.error', '')
    if (errorMsg) {
      errorText = errorMsg
    }
  } else if (error.request) {
    errorText = 'Network Error'
  }

  return `${errorText} (${error.response?.status})`
}

export const getErrorToastObject = (error: AxiosError): UseToastOptions => {
  return {
    ...DEFAULT_TOAST_OPTIONS,
    description: getErrorMsg(error),
    status: 'error',
  }
}

export function createCtx<T extends {} | null>() {
  const ctx = createContext<T | undefined>(undefined)

  function useCtx<K = {}>() {
    const c = useContext(ctx)

    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value')
    }

    return c as T & K
  }

  return [useCtx, ctx.Provider, ctx] as const
}

export const readableColor = (
  baseColor: string,
  ifLightColor: string,
  ifDarkColor: string,
): string => {
  const contrastRatio = getContrast(baseColor, '#fff')

  if (contrastRatio > 2.2) {
    return ifLightColor
  }

  return ifDarkColor
}

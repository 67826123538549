import { RequestForm } from '../components/RequestForm/RequestForm'
import { useAppContext, withPage } from '@/lib/layout/Application'

import { useUpdateRequestForm } from '../mutations'
import { RequestFormInput } from '@/features/forms/types'

type Props = {
  form: {
    data: {
      attributes: RequestFormInput & {
        token: string
        logo_url: string | null
      }
      id: string
      type: 'form'
    }
  }
  formUrl: string
  customization: {
    domain: boolean
    subdomain: boolean
    theme: boolean
  }
}

const DashboardFormsShow = ({ form, formUrl, customization }: Props) => {
  const { mutate: updateForm, isLoading } = useUpdateRequestForm()

  const { isFreePlan } = useAppContext()

  return (
    <div className="DashboardSettingsShow">
      <div className="container">
        <div className="Header">
          <h2 className="Header__h2">
            <span className="Header__breadcrumb__a">แก้ไขฟอร์ม</span>
          </h2>
        </div>

        <div className="DashboardSettingsShow__body">
          <div className="DashboardSettingsShow__body__top">
            <RequestForm
              isFreePlan={isFreePlan}
              defaultValues={form.data.attributes}
              formUrl={formUrl}
              canCustomizeSubDomain={customization.subdomain}
              canCustomizeTheme={customization.theme}
              isLoading={isLoading}
              logoUrl={form.data.attributes.logo_url}
              onSubmitForm={(data) => {
                updateForm({
                  data,
                  params: {
                    token: form.data.attributes.token,
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withPage(DashboardFormsShow)

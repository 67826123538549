import { Button, Checkbox, Input, Stack } from '@chakra-ui/react'

import { FormControl } from '@/components/Form'

import { getAuthenticityToken } from '../helpers'

type LoginFormProps = {
  formActionPath: string
}

export const LoginForm = (props: LoginFormProps) => {
  const { formActionPath } = props

  const authenticityToken = getAuthenticityToken()

  return (
    <form action={formActionPath} acceptCharset="UTF-8" method="post">
      <Stack spacing="6">
        <FormControl isRequired>
          <Input
            autoComplete="email"
            autoFocus
            id="admin_email"
            isRequired
            name="admin[email]"
            placeholder="อีเมล"
            size="lg"
            type="email"
          />
        </FormControl>
        <FormControl isRequired>
          <Input
            autoComplete="current-password"
            id="admin_password"
            isRequired
            name="admin[password]"
            placeholder="รหัสผ่าน"
            size="lg"
            type="password"
          />
        </FormControl>
        <Input
          value={authenticityToken}
          type="hidden"
          name="authenticity_token"
        />

        <Checkbox id="admin_remember_me" value="1" name="admin[remember_me]">
          ให้ฉันอยู่ในระบบ
        </Checkbox>

        <Button type="submit" size="xl">
          เข้าสู่ระบบ
        </Button>
      </Stack>
    </form>
  )
}

import { Fragment } from 'react'

import { RequestForm } from '../components/RequestForm/RequestForm'
import { useAppContext, withPage } from '@/lib/layout/Application'

import { useCreateForm } from '../mutations'

type Props = {
  customization: {
    domain: boolean
    subdomain: boolean
    theme: boolean
  }
}

const DashboardFormsNew = ({ customization }: Props) => {
  const { mutate: createForm, isLoading } = useCreateForm()
  const { isFreePlan } = useAppContext()

  return (
    <Fragment>
      <div className="DashboardSettingsShow">
        <div className="container">
          <div className="Header">
            <h2 className="Header__h2">
              <span className="Header__breadcrumb__a">สร้างฟอร์มใหม่</span>
            </h2>
          </div>

          <div className="DashboardSettingsShow__body">
            <div className="DashboardSettingsShow__body__top">
              <RequestForm
                isFreePlan={isFreePlan}
                isLoading={isLoading}
                onSubmitForm={(data) => {
                  createForm({ data })
                }}
                canCustomizeSubDomain={customization.subdomain}
                canCustomizeTheme={customization.theme}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withPage(DashboardFormsNew)

import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
} from 'react'
import {
  Button,
  ModalCloseButton,
  ModalHeader,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'

import Modal from '@/components/atoms/Modal'
import { UnsubReasonForm } from '../UnsubReasonForm'

import { MODAL_DATA, ModalDatum } from '@/features/dashboard/packages/constants'
import { getSubscriptionType } from '@/features/dashboard/packages/helpers'
import { useCheckoutServices } from '@/features/dashboard/payments/services'

import {
  Plan,
  PackageDetail,
  PackageTokens,
  SubscriptionType,
  UnsubReasonFormInput,
} from '@/features/dashboard/packages/types'

export interface RefProps {
  plan: Plan | null
  setPlan: (plan: Plan | null) => void
}

interface Props {
  summariesUrl: string
  packageDetail: PackageDetail
  packageTokens: PackageTokens
  expiry: string
}

function SubscriptionModal(
  { packageDetail, packageTokens, summariesUrl, expiry }: Props,
  ref: ForwardedRef<RefProps>,
) {
  const [plan, setPlan] = useState<Plan | null>(null)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const initialRef = useRef(null)
  const checkoutServices = useCheckoutServices()
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    if (plan && !isOpen) {
      onOpen()
    } else {
      setShowForm(false)
      onClose()
    }
  }, [plan])

  useEffect(() => {
    if (!isOpen) {
      setPlan(null)
    }
  }, [isOpen])

  useImperativeHandle(
    ref,
    () => ({
      plan,
      setPlan,
    }),
    [plan],
  )

  const handleSubscribe = () => {
    if (!plan) return

    window.location.href = `${summariesUrl}?package=${packageTokens[plan.plan]}`
  }

  const handleShowForm = () => {
    if (!plan) return

    setShowForm(true)
  }

  const handleUnsubscribe = (data: UnsubReasonFormInput) => {
    if (!plan) return

    checkoutServices.unsubscribe({
      data: {
        reason: data.reason_input || data.reason,
      },
    })
  }

  const handleResubscribe = () => {
    checkoutServices.resubscribe()
  }

  let modalData: ModalDatum | null = null
  let currentSubscription: SubscriptionType = 'unsubscribe'
  if (plan) {
    currentSubscription = getSubscriptionType(packageDetail, plan)

    modalData = MODAL_DATA[currentSubscription](plan, expiry)
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      contentSX={{
        width: '100%',
        maxW: '543px',
      }}
    >
      <ModalCloseButton sx={{ top: '16px' }} />

      {modalData && (
        <VStack
          spacing="4"
          sx={{
            px: 8,
            py: 4,
            alignItems: 'stretch',
            borderTop: '8px solid',
            borderColor: 'primary.400',
          }}
        >
          <ModalHeader sx={{ textAlign: 'center', fontWeight: 600, px: 0 }}>
            {showForm ? (
              <Text as="h1" sx={{ fontSize: '3xl', textAlign: 'center' }}>
                เราเสียใจเป็นอย่างยิ่ง
                <br />
                ที่คุณต้องการยกเลิกบริการจากเรา
              </Text>
            ) : Array.isArray(modalData.title) ? (
              modalData.title.map((title) => title)
            ) : (
              modalData.title
            )}
          </ModalHeader>

          {showForm ? (
            <UnsubReasonForm
              isLoading={checkoutServices.isRequesting}
              onSubmit={handleUnsubscribe}
            />
          ) : Array.isArray(modalData.description) ? (
            modalData.description.map((description) => description)
          ) : (
            modalData.description
          )}

          <VStack
            spacing="4"
            sx={{ alignItems: 'stretch', pt: showForm ? 0 : 4 }}
          >
            {!showForm && (
              <Button
                size="lg"
                ref={initialRef}
                onClick={
                  currentSubscription === 'resubscribe'
                    ? handleResubscribe
                    : currentSubscription === 'unsubscribe'
                    ? handleShowForm
                    : handleSubscribe
                }
              >
                {currentSubscription === 'unsubscribe' && !showForm
                  ? 'ต่อไป'
                  : modalData.buttonLabel}
              </Button>
            )}
            <Button
              variant="ghost"
              size="lg"
              colorScheme="gray"
              onClick={onClose}
            >
              ยกเลิก
            </Button>
          </VStack>
        </VStack>
      )}
    </Modal>
  )
}

export default forwardRef(SubscriptionModal)

import { AxiosResponse } from 'axios'

import { axios } from '@/lib/helpers'
import { fetchAPI } from '@/lib/helpers/api'

import {
  CheckoutSetupProps,
  CheckoutProps,
} from '@/features/dashboard/payments/types'
import { UnsubscriptionPayload } from '../../packages/types'

const baseUrl = '/payments'

async function setup({
  data,
}: {
  data: CheckoutSetupProps
}): Promise<AxiosResponse> {
  return axios.post(`${baseUrl}/summaries`, data)
}

async function subscribe({
  data,
}: {
  data: CheckoutProps
}): Promise<AxiosResponse> {
  return axios.post(`${baseUrl}/credit_cards/recurrings`, data)
}

async function unsubscribe({
  data,
}: UnsubscriptionPayload): Promise<AxiosResponse> {
  return fetchAPI({
    path: '/packages/cancel',
    method: 'delete',
    data,
  })
}

export function resubscribe(): Promise<AxiosResponse> {
  return fetchAPI({
    path: '/packages/auto_renew',
    method: 'put',
  })
}

export default {
  baseUrl,
  resubscribe,
  setup,
  subscribe,
  unsubscribe,
}

import { useQuery } from 'react-query'

import { fetchAPI } from '@/lib/helpers/api'

import { DASHBOARD_STATS } from '../constants'
import { DashboardStats } from '../types'

export const useDashboardStats = () => {
  return useQuery<DashboardStats>({
    queryKey: [DASHBOARD_STATS],
    async queryFn() {
      const response = await fetchAPI<{ data: DashboardStats }>({
        path: '/dashboard',
      })

      const data = response.data.data

      return data
    },
    placeholderData,
  })
}

const placeholderData: DashboardStats = {
  all: 0,
  completed: 0,
  request_type_count: {
    correct: 0,
    forgotten: 0,
    know_how: 0,
    object: 0,
    stop_process: 0,
    stop_using: 0,
    transfer: 0,
  },
}

import { Button, Icon, Link, Text, VStack } from '@chakra-ui/react'
import { HiCheckCircle } from 'react-icons/hi'

import { AuthLayout } from '../../components'
import { LOGIN_PATH } from '../../constants'

function ForgotSuccessPage() {
  return (
    <AuthLayout plainLayout>
      <VStack spacing="4" sx={{ p: '56px 0 80px' }}>
        <Icon as={HiCheckCircle} sx={{ boxSize: '100px', color: '#6AC761' }} />
        <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
          ส่งลิงก์เปลี่ยนรหัสผ่านใหม่เรียบร้อยแล้ว
        </Text>
        <Text sx={{ fontWeight: 600, fontSize: '16px' }}>
          โปรดตรวจสอบกล่องจดหมายของคุณสำหรับคำแนะนำเกี่ยวกับวิธีตั้งค่ารหัสผ่านใหม่
        </Text>
        <Link href={LOGIN_PATH}>
          <Button size="xl">กลับไปหน้าเข้าสู่ระบบ</Button>
        </Link>
      </VStack>
    </AuthLayout>
  )
}

export default ForgotSuccessPage

import { useToast } from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/helpers/api'
import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants'

type Payload = {
  data: {
    adminsIds: number[]
  }
}

export const useDeleteAdmins = () => {
  const toast = useToast()

  return useMutation<AxiosResponse, AxiosError, Payload>(
    ({ data }) => {
      return fetchAPI({
        path: '/downgrade_conflicts',
        method: 'delete',
        data: {
          except_admins: data.adminsIds,
        },
      })
    },
    {
      onSuccess() {
        toast({ ...DEFAULT_TOAST_OPTIONS })
        window.location.href = `/requests`
      },
    },
  )
}

import { Button, Input, Stack } from '@chakra-ui/react'
import { useState } from 'react'

import { FormControl } from '@/components/Form'
import { PasswordInput } from './PasswordInput'

import { getAuthenticityToken } from '../helpers'

import { AuthFormProps, ResetPasswordFormInput } from '../types'

type ResetPasswordFormProps = AuthFormProps & {
  resetPasswordToken: string
  errors: Partial<Record<keyof ResetPasswordFormInput, string[]>>
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { formActionPath, resetPasswordToken, errors } = props

  const authenticityToken = getAuthenticityToken()

  const [password, setPassword] = useState('')

  return (
    <form action={formActionPath} acceptCharset="UTF-8" method="post">
      <input type="hidden" name="_method" value="put" autoComplete="off" />
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input
        autoComplete="off"
        type="hidden"
        value={resetPasswordToken}
        name="admin[reset_password_token]"
        id="admin_reset_password_token"
      />

      <Stack spacing="6">
        <FormControl isRequired errorMsg={errors.password?.[0]}>
          <PasswordInput
            autoFocus
            autoComplete="new-password"
            placeholder="รหัสผ่านใหม่"
            name="admin[password]"
            id="admin_password"
            size="lg"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </FormControl>

        <FormControl isRequired errorMsg={errors.password_confirmation?.[0]}>
          <Input
            autoComplete="new-password"
            placeholder="ยืนยันรหัสผ่านใหม่"
            type="password"
            name="admin[password_confirmation]"
            id="admin_password_confirmation"
            size="lg"
          />
        </FormControl>

        <Button type="submit" size="xl">
          เปลี่ยนรหัสผ่านของฉัน
        </Button>
      </Stack>
    </form>
  )
}

import { Fragment } from 'react'

import { termsAndPrivacyIndexes } from '../constants'

export const renderTemplate = (key: string) => {
  return (
    <Fragment>
      {termsAndPrivacyIndexes[key].subMenus.map(
        ({ title, content, scrollTo, useH3 }) => (
          <section key={scrollTo} id={scrollTo}>
            {useH3 ? <h3>{title}</h3> : <h2>{title}</h2>}
            {content && typeof content === 'object' ? (
              content
            ) : (
              <p>{content}</p>
            )}
          </section>
        ),
      )}
    </Fragment>
  )
}

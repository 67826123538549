import th from '@/icons/flags/th.svg'
import en from '@/icons/flags/en.svg'

import { FormLanguage } from '@/features/forms/types'

export const LANGUAGE_CONSTANT: Record<
  FormLanguage,
  {
    icon: any
    shortLabel: string
    label: string
    value: FormLanguage
  }
> = {
  en: {
    icon: en,
    shortLabel: 'EN',
    label: 'English',
    value: 'en',
  },
  th: {
    icon: th,
    shortLabel: 'TH',
    label: 'ภาษาไทย',
    value: 'th',
  },
} as const

export const LANGUAGE_OPTIONS = Object.values(LANGUAGE_CONSTANT)

import { withPage } from '@/lib/layout/Application'
import { DashboardNavigation } from '../../components'

import { invoicesPackagesMenu } from '../../constants'
import { Transaction } from '../types'
import { formatDataTime } from '@/lib/helpers'

type Props = {
  transactions: Transaction[]
}

function InvoicesDesktopPage(props: Props) {
  const { transactions } = props

  return (
    <div className="DashboardInvoicesIndex">
      <div className="container">
        <div className="Header">
          <h2 className="Header__h2">
            <span className="Header__breadcrumb__a">
              วิธีการชำระเงินและเรียกเก็บเงิน
            </span>
          </h2>
        </div>

        <DashboardNavigation menus={invoicesPackagesMenu} />

        <div className="Table">
          <div className="Table__Row">
            {['วันที่ทำรายการ', 'จำนวนเงิน', 'สถานะการชำระ'].map((label) => {
              return (
                <div className="Table__col Table__col--header" key={label}>
                  {label}
                </div>
              )
            })}
          </div>
          {transactions.map((tx) => {
            return (
              <div className="Table__Row" key={tx.created_at}>
                <div className="Table__col">
                  {formatDataTime(tx.created_at, 'dd MMM HH:mm')}
                </div>
                <div className="Table__col">
                  {tx.amount.toLocaleString() + ' บาท'}
                </div>
                <div className="Table__col">
                  <span
                    className={`Status ${
                      tx.paid ? 'Status--verified' : 'Status--pending'
                    }`}
                  >
                    {tx.paid ? 'ชำระแล้ว' : 'รอการตรวจสอบ'}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default withPage(InvoicesDesktopPage)

const primary = {
  50: '#ebf3ff',
  100: '#b1d1ff',
  200: '#80b2ff',
  300: '#4f93fb',
  400: '#2879f9',
  500: '#2879f9',
  600: '#065adf',
  700: '#0046af',
  800: '#00327e',
  900: '#001e4e',
  1000: '#000a20',
}

const gray = {
  50: '#f8f8f8',
  100: '#f6f6f6',
  200: '#ebebeb',
  300: '#e8e8e8',
  350: '#cccccc',
  400: '#b8b8b8',
  450: '#b2b2b2',
  500: '#a3a3a3',
  600: '#888888',
  700: '#474747',
  800: '#3d3d3d',
  900: '#2b2b2b',
}

const green = {
  50: '#E7F8BA',
  100: '#E3F08E',
  200: '#D6EA61',
  300: '#C7E434',
  400: '#9dd216',
  500: '#91B017',
  600: '#7B9513',
  700: '#657A10',
  800: '#505F0C',
  900: '#3A4409',
}

const brown = {
  50: '#F3E8DD',
  100: '#EAD7C3',
  200: '#E0C6A8',
  300: '#D7B58E',
  400: '#cea473',
  500: '#C18B4D',
  600: '#A37139',
  700: '#7D562C',
  800: '#573C1F',
  900: '#312211',
}

const blue = {
  50: '#CCF2FF',
  100: '#99E4FF',
  200: '#66D4FF',
  300: '#33C2FF',
  400: '#00b1ff',
  500: '#0096D6',
  600: '#007CAD',
  700: '#006185',
  800: '#00435C',
  900: '#002533',
}

const red = {
  50: '#FDD3D3',
  100: '#FCBBBB',
  200: '#FBA2A2',
  300: '#FA8A8A',
  400: '#f97272',
  500: '#F74040',
  600: '#F50F0F',
  700: '#C90808',
  800: '#980606',
  900: '#670404',
}

const switchColor = {
  50: '#00DEA9',
  100: '#00DEA9',
  200: '#00DEA9',
  300: '#00DEA9',
  400: '#00DEA9',
  500: '#00DEA9',
  600: '#00DEA9',
  700: '#00DEA9',
  800: '#00DEA9',
  900: '#00DEA9',
}

const colors = {
  brand: primary,
  primary,
  gray,
  green,
  brown,
  blue,
  red,
  switch: switchColor,
}

colors.brand = colors.primary

export default colors

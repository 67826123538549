import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  Stack,
  PaymentProvider,
  Text,
  useToast,
} from '@datawowio/payment-ui'

import { axios } from '@/lib/helpers'
import { PAYMENT_PROJECT } from '@/features/dashboard/payments/constants'

const LinkAction = (props) => {
  const { path, data, className, method, label, confirmModal = {} } = props

  const { title, description, buttonLabel, buttonColor } = confirmModal

  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const handleRequest = async () => {
    try {
      await axios[method](path, data)
      window.location.reload()
    } catch (err) {
      toast({
        status: 'error',
        position: 'top-right',
        title: 'Error',
        description: get(err, 'response.data.errors'),
      })
    }
  }

  return (
    <Fragment>
      <a
        className={className}
        onClick={() => {
          if (isEmpty(confirmModal)) {
            handleRequest()
          } else {
            onOpen()
          }
        }}
      >
        {label}
      </a>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent maxW="524px" borderRadius="4px">
          <ModalHeader fontSize="24px">{title}</ModalHeader>

          <ModalCloseButton />

          <ModalBody p="20px">
            <Text mb="16px" fontFamily="Noto Sans">
              {description}
            </Text>
            <Stack>
              <Button
                h="64px"
                bg={buttonColor}
                color="white"
                onClick={handleRequest}
              >
                {buttonLabel}
              </Button>

              <Button h="64px" variant="ghost" onClick={onClose}>
                ยกเลิก
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

LinkAction.propTypes = {
  method: PropTypes.oneOf(['patch', 'put', 'delete']),
}

const withThemeProvider = (Component) => {
  return function EnhancedComponent(props) {
    return (
      <PaymentProvider
        project={{
          ...PAYMENT_PROJECT,
          email: props.email || '',
        }}
        theme={{}}
      >
        <Component {...props} />
      </PaymentProvider>
    )
  }
}

export default withThemeProvider(LinkAction)

import { Box, Text, VStack, Grid, Image, Flex } from '@chakra-ui/react'

import bgImg from '@/images/bg-accesstodashboard.png'
import logo from '@/images/dwpdpa-logo-new.svg'

import { withAuthPage } from '@/lib/layout/Application'

import { Span } from '@/components/atoms/shared'
import { FlashMessage } from './FlashMessage'

import { Flash } from '../types'
import { Fragment } from 'react'

type AuthLayoutProps = {
  children: React.ReactNode
  title?: string
  subtitle?: React.ReactNode
  footer?: React.ReactNode
  flash?: Flash
  plainLayout?: boolean
}

const AuthLayout = (props: AuthLayoutProps) => {
  const {
    children,
    title,
    subtitle,
    footer,
    flash,
    plainLayout = false,
  } = props

  return (
    <Box
      sx={{
        w: '100vw',
        h: '100vh',
        bgRepeat: 'no-repeat',
        bgSize: 'cover',
        bgPosition: 'center',
        bgImage: `url("${bgImg}")`,
        a: {
          color: 'brand.400',
        },
      }}
    >
      <Grid sx={{ maxW: '800px', px: 6, mx: 'auto', h: 'full' }}>
        <Flex sx={{ minH: '80px', alignItems: 'center' }}>
          <Image sx={{ w: '140px', h: '35px' }} src={logo} />
        </Flex>

        <Box sx={{ borderRadius: '8px', bg: 'white', alignSelf: 'start' }}>
          {plainLayout ? (
            <Fragment>{children}</Fragment>
          ) : (
            <Fragment>
              <VStack sx={{ borderBottom: '1px solid #e8e8e8', p: 6 }}>
                <Text as="h1" sx={{ fontSize: '22px', fontWeight: 600 }}>
                  {title}
                </Text>
                <Box>{subtitle}</Box>
              </VStack>

              <Box sx={{ py: 6, px: 16 }}>
                {flash?.length ? (
                  <FlashMessage type={flash[0][0]} message={flash[0][1]} />
                ) : null}

                {children}
              </Box>

              <Box sx={{ p: 6 }}>{footer}</Box>
            </Fragment>
          )}
        </Box>

        <Text
          sx={{ color: 'white', textAlign: 'center', py: 10, alignSelf: 'end' }}
        >
          &copy;{new Date().getFullYear()}&nbsp;
          <Span sx={{ fontWeight: 600 }}>nDataThoth Limited</Span> All Rights
          Reserved.
        </Text>
      </Grid>
    </Box>
  )
}

export default withAuthPage<AuthLayoutProps>(AuthLayout)

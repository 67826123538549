import { useMemo } from 'react'

import { DashboardLayout } from '@/components/layouts'
import { withPage } from '@/lib/layout/Application'

import { WebhookLogSection, WebhookSettingSection } from '../containers'

import { Webhook, WebhookLog } from '../../types'

type WebhookPageProps = {
  webhook: { id: string; attributes: Webhook; type: 'webhook' }
  attempts: { id: string; attributes: WebhookLog; type: 'webhook_attempt' }[]
}

function WebhookPage(props: WebhookPageProps) {
  const { webhook, attempts } = props

  const logs = useMemo(
    () => attempts.map((att) => ({ ...att.attributes, id: att.id })),
    [],
  )

  return (
    <DashboardLayout title="Webhook">
      <WebhookSettingSection defaultWebhookValue={webhook.attributes} />

      <WebhookLogSection data={logs} />
    </DashboardLayout>
  )
}

export default withPage(WebhookPage)

import React, { FC, useMemo, useEffect } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'dayjs/locale/th'

import { ThemeProvider } from '@/lib/styles'
import { AppContextProvider } from './ApplicationContext'

import '@/features/i18n'

import { PLANS } from '@/features/dashboard/packages/constants'
import { Plan } from '@/features/dashboard/packages/types'

import { logUser, logEcommerce } from '@/lib/stats'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      staleTime: 1000 * 60 * 10,
    },
  },
})

export const ApplicationLayout = (props: Dict) => {
  const { children, ...rest } = props

  const packageName = useMemo(() => gon.company_package, [])
  const userID = useMemo(() => gon.current_admin_id, [])
  const currentPackage = useMemo(() => {
    return PLANS.find!((pl) => pl.plan === packageName) as Plan
  }, [])

  const contextValue = {
    ...rest,
    currentPackage,
    isFreePlan: currentPackage.plan === 'free',
  }

  useEffect(() => {
    logUser(userID ?? '')

    if (!userID) {
      logEcommerce('', null)
    }
  }, [userID])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppContextProvider value={contextValue}>{children}</AppContextProvider>
      </ThemeProvider>

      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export const withPage = (Component: FC<any>) => (props: Dict) => {
  return (
    <ApplicationLayout {...props}>
      <Component {...props} />
    </ApplicationLayout>
  )
}

export function withAuthPage<T>(Component: React.ComponentType<T>) {
  return (props: T) => {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Component {...props} />
        </ThemeProvider>

        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
      </QueryClientProvider>
    )
  }
}

import { get } from 'lodash'
import { Box, Grid, Text, Button } from '@chakra-ui/react'

import { PLANS, MODAL_DATA } from '@/features/dashboard/packages/constants'
import IconCheckCircle from '@/components/atoms/Icons/IconCheckCircle'
import IconIncorrectCircle from '@/components/atoms/Icons/IconIncorrectCircle'
import { getSubscriptionType } from '@/features/dashboard/packages/helpers'

import { PackageDetail, Plan } from '@/features/dashboard/packages/types'

const dataKeys = [
  'user',
  'form',
  'submission',
  'custom_domain',
  'sub_domain',
  'custom_logo',
  'api',
  'data',
]

interface PricingTableMobileProps {
  onClickPlan: (plan: Plan) => void
  packageDetail: PackageDetail
}

function PricingTableMobile({
  packageDetail,
  onClickPlan,
}: PricingTableMobileProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'gray.50',
        py: 4,
        px: 6,
      }}
    >
      <Text
        as="h2"
        sx={{
          fontSize: '2xl',
          py: 4,
        }}
      >
        แพ็คเกจทั้งหมด
      </Text>
      <Grid
        sx={{
          gridGap: 4,
          gridTemplateColumns: {
            md: 'repeat(2, 1fr)',
          },
        }}
      >
        {PLANS.map((plan) => {
          const isUsedCurrentPackage =
            plan.plan === get(packageDetail, 'name', '')

          return (
            <Box
              key={plan.plan}
              sx={{
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: 'gray.300',
                borderRadius: 'lg',
              }}
            >
              <Grid
                sx={{
                  border: 'none',
                  borderBottom: '1px solid',
                  borderColor: 'gray.300',
                  py: 4,
                  px: 6,
                  gridGap: 1,
                }}
              >
                <Text
                  as="span"
                  sx={{
                    textTransform: 'uppercase',
                    color: `${plan.color}.400`,
                    fontSize: 'md',
                  }}
                >
                  {plan.plan}
                </Text>
                <Text
                  as="h3"
                  sx={{
                    fontSize: '2xl',
                  }}
                >
                  {plan.title}
                </Text>
                <Text>{plan.suffix}</Text>
              </Grid>
              <Grid
                sx={{
                  py: 4,
                  px: 6,
                  gridGap: 4,
                }}
              >
                {dataKeys.map((key) => {
                  const checked = get(plan, `${key}.checked`)

                  return (
                    <Grid
                      key={key}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'max-content 1fr',
                        gridGap: 4,
                      }}
                    >
                      {checked ? <IconCheckCircle /> : <IconIncorrectCircle />}
                      <Text>{get(plan, `${key}.label`)}</Text>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid
                sx={{
                  padding: 6,
                  justifyItems: 'center',
                }}
              >
                <Button
                  isDisabled={isUsedCurrentPackage}
                  colorScheme={plan.color}
                  variant="solid"
                  onClick={() => onClickPlan(plan)}
                  size="xl"
                  sx={{
                    color: 'white',
                    padding: 4,
                    borderRadius: 'base',
                    border: 'none',
                    fontWeight: 'medium',
                    fontSize: 'xl',
                    width: '100%',
                    '&:disabled': {
                      cursor: 'not-allowed',
                      backgroundColor: 'gray.300',
                      color: 'gray.900',
                      outline: 'none',
                    },
                  }}
                >
                  {isUsedCurrentPackage
                    ? 'คุณกำลังใช้แพ็กเกจนี้'
                    : MODAL_DATA[getSubscriptionType(packageDetail, plan)](plan)
                        .buttonLabel}
                </Button>
                <Text
                  sx={{
                    textAlign: 'center',
                    color: 'gray.600',
                    marginTop: 4,
                  }}
                >
                  รวมภาษีมูลค่าเพิ่ม 7%
                </Text>
              </Grid>
            </Box>
          )
        })}
      </Grid>
    </Box>
  )
}

export default PricingTableMobile

import { routes } from './routes'

const getRoute = () => {
  const currentPath = window.location.pathname

  return routes.find((r) => {
    return r.regex.test(currentPath)
  })
}

export const useActiveRoute = () => {
  return function (targetRoutes: string[]) {
    const route = getRoute()
    const routeName = route?.name || ''

    return targetRoutes.includes(routeName)
  }
}

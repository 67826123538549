import { useState } from 'react'
import { Box, Button, Text, Heading, useToast } from '@chakra-ui/react'
import Modal from '@/components/molecules/Modal'
import { axios, getErrorMsg } from '@/lib/helpers'
import {
  DEFAULT_TOAST_OPTIONS,
  ERROR_TOAST_OPTIONS,
} from '@/lib/constants/toast'

interface Props {
  modalData: {
    isActive: boolean
    modalChangeEmailData: { isActive: boolean; newEmail: string }
    modalChangeEmailDataSet: (data: {
      isActive: boolean
      newEmail: string
    }) => void
  }
  modalDataSet: (data: { isActive: boolean }) => void
}

export const CancelModal = (props: Props) => {
  const { modalData, modalDataSet } = props
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onCancel = async () => {
    setLoading(true)
    try {
      await axios.put(`/users/email/cancel`, {
        admin: { email: modalData.modalChangeEmailData.newEmail },
      })
      toast({
        description: 'ยกเลิกการเปลี่ยนแปลงอีเมลสำเร็จ',
        ...DEFAULT_TOAST_OPTIONS,
      })
      modalData.modalChangeEmailDataSet({
        ...modalData.modalChangeEmailData,
        newEmail: '',
      })
      modalDataSet({
        ...modalData,
        isActive: false,
      })
    } catch (err: any) {
      toast({
        title: getErrorMsg(err),
        ...ERROR_TOAST_OPTIONS,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal modalData={modalData} modalDataSet={modalDataSet}>
      <Box sx={{ button: { height: '64px', width: '100%', p: '20px 26px' } }}>
        <Heading as="h3" sx={{ pb: 0, fontSize: '24px' }}>
          คุณแน่ใจหรือไม่ ?
        </Heading>
        <Text
          sx={{
            fontSize: '12px',
            color: 'gray.600',
            mb: '20px',
            mt: '8px',
          }}
        >
          คุณต้องการยกเลิกการกระทำนี้หรือไม่ ? กรุณายืนยัน
        </Text>
        <Button
          colorScheme="brand"
          type="submit"
          onClick={onCancel}
          isLoading={loading}
        >
          ยืนยัน
        </Button>
        <Button
          color="gray.600"
          colorScheme="white"
          type="submit"
          onClick={() => {
            modalDataSet({ ...modalData, isActive: false })
          }}
        >
          ยกเลิก
        </Button>
      </Box>
    </Modal>
  )
}

import { Button, Input, Stack } from '@chakra-ui/react'
import { useState } from 'react'

import { FormControl } from '@/components/Form'
import { PasswordInput } from './PasswordInput'

import { getAuthenticityToken } from '../helpers'

import { AuthFormProps, ResetPasswordFormInput } from '../types'

type ResetExpiredPasswordFormProps = AuthFormProps & {
  resetPasswordToken: string
  errors: Partial<Record<keyof ResetPasswordFormInput, string[]>>
}

export const ResetExpiredPasswordForm = (
  props: ResetExpiredPasswordFormProps,
) => {
  const { errors } = props

  const [password, setPassword] = useState('')

  const authenticityToken = getAuthenticityToken()

  return (
    <form action="/password_expired" acceptCharset="UTF-8" method="post">
      <input type="hidden" name="_method" value="put" autoComplete="off" />
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <Stack spacing="6">
        <FormControl isRequired errorMsg={errors.current_password?.[0]}>
          <Input
            autoComplete="current_password"
            placeholder="รหัสผ่านปัจจุบัน"
            type="password"
            name="admin[current_password]"
            id="admin_current_password"
            size="lg"
          />
        </FormControl>

        <FormControl isRequired errorMsg={errors.password?.[0]}>
          <PasswordInput
            autoFocus
            autoComplete="new-password"
            placeholder="รหัสผ่านใหม่"
            name="admin[password]"
            id="admin_password"
            size="lg"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </FormControl>

        <FormControl isRequired errorMsg={errors.password_confirmation?.[0]}>
          <Input
            autoComplete="new-password"
            placeholder="ยืนยันรหัสผ่านใหม่"
            type="password"
            name="admin[password_confirmation]"
            id="admin_password_confirmation"
            size="lg"
          />
        </FormControl>

        <Button type="submit" size="xl">
          เปลี่ยนรหัสผ่านของฉัน
        </Button>
      </Stack>
    </form>
  )
}

import { AcceptInvitationForm, AuthLayout } from '../components'
import { AcceptInvitationFormInput, AuthPageProps } from '../types'

type AcceptInvitationPageProps = AuthPageProps & {
  resource: AcceptInvitationFormInput
  errors: Partial<Record<keyof AcceptInvitationFormInput, string[]>>
  invitationToken: string
}

function AcceptInvitationPage(props: AcceptInvitationPageProps) {
  const { resource, formActionPath, errors, invitationToken } = props

  return (
    <AuthLayout title="ตั้งรหัสผ่านของคุณ">
      <AcceptInvitationForm
        defaultValues={resource}
        errors={errors}
        formActionPath={formActionPath}
        invitationToken={invitationToken}
      />
    </AuthLayout>
  )
}

export default AcceptInvitationPage

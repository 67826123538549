interface Props {
  fill: string
}

function IconSearch(props: Props) {
  const { fill = '#B2B2B2' } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.258.667c3.64 0 6.592 2.95 6.592 6.591 0 1.583-.558 3.036-1.489 4.173l2.78 2.78c.257.257.257.673 0 .93-.237.237-.61.255-.868.054l-.062-.054-2.78-2.78c-1.137.93-2.59 1.489-4.173 1.489-3.64 0-6.591-2.951-6.591-6.592 0-3.64 2.95-6.591 6.591-6.591zm0 1.315c-2.914 0-5.276 2.362-5.276 5.276 0 2.914 2.362 5.277 5.276 5.277 1.433 0 2.732-.571 3.683-1.498.012-.02.027-.036.043-.053l.054-.044c.926-.95 1.497-2.25 1.497-3.682 0-2.914-2.363-5.276-5.277-5.276z"
      />
    </svg>
  )
}

export default IconSearch

import { REQUEST_FORM_STATUS } from '../constants'

export * from './subject'

type RequestFormStatus = typeof REQUEST_FORM_STATUS[number]

export type Form = {
  color: string
  company_id: number
  created_at: string
  custom_domain: string | null
  id: number
  form_url: string
  logo: {
    url: string
  }
  name: string
  privacy_url: string
  slug: string
  status: RequestFormStatus
  terms_url: string
  token: string
  updated_at: string
}

export type RequestFormInput = {
  color?: string
  logo: File | null
  name: string
  privacy_url?: string
  slug?: string
  status: RequestFormStatus
  terms_url?: string
}

export type FormLanguage = 'th' | 'en'

import { useState, useRef, Fragment } from 'react'
import { get, flatten, entries, isEmpty } from 'lodash'

import { axios } from '@/lib/helpers'
import { Button, Link } from '@chakra-ui/react'
import { withPage } from '@/lib/layout/Application'

const API_KEYS_PATH = '/api_keys'

const DashboardAPIKeysShow = (props) => {
  const { api_keys, api_key } = props

  const [form, setForm] = useState({
    name: '',
    permissions: 'none',
  })
  const [errors, setErrors] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleGenerate = async () => {
    setIsLoading(true)

    const { name, permissions } = form
    const data = { name, permissions }

    try {
      await axios.post(API_KEYS_PATH, data)
      window.location.reload()
    } catch (err) {
      setIsLoading(false)
      setErrors(get(err, 'response.data.errors'))
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setForm((state) => ({ ...state, [name]: value }))
  }

  const handleReroll = async (token) => {
    try {
      await axios.put(`/api_keys/rollings/${token}`)
      window.location.reload()
    } catch (err) {
      setErrors(get(err, 'response.data.errors'))
    }
  }

  return (
    <Fragment>
      <div className="DashboardAPIKeysShow">
        <div className="container">
          <div className="Header">
            <h2 className="Header__h2">
              <span className="Header__breadcrumb__a">API Keys</span>
            </h2>

            <Link
              isExternal
              href={'https://app.pdpaform.com/api/documentation/index.html'}
            >
              <Button variant="ghost">Guide</Button>
            </Link>
          </div>

          <main className="Main">
            <div className="Head">
              <div className="Head__left">
                <p>Create API Key Connect</p>
                <input
                  className="Input"
                  onChange={handleChange}
                  placeholder="Name"
                  value={form.name}
                  name="name"
                />

                <select
                  className="Select"
                  name="permissions"
                  onChange={({ target: { value, name } }) => {
                    setForm((form) => ({ ...form, [name]: value }))
                  }}
                  value={form.permission}
                >
                  <option value="none">No Access</option>
                  <option value="read_only">Read Only</option>
                  <option value="write_only">Write Only</option>
                  <option value="read_write">Read and Write</option>
                </select>

                <button
                  className="Button"
                  onClick={handleGenerate}
                  disabled={isLoading}
                >
                  สร้างคีย์
                </button>

                {!isEmpty(errors) && (
                  <div className="error-wrapper">
                    <ul className="FormsNew__body__form__errors">
                      {flatten(entries(errors).map((x) => get(x, '1'))).map(
                        (x, i) => (
                          <li
                            key={i}
                            className="FormsNew__body__form__errors__li"
                          >
                            {x}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>

              <div className="Head__right">
                <p>My Key</p>
                <input
                  className="Input"
                  value={get(api_key, 'token')}
                  readOnly
                />
                <button
                  className="Button"
                  onClick={() => handleReroll(get(api_key, 'token'))}
                >
                  สร้างคีย์ใหม่
                </button>
              </div>
            </div>

            <div className="Table">
              <div className="Table__row">
                {headers.map((h) => {
                  return (
                    <div className="Table__col Table__col--header" key={h}>
                      {h}
                    </div>
                  )
                })}
              </div>
              {api_keys.map((key) => {
                const {
                  name,
                  email,
                  token,
                  allow_read,
                  allow_write,
                  permission,
                } = key

                return (
                  <CustomRow
                    key={token}
                    name={name}
                    email={email}
                    token={token}
                    read={allow_read}
                    write={allow_write}
                    permission={permission}
                    handleReroll={handleReroll}
                    setErrors={setErrors}
                  />
                )
              })}
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  )
}

export default withPage(DashboardAPIKeysShow)

const headers = ['Key name', 'Member', 'API Key', 'API Permission', 'Action']

const CustomRow = (props) => {
  const { name, email, token, permission, setErrors, handleReroll } = props

  const inputRef = useRef()

  const handleCopy = () => {
    const input = inputRef.current

    if (input) {
      input.select()
      document.execCommand('copy')
    }
  }

  const handleChange = async ({ target: { value } }) => {
    try {
      await axios.put(`${API_KEYS_PATH}/${token}`, { permissions: value })
      window.location.reload()
    } catch (err) {
      setErrors(get(err, 'response.data.errors'))
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure to delete this key?')) {
      try {
        await axios.delete(`/api_keys/${token}`)
        window.location.reload()
      } catch (err) {
        setErrors(get(err, 'response.data.errors'))
      }
    }
  }

  return (
    <div className="Table__row Table__row--slim">
      <div className="Table__col">{name}</div>
      <div className="Table__col">{email}</div>
      <div className="Table__col">
        <input
          className="token-wrapper"
          value={token}
          readOnly
          ref={inputRef}
        />
      </div>
      <div className="Table__col">
        <select className="Select" onChange={handleChange} value={permission}>
          <option value="none">No Access</option>
          <option value="read_only">Read Only</option>
          <option value="write_only">Write Only</option>
          <option value="read_write">Read and Write</option>
        </select>
      </div>
      <div className="Table__col">
        <div className="actions-wrapper">
          <a className="Table__col__controls__a" onClick={handleCopy}>
            คัดลอก
          </a>
          <a
            className="Table__col__controls__a"
            onClick={() => handleReroll(token)}
          >
            สร้างคีย์ใหม่
          </a>
          <a
            className="Table__col__controls__a Table__col__controls__a--red"
            onClick={handleDelete}
          >
            ลบ
          </a>
        </div>
      </div>
    </div>
  )
}

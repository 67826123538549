import { useState, useEffect, FC, ReactNode } from 'react'

import { createCtx } from '@/lib/helpers'
import { useTransformPackageData } from '@/features/dashboard/payments/helpers/hooks'
import {
  transformCreditCards,
  getDefaultCreditCard,
} from '@/features/dashboard/payments/helpers/utils'
import { useCreditCardServices } from '@/features/dashboard/payments/services'

import {
  RawCard,
  Card,
  RecurringType,
  Package,
  RawPackage,
  Transaction,
  PaymentMethod,
} from '@/features/dashboard/payments/types'

interface Context {
  hostUrl: string
  paymentMethod: PaymentMethod
  setPaymentMethod: (paymentMethod: PaymentMethod) => void
  creditCards: Card[]
  packageData: Package
  transaction: Transaction
}

interface Props {
  hostUrl: string
  packageDetails: RawPackage
  currentPackage: { recurring_type: RecurringType }
  creditCards: RawCard[]
  recurringType: RecurringType
  transaction: Transaction
  children: ReactNode
  email: string
}

const [
  usePaymentCheckoutContext,
  PaymentCheckoutProvider,
  PaymentCheckoutContext,
] = createCtx<Context>()

const withPaymentCheckoutContext = (Component: FC<any>) => (props: Props) => {
  const {
    packageDetails,
    currentPackage,
    transaction,
    creditCards,
    hostUrl,
    children,
  } = props

  const packageData = useTransformPackageData(
    packageDetails,
    currentPackage.recurring_type,
    transaction.address.taxpayer_type,
  )

  const { creditCards: transformedCreditCards } = useCreditCardServices(() =>
    transformCreditCards(creditCards),
  )

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(() =>
    getDefaultCreditCard(transformedCreditCards),
  )

  useEffect(() => {
    if (paymentMethod.type === 'credit') {
      if (paymentMethod.payload) {
        const selectedCard = transformedCreditCards.find(
          (card) => card.id === paymentMethod.payload,
        )

        if (!selectedCard) {
          setPaymentMethod(getDefaultCreditCard(transformedCreditCards))
        }
      } else {
        setPaymentMethod(getDefaultCreditCard(transformedCreditCards))
      }
    }
  }, [transformedCreditCards])

  const contextValues = {
    hostUrl,
    paymentMethod,
    setPaymentMethod,
    creditCards: transformedCreditCards,
    packageData,
    transaction: transaction,
  }

  return (
    <PaymentCheckoutProvider value={contextValues}>
      <Component {...props}>{children}</Component>
    </PaymentCheckoutProvider>
  )
}

export {
  usePaymentCheckoutContext,
  withPaymentCheckoutContext,
  PaymentCheckoutContext,
}

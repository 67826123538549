export default {
  container: {
    p: ['20px 20px 0'],
    m: '0 auto',
  },
  pricePlaneTableItem: {
    minHeight: '72px',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'light',
    fontSize: '2xl',
    padding: 'calc(var(--global-space) * 0.8) 0',
  },
}

const baseStyle = {
  boxShadow: 'none',
  _focus: {
    boxShadow: 'none',
  },
}

export default {
  baseStyle,
}

import {
  Collapse,
  FormControl as CKFormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'

type CustomFormControl = {
  errorMsg?: string
  label?: string
} & FormControlProps

export const FormControl = (props: CustomFormControl): React.ReactElement => {
  const { children, errorMsg, label, id, ...rest } = props

  const _isInvalid = Boolean(errorMsg)

  return (
    <CKFormControl id={id} {...rest} isInvalid={_isInvalid}>
      {label && <FormLabel>{label}</FormLabel>}

      {children}

      <Collapse in={_isInvalid} animateOpacity>
        <FormErrorMessage data-testid="error-msg">{errorMsg}</FormErrorMessage>
      </Collapse>
    </CKFormControl>
  )
}

import { useState } from 'react'
import { find } from 'lodash'

import { axios } from '@/lib/helpers'

function ChangeStatus(props) {
  const { path, links, currentStatus } = props
  const [isOpen, setOpen] = useState(false)

  const selectedStatus = find(links, (l) => l.slug === currentStatus)

  return (
    <div className="mb-statusSelect">
      <div
        className="mb-statusSelect__button"
        onClick={() => setOpen((o) => !o)}
      >
        <span>สถานะ:</span>
        <strong className="mb-statusSelect__status">
          {selectedStatus.label}
        </strong>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              fill="#888"
              fillRule="evenodd"
              d="M12.683 6.146L8.39 11.512c-.172.216-.487.25-.702.078-.03-.023-.055-.05-.078-.078L3.317 6.146c-.173-.216-.138-.53.078-.703.088-.071.198-.11.312-.11h8.586c.276 0 .5.224.5.5 0 .114-.039.224-.11.313z"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="mb-statusSelect__dropdown">
          {links.map((link) => {
            return (
              <div
                key={link.slug}
                className="mb-statusSelect__dropdownItem"
                onClick={() => {
                  axios.patch(path, { status: link.slug }).then(() => {
                    window.location.reload()
                  })
                }}
              >
                {link.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ChangeStatus

import { Box, Center, Grid, Text } from '@chakra-ui/react'

import { withPage } from '@/lib/layout/Application'

import { Strong } from '@/components/atoms/shared'
import { RequestLegend, RequestPieChart } from './RequestPieChart'

import { useDashboardStats } from '../queries'

type DashboardSectionProps = {}

const DashboardSection = (props: DashboardSectionProps) => {
  const {} = props

  const { data: stats } = useDashboardStats()

  return (
    <Grid
      sx={{
        gap: '8px',
        gridTemplateColumns: {
          base: '1fr 1fr',
          xl: '340px 1fr',
        },
        gridTemplateRows: {
          base: '140px max-content',
          md: '113px max-content',
          xl: '144px 144px',
        },
        '> *': {
          bg: 'white',
          borderRadius: '8px',
        },

        '.dashboard-sum': {
          flexDir: 'column',

          div: {
            textAlign: 'center',

            strong: {
              fontSize: { base: '32px', md: '40px' },
              fontWeight: 700,
              d: 'block',
            },
            p: {
              fontSize: { base: 'xs', md: 'sm' },
              fontWeight: 500,
              color: 'gray.600',
            },
          },
        },
      }}
    >
      <Center
        className="dashboard-sum"
        sx={{
          gridColumn: {
            base: '1/2',
          },
        }}
      >
        <Box>
          <Strong color="gray.700">{stats?.all}</Strong>
          <Text>รายการคำร้องทั้งหมด</Text>
        </Box>
      </Center>

      <Center
        className="dashboard-sum"
        sx={{
          gridColumn: {
            base: '2/3',
            xl: '1/2',
          },
        }}
      >
        <Box>
          <Strong sx={{ color: '#00DEA9' }}>{stats?.completed}</Strong>
          <Text>คำร้องเสร็จสิ้น</Text>
        </Box>
      </Center>

      <Grid
        sx={{
          p: 6,
          pr: { md: 10, lg: 12, xl: '72px' },
          gridColumn: {
            base: '1/-1',
            xl: '2/3',
          },
          gridRow: {
            xl: '1/-1',
          },
          alignItems: 'center',
          gridTemplateRows: { base: 'max-content 1fr', md: 'unset' },
          gridTemplateColumns: {
            md: '1fr 294px',
            lg: '1fr 360px',
            xl: '1fr 340px',
          },
          justifyItems: 'center',
          justifyContent: {
            md: 'space-between',
          },
          gap: {
            base: '16px',
          },
        }}
      >
        <RequestPieChart data={stats?.request_type_count} />
        <RequestLegend data={stats?.request_type_count} />
      </Grid>
    </Grid>
  )
}

export default withPage(DashboardSection)

import { Fragment, useMemo, useState } from 'react'
import { Column } from 'react-table'
import {
  Button,
  ButtonGroup,
  HStack,
  Link,
  Tag,
  useDisclosure,
} from '@chakra-ui/react'

import { Form } from '../types'

import { DataTable } from '@/components/atoms/Table'
import { ConfirmDeleteFormModal } from './ConfirmDeleteFormModal'
import { CopyUrlModal } from './CopyUrlModal'

import { useDeleteForm } from '../services'
import { getIframe } from '../helpers'

type FormTableProps = {
  data: Form[]
}

export const FormTable = (props: FormTableProps) => {
  const { data } = props

  const [selectedRow, setSelectedRow] = useState<Form>()
  const deleteDisclosure = useDisclosure()
  const copyUrlDisclosure = useDisclosure()

  const { mutate: deleteForm, isLoading: deleteLoading } = useDeleteForm()

  const memoizedData = useMemo(() => data, [])
  const columns = useMemo<Array<Column<Form>>>(() => {
    return [
      {
        Header: 'ชื่อฟอร์ม',
        accessor: 'name',
      },
      {
        Header: 'URL Form',
        accessor: 'form_url',
        Cell: (cellProps) => {
          return (
            <Link isExternal href={cellProps.value} color="brand.400">
              {cellProps.value}
            </Link>
          )
        },
      },
      {
        Header: 'สถานะ',
        accessor: 'status',
        Cell: (cellProps) => {
          const data = cellProps.value

          return (
            <Tag
              sx={{
                borderRadius: 'full',
                bg: data === 'active' ? '#23D492' : 'gray.400',
                color: 'white',
                fontSize: 'xs',
                px: 3,
              }}
            >
              {data.toTitleCase()}
            </Tag>
          )
        },
      },
      {
        Header: 'การกระทำ',
        accessor: 'updated_at',
        Cell: (cellProps) => {
          const row = cellProps.row.original

          return (
            <ButtonGroup
              variant="link"
              sx={{
                button: {
                  minW: 'unset',
                },
              }}
            >
              <HStack spacing="6">
                <Button
                  onClick={() => {
                    setSelectedRow(row)
                    copyUrlDisclosure.onOpen()
                  }}
                >
                  เผยแพร่
                </Button>
                <Button as="a" href={`/forms/${row.token}`}>
                  แก้ไข
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setSelectedRow(row)
                    deleteDisclosure.onOpen()
                  }}
                >
                  ลบ
                </Button>
              </HStack>
            </ButtonGroup>
          )
        },
      },
    ]
  }, [])

  return (
    <Fragment>
      <DataTable columns={columns} data={memoizedData} />

      {selectedRow && (
        <CopyUrlModal
          disclosure={copyUrlDisclosure}
          url={selectedRow.form_url}
          iframe={getIframe(
            selectedRow.form_url,
            `${selectedRow.name} DSAR Form`,
          )}
        />
      )}
      {selectedRow && (
        <ConfirmDeleteFormModal
          disclosure={deleteDisclosure}
          onConfirm={() => {
            deleteForm({
              params: {
                token: selectedRow.token,
              },
            })
          }}
          isLoading={deleteLoading}
        />
      )}
    </Fragment>
  )
}

import Checkbox from './checkbox'
import Button from './button'
import Link from './link'
import Switch from './switch'
import Input from './input'

export default {
  Checkbox,
  Button,
  Link,
  Switch,
  Input,
}

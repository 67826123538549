import { Plan, PackageSize } from '@/features/dashboard/packages/types'

export const SUBSCRIPTION_LEVEL: Record<PackageSize, number> = {
  free: 0,
  small: 1,
  medium: 2,
  enterprise: 3,
}

export const PLANS: Plan[] = [
  {
    plan: 'free',
    prefix: 'FREE',
    title: 'ฟรี! ไม่มีค่าใช้จ่าย',
    suffix: 'ไม่ซับซ้อน ปลอดภัย ยกเลิกเมื่อไรก็ได้',
    color: 'green',
    price: {
      title: 'ฟรี!',
    },
    user: {
      checked: true,
      label: '1 User',
      count: 1,
    },
    form: {
      checked: true,
      label: '1 Number of Form',
      count: 1,
    },
    submission: {
      checked: true,
      label: 'Form Submission: 50 ต่อเดือน',
      count: 50,
      suffix: 'ต่อเดือน',
    },
    custom_domain: {
      checked: false,
      label: 'Custom Domain with Free SSL',
    },
    sub_domain: {
      checked: false,
      label: 'Subdomain URL name',
    },
    custom_logo: {
      checked: false,
      label: 'Customize Logo / Theme Color',
    },
    api: {
      checked: false,
      label: 'API Access',
    },
    data: {
      checked: true,
      label: '1 Year for Data Retention',
      desk_label: '1 ปี',
      count: 1,
    },
    button: {
      label: 'free',
      href: '',
    },
  },
  {
    plan: 'small',
    prefix: 'Small Business',
    title: '500 บาท/เดือน',
    suffix: 'หรือ 5,000 บาทต่อปี ',
    color: 'brown',
    price: {
      title: '500 บาท',
      unit: '/เดือน',
      sub: '5,000 บาท/ปี',
      month: '500 บาท / เดือน',
      year: '5,000 บาท / ปี',
    },
    user: {
      checked: true,
      label: '5 Users',
      count: 5,
    },
    form: {
      checked: true,
      label: '2 Number of Form',
      count: 2,
    },
    submission: {
      checked: true,
      label: 'Form Submission: 5000 ต่อเดือน',
      count: '5,000',
      suffix: 'ต่อเดือน',
    },
    custom_domain: {
      checked: false,
      label: 'Custom Domain with Free SSL',
    },
    sub_domain: {
      checked: true,
      label: 'Subdomain URL name',
    },
    custom_logo: {
      checked: true,
      label: 'Customize Logo / Theme Color',
    },
    api: {
      checked: false,
      label: 'API Access',
    },
    data: {
      checked: true,
      label: '2 Years for Data Retention',
      desk_label: '2 ปี',
      count: 2,
    },
    button: {
      label: 'small',
      href: '',
    },
  },
  {
    plan: 'medium',
    prefix: 'Medium Sized',
    title: '1,500 บาท/เดือน',
    suffix: 'หรือ 15,000 บาทต่อปี',
    color: 'red',
    price: {
      title: '1,500 บาท',
      unit: '/เดือน',
      sub: '15,000 บาท/ปี',
      disclaimer: '',
      month: '1,500 บาท / เดือน',
      year: '15,000 บาท / ปี',
    },
    user: {
      checked: true,
      label: '10 Users',
      count: 10,
    },
    form: {
      checked: true,
      label: '10 Number of Form',
      count: 10,
    },
    submission: {
      checked: true,
      label: 'Form Submission: 10,000 ต่อเดือน',
      count: '10,000',
      suffix: 'ต่อเดือน',
    },
    custom_domain: {
      checked: false,
      label: 'Custom Domain with Free SSL',
    },
    sub_domain: {
      checked: true,
      label: 'Subdomain URL name',
    },
    custom_logo: {
      checked: true,
      label: 'Customize Logo / Theme Color',
    },
    api: {
      checked: false,
      label: 'API Access',
    },
    data: {
      checked: true,
      label: '3 Years for Data Retention',
      desk_label: '3 ปี',
      count: 3,
    },
    button: {
      label: 'medium',
      href: '',
    },
  },
  {
    plan: 'enterprise',
    prefix: 'Enterprise',
    title: 'Contact us',
    suffix: '30,000 บาทต่อเดือนหรือ 300,000 บาทต่อปี ',
    color: 'blue',
    price: {
      title: '30,000 บาท',
      unit: '/เดือน',
      sub: '300,000 บาท/ปี',
      month: '30,000 บาท / เดือน',
      year: '300,000 บาท / ปี',
    },
    user: {
      checked: true,
      label: '100 Users',
      count: 100,
    },
    form: {
      checked: true,
      label: '100 Number of Form',
      count: 100,
    },
    submission: {
      checked: true,
      label: 'Form Submission: ไม่จำกัด',
      count: 'ไม่จำกัด',
    },
    custom_domain: {
      checked: true,
      label: 'Custom Domain with Free SSL',
    },
    sub_domain: {
      checked: true,
      label: 'Subdomain URL name',
    },
    custom_logo: {
      checked: true,
      label: 'Customize Logo / Theme Color',
    },
    api: {
      checked: true,
      label: 'API Access',
    },
    data: {
      checked: true,
      label: '5 Years for Data Retention',
      desk_label: '5 ปี',
      count: 5,
    },
    button: {
      label: 'enterprise',
      href: '',
    },
  },
]

import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useQuery, useMutation } from 'react-query'

import { getErrorToastObject } from '@/lib/helpers/utils'
import creditCardAPI from '@/features/dashboard/payments/api/creditCards'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants/toast'
import { Card } from '@/features/dashboard/payments/types'

type SetupCreditCard = () => Promise<{ client_secret: 'string' }>

type CreateCreditCard = (args: {
  data: {
    payment_method_id: string
    default_card: boolean
  }
}) => Promise<AxiosResponse>

type UpdateCreditCard = (args: {
  data: {
    default_card: boolean
  }
  params: { id: string }
}) => Promise<AxiosResponse>

type RemoveCreditCard = (args: {
  params: { id: string }
}) => Promise<AxiosResponse>

interface UseCreditCardServicesResult {
  creditCards: Card[]
  isFetching: boolean
  isRequesting: boolean
  setup: SetupCreditCard
  create: CreateCreditCard
  update: UpdateCreditCard
  remove: RemoveCreditCard
}

export function useCreditCardServices(
  initialData?: () => Card[] | Card[],
): UseCreditCardServicesResult {
  const toast = useToast()

  const { data: creditCards, isFetching, isLoading, refetch } = useQuery(
    creditCardAPI.list.getQueryKey(),
    () => creditCardAPI.list.queryFn({}),
    {
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
      refetchOnMount: true,
      initialData,
    },
  )

  const { mutateAsync: setup, isLoading: isLoadingSetup } = useMutation(
    creditCardAPI.setup,
    {
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: create, isLoading: isLoadingCreate } = useMutation(
    creditCardAPI.create,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'บันทึกสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: update, isLoading: isLoadingUpdate } = useMutation(
    creditCardAPI.update,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          description: 'บันทึกสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  const { mutateAsync: remove, isLoading: isLoadingRemove } = useMutation(
    creditCardAPI.remove,
    {
      onSuccess: () => {
        refetch()
        toast({
          ...DEFAULT_TOAST_OPTIONS,
          status: 'warning',
          description: 'ลบสำเร็จ',
        })
      },
      onError: (error: AxiosError) => {
        toast(getErrorToastObject(error))
      },
    },
  )

  return {
    creditCards: creditCards || [],
    isFetching: isLoading || isFetching,
    isRequesting:
      isLoadingSetup || isLoadingCreate || isLoadingUpdate || isLoadingRemove,
    setup,
    create,
    update,
    remove,
  }
}

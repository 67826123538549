document.getElementById('NavWhatIs').addEventListener('click', (e) => {
  e.preventDefault()
  scrollToSection('what-is-pdpa-form')

  window.setTimeout(() => {
    window.location.href = window.location.origin + '#what-is-pdpa-form'
  }, 800)
})
document.getElementById('NavWhyUs').addEventListener('click', (e) => {
  e.preventDefault()
  scrollToSection('benefits')

  window.setTimeout(() => {
    window.location.href = window.location.origin + '#benefits'
  }, 800)
})
document.getElementById('NavPackage').addEventListener('click', (e) => {
  e.preventDefault()
  scrollToSection('packages')

  window.setTimeout(() => {
    window.location.href = window.location.origin + '#packages'
  }, 800)
})
document.getElementById('NavContact').addEventListener('click', (e) => {
  e.preventDefault()
  scrollToSection('contact-us')

  window.setTimeout(() => {
    window.location.href = window.location.origin + '#contact-us'
  }, 800)
})

const scrollToSection = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
}

const header = document.querySelector('header.Landing__Header')
const hamburger = document.querySelector('a.Nav__hamburger')

if (header) {
  const toggleMenu = () => {
    header.classList.toggle('Landing__Header--active')
  }

  document
    .getElementById('WhatIs--mobile')
    .addEventListener('click', toggleMenu)
  document.getElementById('WhyUs--mobile').addEventListener('click', toggleMenu)
  document
    .getElementById('Package--mobile')
    .addEventListener('click', toggleMenu)
  document
    .getElementById('Contact--mobile')
    .addEventListener('click', toggleMenu)

  hamburger.addEventListener('click', toggleMenu)
}

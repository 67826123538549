import {
  UseDisclosureReturn,
  ModalBody,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react'

import Modal from '@/components/atoms/Modal'
import { ModalHeader } from '@/components/atoms/shared'

type ConfirmDeleteFormModalProps = {
  disclosure: UseDisclosureReturn
  onConfirm: () => void
  isLoading: boolean
}

export const ConfirmDeleteFormModal = (props: ConfirmDeleteFormModalProps) => {
  const { disclosure, onConfirm, isLoading } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>ยืนยันลบฟอร์ม</ModalHeader>
      <ModalBody>
        <Text>
          การลบข้อมูลบัญชีบริษัทของคุณ
          รวมถึงการลบข้อมูลที่ถูกแนบมาทั้งหมดของผู้ใช้
          ข้อมูลต่างๆที่อยู่ในระบบนี้จะหายถาวรทั้งหมด
        </Text>

        <Stack mt="6">
          <Button
            colorScheme="red"
            isLoading={isLoading}
            onClick={onConfirm}
            size="xl"
          >
            ยืนยันการลบ
          </Button>
          <Button
            colorScheme="gray"
            isDisabled={isLoading}
            onClick={onClose}
            size="xl"
            variant="ghost"
          >
            ยกเลิก
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}

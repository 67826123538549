import { useState, Fragment } from 'react'
import { get, entries, map, isEmpty, flatten, keys } from 'lodash'
import className from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@chakra-ui/react'

import { getSubjects } from '@/features/forms/helpers'
import { t, axios, readableColor } from '@/lib/helpers'
import { withTheme } from '@/lib/styles'

import img from '@/images/app/FormsNew__body__sent__img.svg'

import '@/features/i18n'
import { LanguageSelect } from '@/components/atoms/LanguageSelect'

type Errors = Record<string, string>

interface Props {
  companyName: string
  formName: string
  color: string
  logo: string
  new_admin_registration_url: string
  footer_links: {
    terms_url?: string
    policies_url?: string
  }
  options: {
    bg: boolean
    header: boolean
    lngSelector: boolean
  }
}

const FormsIndex = (props: Props) => {
  const {
    companyName,
    formName,
    color,
    logo,
    new_admin_registration_url,
    footer_links,
    options,
  } = props

  const { bg, header, lngSelector } = options

  const { policies_url, terms_url } = footer_links

  const [isActiveCorporate, isActiveCorporateSet] = useState(false)
  const [subjectIndex, subjectIndexSet] = useState('forgotten')
  const [errors, errorsSet] = useState<Errors>({})
  const [isLoading, isLoadingSet] = useState(false)
  const [uploadFile, uploadFileSet] = useState()
  const [isSent, isSentSet] = useState(false)

  const buttonColor = readableColor(color, '#ffffff', '#2d2d2d')
  const otherColor = readableColor(bg ? color : '#ffffff', '#ffffff', '#2d2d2d')
  const otherColorName = otherColor === '#2d2d2d' ? 'black' : 'white'

  const subjects = getSubjects(formName)

  const { t: tForms } = useTranslation('forms')

  return (
    <div
      className="FormsNew"
      style={{ backgroundColor: bg ? color : '#ffffff' }}
    >
      <div className="container">
        {header && (
          <div
            className={className('FormsNew__header', {
              'FormsNew__header--no-lngSelector': !lngSelector,
            })}
          >
            {logo ? (
              <img className="FormsNew__header__img" src={logo} />
            ) : (
              <h1 className="FormsNew__header__h1">
                <span
                  className={className(
                    'FormsNew__header__h1__a',
                    `FormsNew__header__h1__a--${otherColorName}`,
                  )}
                  style={{ color: otherColor }}
                >
                  PDPA Form
                </span>
              </h1>
            )}

            {lngSelector && (
              <LanguageSelect
                value={gon.currentLocale}
                handleChange={(value) => {
                  window.location.href = `/${value}`
                }}
                isMini
                menuShortLabel
                sx={{
                  color: otherColor,
                  borderColor: otherColor,
                }}
              />
            )}
          </div>
        )}

        <div
          className={className('FormsNew__body', {
            'FormsNew__body--no-border': !bg,
          })}
        >
          {isSent ? (
            <div
              className={className('FormsNew__body__sent', {
                'FormsNew__body__sent--center': !bg,
              })}
            >
              <img className="FormsNew__body__sent__img" src={img} />

              <p className="FormsNew__body__sent__p">
                {t('pages.forms.new.body.sent.p')}
              </p>

              <small className="FormsNew__body__sent__small">
                {t('pages.forms.new.body.sent.small')}
              </small>

              <a
                className="Button Button--large"
                href={window.location.href}
                style={{
                  backgroundColor: color,
                  color: buttonColor,
                }}
              >
                {t('pages.forms.new.body.sent.submit')}
              </a>
            </div>
          ) : (
            <Fragment>
              <div
                className={className('FormsNew__body__header', {
                  'FormsNew__body__header--no-border': !bg,
                })}
              >
                <p className="FormsNew__body__header__p">
                  {t('pages.forms.new.body.p')}
                </p>

                <small
                  className="FormsNew__body__header__small"
                  dangerouslySetInnerHTML={{
                    __html:
                      t('pages.forms.new.body.small', {
                        name: formName,
                      }) || '',
                  }}
                />
              </div>

              <form
                className={className('FormsNew__body__form', {
                  'FormsNew__body__form--no-border': !bg,
                })}
                onSubmit={(e) => {
                  e.preventDefault()

                  const $els = get(e, 'target.elements')
                  const formData = new FormData()

                  map($els, (x) => {
                    return {
                      name: get(x, 'name'),
                      value:
                        get(x, 'name') == 'identity'
                          ? get(x, 'files[0]')
                          : get(x, 'value'),
                    }
                  })
                    .filter((x) => !isEmpty(get(x, 'name')))
                    .forEach((x) => {
                      formData.append(
                        `request[${get(x, 'name')}]`,
                        get(x, 'value'),
                      )
                    })

                  isLoadingSet(true)

                  axios
                    .post(`/${gon.currentLocale}`, formData)
                    .then(() => {
                      isSentSet(true)
                    })
                    .catch((e) => {
                      isLoadingSet(false)
                      errorsSet(get(e, 'response.data.errors'))
                    })
                }}
              >
                <label className="FormsNew__body__form__label">
                  <span className="FormsNew__body__form__label__span">
                    {t('pages.forms.new.body.form.individual_or_company.label')}
                  </span>

                  <select
                    className="Select"
                    onChange={(e) => {
                      isActiveCorporateSet(Number(get(e, 'target.value')) == 1)
                    }}
                  >
                    <option value="0">
                      {t(
                        'pages.forms.new.body.form.individual_or_company.select.person',
                      )}
                    </option>

                    <option value="1">
                      {t(
                        'pages.forms.new.body.form.individual_or_company.select.company',
                      )}
                    </option>
                  </select>
                </label>

                <div className="FormsNew__body__form__label">
                  <span className="FormsNew__body__form__label__span">
                    {t('pages.forms.new.body.form.name.label')}
                  </span>

                  <div className="FormsNew__body__form__label__row">
                    <Input errors={errors} name="first_name" />

                    <Input errors={errors} name="last_name" />
                  </div>
                </div>

                {isActiveCorporate && (
                  <label className="FormsNew__body__form__label">
                    <span className="FormsNew__body__form__label__span">
                      {t('pages.forms.new.body.form.company_name.label')}
                    </span>

                    <Input errors={errors} name="company_name" />
                  </label>
                )}

                <label className="FormsNew__body__form__label">
                  <span className="FormsNew__body__form__label__span">
                    {t('pages.forms.new.body.form.email.label')}
                  </span>

                  <Input errors={errors} name="email" />
                </label>

                <label className="FormsNew__body__form__label">
                  <span className="FormsNew__body__form__label__span FormsNew__body__form__label__span--with-tooltip">
                    {t('pages.forms.new.body.form.identity.label')}

                    <a className="FormsNew__body__form__label__span__tooltip">
                      <p className="FormsNew__body__form__label__span__tooltip__panel">
                        {t('pages.forms.new.body.form.identity.tooltip')}
                      </p>
                    </a>
                  </span>

                  <div
                    className={className('InputFile', {
                      'InputFile--error': keys(errors).find(
                        (x) => x == 'identity',
                      ),
                    })}
                  >
                    <p
                      className={className('InputFile__p', {
                        'InputFile__p--active': uploadFile,
                      })}
                    >
                      {get(uploadFile, 'name') ||
                        t('pages.forms.new.body.form.identity.pick')}
                    </p>

                    <a
                      className="InputFile__a"
                      style={{
                        backgroundColor: color,
                        color: buttonColor,
                      }}
                    >
                      {t('pages.forms.new.body.form.identity.find')}
                    </a>

                    <input
                      type="file"
                      name="identity"
                      onChange={(e) => {
                        uploadFileSet(get(e, 'target.files.0'))
                      }}
                    />
                  </div>
                </label>

                {(() => {
                  const selectedSubject = subjects.find(
                    (x) => subjectIndex && get(x, 'key') == subjectIndex,
                  )

                  return (
                    <Fragment>
                      <label className="FormsNew__body__form__label">
                        <span className="FormsNew__body__form__label__span">
                          {t('pages.forms.new.body.form.subject.label')}
                        </span>

                        <select
                          className="Select"
                          value={subjectIndex}
                          name="subject"
                          onChange={(e) => {
                            subjectIndexSet(get(e, 'target.value'))
                          }}
                        >
                          {false && (
                            <option value="">
                              {t('pages.forms.new.body.form.subject.default')}
                            </option>
                          )}

                          {subjects.map((x, i) => (
                            <option key={i} value={get(x, 'key')}>
                              {get(x, 'label')}
                            </option>
                          ))}
                        </select>
                      </label>

                      {get(selectedSubject, 'optional1') && (
                        <label className="FormsNew__body__form__label">
                          <span className="FormsNew__body__form__label__span">
                            {get(selectedSubject, 'optional1.label')}
                          </span>

                          {get(selectedSubject, 'optional1.options') ? (
                            <select className="Select" name="optional1">
                              {false && (
                                <option value="">
                                  {t(
                                    'pages.forms.new.body.form.subject.default',
                                  )}
                                </option>
                              )}

                              {(
                                get(selectedSubject, 'optional1.options') || []
                              ).map((x, i) => (
                                <option key={i}>{get(x, 'label')}</option>
                              ))}
                            </select>
                          ) : (
                            <textarea
                              className="Input"
                              rows={5}
                              name="optional1"
                            />
                          )}
                        </label>
                      )}

                      {get(selectedSubject, 'optional2') && (
                        <label className="FormsNew__body__form__label">
                          <span className="FormsNew__body__form__label__span">
                            {get(selectedSubject, 'optional2.label')}
                          </span>

                          <textarea
                            className="Input"
                            rows={5}
                            name="optional2"
                          />
                        </label>
                      )}
                    </Fragment>
                  )
                })()}

                {!isEmpty(errors) && (
                  <Fragment>
                    <ul className="FormsNew__body__form__errors">
                      {flatten(entries(errors).map((x) => get(x, '1'))).map(
                        (x, i) => (
                          <li
                            key={i}
                            className="FormsNew__body__form__errors__li"
                          >
                            {x}
                          </li>
                        ),
                      )}
                    </ul>
                  </Fragment>
                )}

                <button
                  className="Button Button--large"
                  disabled={isLoading}
                  style={{
                    backgroundColor: color,
                    color: buttonColor,
                  }}
                >
                  {isLoading
                    ? t('pages.forms.new.body.button.loading')
                    : t('pages.forms.new.body.button.normal')}
                </button>

                <p className="FormsNew__body__form__p">
                  {policies_url && terms_url ? (
                    <Trans i18nKey="term_privacy" t={tForms}>
                      โดยการส่งคำขอผ่านแบบฟอร์ม คุณได้ยอมรับ&nbsp;
                      <Link isExternal href={terms_url}>
                        ข้อตกลงในการใช้งาน
                      </Link>
                      &nbsp;และ&nbsp;
                      <Link isExternal href={policies_url}>
                        นโยบายการรักษาข้อมูลส่วนบุคคล
                      </Link>
                    </Trans>
                  ) : terms_url ? (
                    <Trans i18nKey="terms_only" t={tForms}>
                      โดยการส่งคำขอผ่านแบบฟอร์ม คุณได้ยอมรับ&nbsp;
                      <Link isExternal href={terms_url}>
                        ข้อตกลงในการใช้งาน
                      </Link>
                    </Trans>
                  ) : policies_url ? (
                    <Trans i18nKey="terms_only" t={tForms}>
                      โดยการส่งคำขอผ่านแบบฟอร์ม คุณได้ยอมรับ&nbsp;
                      <Link isExternal href={policies_url}>
                        นโยบายการรักษาข้อมูลส่วนบุคคล
                      </Link>
                    </Trans>
                  ) : null}
                </p>
              </form>
            </Fragment>
          )}
        </div>

        <div
          className={className('FormsNew__footer', {
            'FormsNew__footer--no-border': bg,
          })}
          style={{ color: otherColor }}
        >
          <p className="FormsNew__footer__p">
            &copy;{new Date().getFullYear()} <strong>{companyName}</strong>. All
            Rights Reserved.
          </p>

          <p className="FormsNew__footer__p">
            Get your own form{' '}
            <a
              className="FormsNew__footer__p__a"
              href={new_admin_registration_url}
            >
              PDPAForm.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTheme<Props>(FormsIndex)

interface InputProps {
  name: string
  errors: Errors
}

const Input = ({ name, errors }: InputProps) => {
  return (
    <input
      type="text"
      className={className('Input', {
        'Input--error': keys(errors).find((x) => x == name),
      })}
      placeholder={t(`pages.forms.new.body.form.${name}.placeholder`)}
      name={name}
    />
  )
}

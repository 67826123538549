import { FC, ReactNode } from 'react'
import { flowRight as compose } from 'lodash'
import { PaymentProvider } from '@datawowio/payment-ui'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { withPage } from '@/lib/layout/Application/ApplicationLayout'
import { PAYMENT_PROJECT } from '@/features/dashboard/payments/constants'

import theme from '@/lib/styles/theme'

const stripePromise = loadStripe(gon.stripePKey)
interface Props {
  children: ReactNode
  email: string
}

export const withPaymentProvider = (Component: FC<any>) => (props: Props) => {
  const { email, children } = props
  return (
    <PaymentProvider
      project={{
        ...PAYMENT_PROJECT,
        email: email,
      }}
      theme={theme}
    >
      <Elements stripe={stripePromise}>
        <Component {...props}>{children}</Component>
      </Elements>
    </PaymentProvider>
  )
}

export const withPaymentLayout = compose(withPage, withPaymentProvider)

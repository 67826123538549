import { Fragment } from 'react'

import DocsLayout from '@/features/termAndPrivacy/components/DocsLayout'
import { renderTemplate } from '@/features/termAndPrivacy/helpers'

const Terms = () => {
  return (
    <Fragment>
      <h1>Terms And Condition</h1>
      <p>
        These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;)
        are an agreement between PDPAForm.com (&quot;nDataThoth Limited&quot;,
        &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
        (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Agreement
        sets forth the general terms and conditions of your use of the
        pdpaform.com website and any of its products or services (collectively,
        &quot;Website&quot; or &quot;Services&quot;).
      </p>
      {renderTemplate('terms')}
      <div id="EndContent">
        This document was last updated on January 24, 2022
      </div>
    </Fragment>
  )
}

export default DocsLayout(Terms)

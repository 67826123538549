import { Fragment } from 'react'
import { Box, Button } from '@chakra-ui/react'

import { useAppContext, withPage } from '@/lib/layout/Application'
import { DashboardLayout } from '@/components/layouts'
import { FormTable } from '../components'

import { Form } from '../types'

type FormListPageProps = {
  forms: Form[]
}

function FormListPage(props: FormListPageProps) {
  const { forms } = props
  const { currentPackage } = useAppContext()

  return (
    <Fragment>
      <DashboardLayout
        title="ฟอร์ม"
        leftElement={
          <Button
            colorScheme="brand"
            as="a"
            href="/forms/new"
            isDisabled={forms.length >= currentPackage.form.count}
          >
            สร้างฟอร์ม
          </Button>
        }
      >
        <Box sx={{ p: '4px 12px' }}>
          <FormTable data={forms} />
        </Box>
      </DashboardLayout>
    </Fragment>
  )
}

export default withPage(FormListPage)

import { Box, Text } from '@chakra-ui/react'

import { withPage } from '@/lib/layout/Application'
import { PasswordExpirationSection } from '../containers'

import { Company } from '../../company/types'

type SecuritySettingPageProps = {
  company: Company
}

function SecuritySettingPage(props: SecuritySettingPageProps) {
  const { company } = props

  return (
    <Box
      sx={{
        maxW: '620px',
        mx: 'auto',
      }}
    >
      <Box className="Header">
        <Text as="h2" textStyle="h2">
          ตั้งค่าความปลอดภัย
        </Text>
      </Box>

      <Box
        sx={{
          mt: 4,
          bg: 'white',
          borderRadius: '8px',
          p: {
            base: '24px 32px',
          },
        }}
      >
        <PasswordExpirationSection
          expirePasswordAfterDays={company.expire_password_after_days}
        />
      </Box>
    </Box>
  )
}

export default withPage(SecuritySettingPage)

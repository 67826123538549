import {
  Box,
  Button,
  Link,
  ModalBody,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import Modal from '@/components/atoms/Modal'
import { ModalHeader, Span } from '@/components/atoms/shared'

import { PackageSize } from '@/features/dashboard/packages/types'

type ConfirmSelectFormModalProps = {
  disclosure: UseDisclosureReturn
  isLoading: boolean
  onSubmit: () => void
  packageName: PackageSize
  quota: number
}

export const ConfirmSelectFormModal = (props: ConfirmSelectFormModalProps) => {
  const { disclosure, packageName, quota, isLoading, onSubmit } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalHeader>
        ยืนยันการเลือกแบบฟอร์มรับคำร้อง
        <Text sx={{ fontSize: 'md', fontWeight: 'normal' }}>
          ปัจจุบันคุณใช้&nbsp;
          <Span sx={{ fontWeight: 'semibold' }}>
            แพ็กเกจ {packageName.toTitleCase()}
          </Span>
          <br />
          รองรับแบบฟอร์มรับคำร้องได้สูงสุดจำนวน {quota} ฟอร์ม
        </Text>
      </ModalHeader>

      <ModalBody>
        <Box>
          <Text
            sx={{
              textDecor: 'underline',
              fontWeight: 'bold',
              color: 'red.400',
            }}
          >
            ข้อควรระวัง
          </Text>
          <Text
            sx={{
              color: 'red.400',
            }}
          >
            ข้อมูลทั้งหมดของแบบฟอร์มรับคำร้องที่คุณไม่ได้เลือกจะถูกระงับการใช้งาน
            หากต้องการให้แบบฟอร์มรับคำร้องใช้งานได้ทั้งหมด
            <br />
            <Link sx={{ color: 'brand.400' }} href="/packages">
              อัปเกรดแพ็กเกจ
            </Link>
          </Text>

          <Text sx={{ mt: 6 }}>หากคุณตรวจทานเรียบร้อยแล้ว กดยืนยัน</Text>
        </Box>

        <Stack mt="6">
          <Button size="xl" isLoading={isLoading} onClick={onSubmit}>
            ยืนยัน
          </Button>
          <Button
            size="xl"
            colorScheme="gray"
            variant="ghost"
            isDisabled={isLoading}
          >
            ยกเลิก
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}

export const DASHBOARD_STATS = 'dashboard_stats'

export const REQUEST_TYPES = [
  'forgotten',
  'transfer',
  'know_how',
  'correct',
  'stop_using',
  'stop_process',
  'object',
] as const

import {
  Grid,
  Box,
  SelectCreditCard as _SelectCreditCard,
  AddStripeCreditCardForm,
  PaymentMethodContainer,
} from '@datawowio/payment-ui'
import { useToast } from '@chakra-ui/react'
import { useStripe } from '@stripe/react-stripe-js'

import { DEFAULT_TOAST_OPTIONS } from '@/lib/constants/toast'

import { usePaymentCheckoutContext } from '@/features/dashboard/payments/contexts/paymentCheckoutContext'
import { useCreditCardServices } from '@/features/dashboard/payments/services'
import { logEvent } from '@/lib/stats'

import CreditCardList from './CreditCardList'

const SelectCreditCard = () => {
  const stripe = useStripe()
  const toast = useToast()

  const { creditCards } = usePaymentCheckoutContext()

  const creditCardServices = useCreditCardServices()

  return (
    <Grid gap="6">
      <PaymentMethodContainer>
        <_SelectCreditCard
          renderCreditForm={({ onClose }) => {
            return (
              <AddStripeCreditCardForm
                onSubmit={async (card, isPrimary) => {
                  try {
                    if (!stripe) return

                    const { client_secret: clientSecret } =
                      await creditCardServices.setup()

                    if (!clientSecret) return

                    const { error, setupIntent } =
                      await stripe.confirmCardSetup(clientSecret, {
                        payment_method: card,
                      })

                    if (error) {
                      toast({
                        ...DEFAULT_TOAST_OPTIONS,
                        description: error.message,
                        status: 'error',
                      })

                      return
                    }

                    if (setupIntent && setupIntent.payment_method) {
                      await creditCardServices.create({
                        data: {
                          payment_method_id: setupIntent.payment_method,
                          default_card: isPrimary,
                        },
                      })

                      logEvent({
                        ga: {
                          category: 'Checkout',
                          action: " 'Add new card success",
                        },
                        fb: {
                          event: 'AddPaymentInfo',
                        },
                      })

                      onClose()
                    }
                  } catch {}
                }}
              />
            )
          }}
        >
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <CreditCardList cards={creditCards} />
          </Box>
        </_SelectCreditCard>
      </PaymentMethodContainer>
    </Grid>
  )
}

export default SelectCreditCard

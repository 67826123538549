import { z } from 'zod'

import { REQUEST_FORM_STATUS } from '.'

export const baseRequestFormSchema = z.object({
  name: z.string().nonempty({ message: 'จำเป็นต้องกรอก' }),
  terms_url: z.string().url({ message: 'รูปแบบ URL ไม่ถูกต้อง' }),
  privacy_url: z.string().url({ message: 'รูปแบบ URL ไม่ถูกต้อง' }),
  status: z.enum(REQUEST_FORM_STATUS),
})

export const premiumRequestFormSchema = baseRequestFormSchema.extend({
  color: z
    .string()
    .regex(/[a-f0-9]{3,4}|[a-f0-9]{6}|[a-f0-9]{8}/, {
      message: 'รูปแบบธีมสีไม่ถูกต้อง',
    })
    .nullable(),
  logo: z.any(),
  slug: z.string(),
})

import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '@/lib/helpers'
import { transformAddresses } from '@/features/dashboard/payments/helpers/utils'

import { Address, RawAddress } from '@/features/dashboard/payments/types'

const baseUrl = '/addresses'

const list = {
  getQueryKey: (): unknown[] => [baseUrl, 'list'],
  queryFn: async (): Promise<Address[]> => {
    const res = await axios.get(`${baseUrl}`, undefined)

    if (res?.data?.data) {
      return transformAddresses(res.data.data)
    }

    return []
  },
}

async function create({
  data,
}: {
  data: Omit<RawAddress, 'id'>
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  return axios.post(`${baseUrl}`, data)
}

async function update({
  data,
  params,
}: {
  data: Partial<Omit<RawAddress, 'id'>>
  params: { id: string }
}): Promise<AxiosResponse> {
  return axios.patch(`${baseUrl}/${params.id}`, data)
}

async function remove({
  params,
}: {
  params: { id: string }
}): Promise<AxiosResponse> {
  return axios.delete(`${baseUrl}/${params.id}`, undefined)
}

export default {
  baseUrl,
  list,
  create,
  update,
  remove,
}

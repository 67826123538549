import { EventType } from '../../types'

export * from './queryKeys'

export const eventTypeColor: Record<
  EventType,
  {
    label: string
    color: string
  }
> = {
  comment_create: {
    label: 'Update Comment',
    color: 'brown.400',
  },
  request_create: {
    label: 'Create Request',
    color: 'primary.400',
  },
  request_update: {
    label: 'Update Request',
    color: 'primary.200',
  },
}

export const statusCodeColor = {}

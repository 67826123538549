import { Fragment, useMemo, useState } from 'react'
import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react'

import { useAppContext, withPage } from '@/lib/layout/Application'

import { DashboardLayout } from '@/components/layouts'
import {
  FormMigrationTable,
  UserMigrationTable,
} from '../components/MigrationTable'
import {
  ConfirmSelectFormModal,
  ConfirmSelectUserModal,
  MigrationNoticeBanner,
  SelectCounter,
} from '../components'

import { MigrationAdmin, MigrationForm, MigrationMode } from '../types'
import { useDeactivateForms, useDeleteAdmins } from '../services'

type MigrationPageProps = {
  data: {
    admins?: MigrationAdmin[]
    forms?: MigrationForm[]
  }
}

function MigrationPage(props: MigrationPageProps) {
  const { data } = props

  const [mode, setMode] = useState<MigrationMode>(data.forms ? 'form' : 'admin')
  const [selectedForms, setSelectedForms] = useState<MigrationForm[]>([])
  const [selectedAdmins, setSelectedAdmins] = useState<MigrationAdmin[]>([])

  const userDisclosure = useDisclosure()
  const formDisclosure = useDisclosure()

  const { currentPackage } = useAppContext()

  const { mutate: deactivateForms, isLoading: isLoadingDeactivateForms } =
    useDeactivateForms()
  const { mutate: deleteAdmins, isLoading: isLoadingDeleteAdmins } =
    useDeleteAdmins()

  const packageName = useMemo(() => gon.company_package, [])

  const userQuota = currentPackage.user.count
  const formQuota = currentPackage.form.count

  const isFormMode = mode === 'form'

  return (
    <Fragment>
      <Box sx={{ pos: 'relative' }}>
        <SelectCounter
          quota={isFormMode ? formQuota : userQuota}
          current={isFormMode ? selectedForms.length : selectedAdmins.length}
        />

        <DashboardLayout
          title={isFormMode ? 'จัดการฟอร์ม' : 'จัดการผู้ใช้งาน'}
          leftElement={
            <Button
              colorScheme="brand"
              onClick={
                isFormMode ? formDisclosure.onOpen : userDisclosure.onOpen
              }
            >
              {isFormMode ? 'เปิดใช้งานฟอร์ม' : 'เลือกผู้ใช้งาน'}
            </Button>
          }
        >
          <Stack>
            <MigrationNoticeBanner
              mode={mode}
              quota={isFormMode ? formQuota : userQuota}
              packageName={packageName}
            />
            {isFormMode
              ? data.forms && (
                  <FormMigrationTable
                    data={data.forms}
                    handleSelectRow={setSelectedForms}
                    limit={formQuota}
                  />
                )
              : data.admins && (
                  <UserMigrationTable
                    data={data.admins}
                    handleSelectRow={setSelectedAdmins}
                    limit={userQuota}
                  />
                )}
          </Stack>
        </DashboardLayout>
      </Box>

      <ConfirmSelectFormModal
        disclosure={formDisclosure}
        isLoading={isLoadingDeactivateForms}
        onSubmit={() => {
          deactivateForms(
            {
              data: {
                formIds: selectedForms.map((form) => form.id),
              },
            },
            {
              onSuccess() {
                formDisclosure.onClose()

                if (data.admins) {
                  setMode('admin')
                } else {
                  window.location.href = `/requests`
                }
              },
            },
          )
        }}
        packageName={packageName}
        quota={formQuota}
      />
      <ConfirmSelectUserModal
        disclosure={userDisclosure}
        isLoading={isLoadingDeleteAdmins}
        onSubmit={() => {
          deleteAdmins({
            data: {
              adminsIds: selectedAdmins.map((ad) => ad.id),
            },
          })
        }}
        packageName={packageName}
        quota={userQuota}
      />
    </Fragment>
  )
}

export default withPage(MigrationPage)

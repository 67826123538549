import {
  chakra,
  HStack,
  ModalHeaderProps,
  ModalHeader as CKModalHeader,
} from '@chakra-ui/react'
import { animated } from 'react-spring'

export const Span = chakra('span')
export const Strong = chakra('strong')
export const ModalHeader = (props: ModalHeaderProps) => {
  const { sx, children, ...rest } = props

  return (
    <CKModalHeader sx={{ fontSize: '2xl', ...sx }} {...rest}>
      {children}
    </CKModalHeader>
  )
}

export const SpringHStack = animated(HStack)

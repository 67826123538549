import { Fragment, FC } from 'react'

import Sidebar from './Sidebar'

const DocsLayout = (Component: FC) => {
  const pathname = window.location.pathname
  const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1)
  const { landing_url: landingURL } = gon

  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <Fragment>
        <header className="Landing__Header">
          <div className="container">
            <a className="Logo" href={landingURL}></a>

            <ul className="Nav">
              <li className="Nav__item">
                <a id="NavWhatIs" href={`${landingURL}#WhatIs`}>
                  PDPA คืออะไร?
                </a>
              </li>
              <li className="Nav__item">
                <a id="NavWhyUs" href={`${landingURL}#WhyUs`}>
                  ทำไมต้อง PDPA&nbsp;Form
                </a>
              </li>
              <li className="Nav__item" id="NavContact">
                <a href={`${landingURL}#Contact`}>ติดต่อเรา</a>
              </li>
              <li className="Nav__item">
                <a href="/sign_in">เข้าสู่ระบบ</a>
              </li>
              <li className="Nav__item Nav__item--blue">
                <a href="/sign_up">เริ่มใช้งาน</a>
              </li>
            </ul>
          </div>
        </header>

        <div className="Docs">
          <div className="container">
            <Sidebar lastSegment={lastSegment} />
            <main>
              <Component />
            </main>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default DocsLayout

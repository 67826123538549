import { useState } from 'react'
import { AxiosResponse } from 'axios'
import {
  Box,
  Button,
  Checkbox,
  Text,
  useToast,
  FormControl,
  Input,
  Grid,
  Link,
  Alert,
  FormLabel,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { get } from 'lodash'
import dayjs from 'dayjs'
import { Icon } from '@datawowio/payment-ui'
import { FiRefreshCcw } from 'react-icons/fi'

import { withPage } from '@/lib/layout/Application/ApplicationLayout'
import { ChangeEmailModal } from '@/features/dashboard/users/ChangeEmailModal'
import { CancelModal } from '@/features/dashboard/users/CancelModal'

import { axios, getLocaleDateString, getErrorMsg } from '@/lib/helpers'
import { updateConsent } from '@/lib/utils/consent'
import {
  DEFAULT_TOAST_OPTIONS,
  ERROR_TOAST_OPTIONS,
} from '@/lib/constants/toast'

type MarketingConsentSettingProps = {
  user: Admin
  company: any
  changedUser: any
  changedDate: any
  isReAuthentication: boolean
  consentApiUrl: string
  consentPurposeId: string
  consentApiKey: string
}

const MarketingConsentSetting = (props: MarketingConsentSettingProps) => {
  const {
    user,
    company,
    changedUser,
    changedDate,
    isReAuthentication,
    consentApiUrl,
    consentPurposeId,
    consentApiKey,
  } = props
  const [loading, setLoading] = useState(false)
  const [modalData, modalDataSet] = useState({
    isActive: false,
    newEmail: changedUser,
  })

  const [modalCancelData, modalCancelDataSet] = useState({
    isActive: false,
  })

  const { handleSubmit, register, getValues } = useForm({
    defaultValues: {
      marketing_consent: get(user, 'marketing_consent'),
      email: get(user, 'email'),
      first_name: get(user, 'first_name'),
      last_name: get(user, 'last_name'),
      telephone: get(company, 'telephone'),
    },
  })

  const toast = useToast()

  const checkError = async (
    func: () => Promise<AxiosResponse<any>>,
    successMsg: string,
    isLoading = false,
  ): Promise<AxiosResponse<any> | undefined> => {
    if (isLoading) {
      setLoading(true)
    }
    try {
      const response = await func()
      toast({
        description: successMsg,
        ...DEFAULT_TOAST_OPTIONS,
      })
      return response
    } catch (err) {
      toast({
        title: getErrorMsg(err),
        ...ERROR_TOAST_OPTIONS,
      })
    } finally {
      if (isLoading) {
        setLoading(false)
      }
    }
  }

  const putSettings = () => {
    return axios.put('/users/settings', { admin: getValues() })
  }

  const handleSubmitForm = async () => {
    const response: AxiosResponse<any> | undefined = await checkError(
      putSettings,
      'อัปเดตสำเร็จ',
      true,
    )

    const data = getValues()
    if (response?.status === 200) {
      await updateConsent({
        consentStatus: data.marketing_consent,
        apiKey: consentApiKey,
        purposeId: consentPurposeId,
        baseUrl: consentApiUrl,
        data: {
          email: data.email,
          name: data.first_name,
          surname: data.last_name,
          phone_number: data.telephone,
        },
      })
    }
  }

  const postConfirm = () => {
    return axios.post('/confirmations')
  }

  const resend = async () => {
    checkError(
      postConfirm,
      'ส่งอีเมลยืนยันอีกครั้งสำเร็จ กรุณาตรวจสอบกล่องขาเข้าอีเมลของคุณ',
    )
  }

  const cancel = () => {
    modalCancelDataSet({
      isActive: true,
      modalChangeEmailData: modalData,
      modalChangeEmailDataSet: modalDataSet,
    })
  }

  return (
    <>
      <Box
        layerStyle="container"
        sx={{
          maxW: '620px',
          a: {
            fontWeight: 700,
            mb: '8px',
            marginLeft: 'auto',
            color: 'primary.400',
            fontSize: '14px',
          },
        }}
      >
        <Box className="Header">
          <Text as="h2" textStyle="h2">
            ตั้งค่าผู้ใช้งาน
          </Text>
        </Box>

        <Box
          sx={{
            m: ['12px', '12px', '16px', '20px'],
            bg: 'white',
            borderRadius: '8px',
            label: { fontWeight: 500, mb: '8px', fontSize: '16px' },
          }}
        >
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Box sx={{ p: ['16px', '16px', '16px', '24px 32px'] }}>
              <Box sx={{ mb: ['40px'] }}>
                <Grid
                  sx={{
                    rowGap: '16px',
                    input: {
                      fontSize: '14px',
                    },
                  }}
                >
                  <Text as="h4" sx={{ fontWeight: 700, mb: '8px' }}>
                    ตั้งค่าผู้ใช้งาน
                  </Text>
                  {user.role === 'owner' && (
                    <Box>
                      <FormControl label="อีเมล">
                        <Grid
                          sx={{
                            gridTemplateColumns: '1fr 1fr',
                            gap: '20px',
                          }}
                        >
                          <FormLabel
                            sx={{
                              fontWeight: 500,
                              mb: '8px',
                              color: 'gray.400',
                              fontSize: '16px',
                            }}
                          >
                            อีเมล
                          </FormLabel>
                          <Link
                            onClick={() =>
                              modalDataSet({ ...modalData, isActive: true })
                            }
                          >
                            <Icon
                              as={FiRefreshCcw}
                              name="repeat"
                              mr="1"
                              ml="6"
                              mb="-2.5px"
                              boxSize="14px"
                            />
                            เปลี่ยนอีเมล
                          </Link>
                        </Grid>

                        <Input
                          type="email"
                          {...register('email')}
                          disabled
                          sx={{
                            backgroundColor: 'gray.50',
                            color: 'gray.400',
                            fontSize: '16px',
                          }}
                          size="lg"
                        />
                      </FormControl>
                      {modalData.newEmail &&
                        get(user, 'email') !== modalData.newEmail && (
                          <Alert
                            variant="subtle"
                            flexDirection="column"
                            alignItems="left"
                            justifyContent="left"
                            textAlign="left"
                            sx={{
                              backgroundColor: '#EBF3FF',
                              borderRadius: '8px',
                              mt: '16px',
                            }}
                          >
                            <Text colo="gray.600">
                              กำลังทำการเปลี่ยนแปลงอีเมล
                              กรุณาตรวจสอบอีเมลเพื่อยืนยัน
                              <br />
                              ภายใน{' '}
                              {getLocaleDateString(
                                new Date(dayjs(changedDate).add(3, 'day')),
                              )}
                              <br />
                              <Text as="strong" color="gray.900">
                                {modalData.newEmail}
                              </Text>{' '}
                            </Text>
                            <Box mt="12px">
                              <Link onClick={resend}>ส่งอีเมลอีกครั้ง</Link>
                              <Text
                                as="span"
                                sx={{
                                  width: '0px',
                                  height: '24px',
                                  border: '1px solid #B1D1FF',
                                  m: '0 12px',
                                }}
                              />
                              <Link onClick={() => cancel()}>
                                ยกเลิกการเปลี่ยนแปลงอีเมล
                              </Link>
                            </Box>
                          </Alert>
                        )}
                    </Box>
                  )}
                  <Grid
                    sx={{
                      gridTemplateColumns: '1fr 1fr',
                      gap: '20px',
                    }}
                  >
                    <FormControl label="ชื่อ">
                      <FormLabel>ชื่อ</FormLabel>
                      <Input size="lg" {...register('first_name')} />
                    </FormControl>
                    <FormControl label="นามสกุล">
                      <FormLabel>นามสกุล</FormLabel>
                      <Input size="lg" {...register('last_name')} />
                    </FormControl>
                  </Grid>

                  <FormControl label="เบอร์โทรศัพท์">
                    <FormLabel>เบอร์โทรศัพท์</FormLabel>
                    <Input
                      size="lg"
                      type="tel"
                      pattern="[0-9]{10}"
                      {...register('telephone')}
                      placeholder="0900000000"
                    />
                  </FormControl>
                </Grid>
              </Box>
              <Box sx={{ mb: ['24px'] }}>
                <Text as="h3" sx={{ fontWeight: 700, mb: '18px' }}>
                  ตั้งค่าความเป็นส่วนตัว
                </Text>

                <Checkbox
                  colorScheme="brand"
                  alignItems="flex-start"
                  size="lg"
                  {...register('marketing_consent')}
                >
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      top: '50%',
                      marginTop: '3px',
                    }}
                  >
                    ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Form
                    และบริการอื่น ๆ จากเรา บริษัทในเครือ
                    บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                    <span style={{ whiteSpace: 'nowrap' }}>
                      (คุณสามารถยกเลิกได้ทุกเมื่อ)
                    </span>
                  </Box>
                </Checkbox>
              </Box>
              <Button
                colorScheme="brand"
                type="submit"
                isLoading={loading}
                sx={{ width: '100%', height: '64px' }}
              >
                อัปเดต
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <ChangeEmailModal
        modalData={modalData}
        modalDataSet={modalDataSet}
        user={user}
        isReauthentication={isReAuthentication}
      />
      <CancelModal
        modalData={modalCancelData}
        modalDataSet={modalCancelDataSet}
      />
    </>
  )
}

export default withPage(MarketingConsentSetting)

import { useState, useRef } from 'react'
import { find } from 'lodash'

import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'
import IconSearch from '@/components/atoms/Icons/IconSearch'
import IconChevronDown from '@/components/atoms/Icons/IconChevronDown'
import SearchBox from './SearchBox'

function Header(props) {
  const { requestPath, dropdown, currentStatus } = props

  const [isOpen, setOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)

  const $dropdownButtonRef = useRef()
  const $dropdownRef = useRef()
  const $searchButtonRef = useRef()
  const $searchBoxRef = useRef()

  useOnClickOutside(
    $dropdownRef,
    () => {
      setOpen(false)
    },
    [$dropdownButtonRef],
  )

  useOnClickOutside(
    $searchBoxRef,
    () => {
      setSearchOpen(false)
    },
    [$searchButtonRef],
  )

  const intialActiveMenu = find(dropdown, (d) => d.status === 'new')
  const activeMenu =
    find(dropdown, (d) => d.status === currentStatus) || intialActiveMenu

  return (
    <div className="mb-headerFilterInput">
      <div className="mb-headerFilterInput__label">
        <div
          ref={$searchButtonRef}
          className="mb-headerFilterInput__searchIcon"
          onClick={() => setSearchOpen((o) => !o)}
        >
          <IconSearch />
        </div>
        <div
          ref={$dropdownButtonRef}
          className="mb-headerFilterInput__dropdown"
          onClick={() => setOpen((o) => !o)}
        >
          <div className="mb-headerFilterInput__dropdownText">
            {activeMenu.label} ({activeMenu.count})
          </div>
          <IconChevronDown />
        </div>
      </div>
      {isOpen && (
        <div ref={$dropdownRef} className="mb-filterDropdown">
          {dropdown.map((menu) => {
            return (
              <div className="mb-filterDropdown__item" key={menu.label}>
                <a className="mb-filterDropdown__link" href={menu.link}>
                  {menu.label} ({menu.count})
                </a>
              </div>
            )
          })}
        </div>
      )}
      {isSearchOpen && (
        <div ref={$searchBoxRef} className="mb-filterDropdown">
          <SearchBox requestPath={requestPath} currentStatus={currentStatus} />
        </div>
      )}
    </div>
  )
}

export default Header

import { useToast, UseToastOptions } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { ERROR_TOAST_OPTIONS } from '../constants'

import { getErrorMsg } from '../helpers'

type RailsError = {
  errors: string[]
}

export const useErrorHandler = () => {
  const toast = useToast()

  return function (
    error: AxiosError<RailsError>,
    toastConfig?: UseToastOptions,
  ) {
    const errors = error.response?.data.errors

    if (errors) {
      errors.forEach((error) => {
        toast({
          ...ERROR_TOAST_OPTIONS,
          ...toastConfig,
          description: error,
        })
      })
    } else {
      toast({
        ...ERROR_TOAST_OPTIONS,
        ...toastConfig,
        description: getErrorMsg(error),
      })
    }
  }
}
